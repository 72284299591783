import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion'
import { PencilSquareIcon, PlayIcon, PlusIcon, XMarkIcon, PencilIcon, TrashIcon, CheckIcon, XCircleIcon, CheckCircleIcon } from '@heroicons/react/24/solid';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000';

function TaskForm({
    flowDescription,
    setFlowDescription,
    platform,
    setPlatform,
    appPackage,
    setAppPackage,
    schedule,
    setSchedule,
    testUid,
    handleDuplicateTest,
    executeTask,
    isDownloads,
    isEditingTask,
    setIsEditingTask,
    isEditingVariables,
    setIsEditingVariables,
    userId,
    apiKey,
    tracing,
    setTracing,
    tracingConfig,
    setTracingConfig,
}) {
    const [initialCheckDone, setInitialCheckDone] = useState(false);
    const [editingVariable, setEditingVariable] = useState(null);
    const [variables, setVariables] = useState([]);
    const [newVariableName, setNewVariableName] = useState('');
    const [newVariableValue, setNewVariableValue] = useState('');
    const [editedName, setEditedName] = useState('');
    const [editedValue, setEditedValue] = useState('');

    useEffect(() => {
        if (flowDescription === "Test Case Name" && !initialCheckDone) {
            setIsEditingTask(true);
            setInitialCheckDone(true);
        }
    }, [flowDescription, initialCheckDone, setIsEditingTask]);

    const toggleEdit = () => {
        setIsEditingTask(!isEditingTask);
    };

    const toggleVariables = () => {
        setIsEditingVariables(!isEditingVariables);
        if (!isEditingVariables) {
            fetchVariables();
        }
    };

    const sortVariables = (variables) => {
        return [...variables].sort((a, b) => a.variable_name.localeCompare(b.variable_name));
    };

    const handleEditClick = (variable) => {
        setEditingVariable(variable);
        setEditedName(variable.variable_name);
        setEditedValue(variable.variable_value);
    };

    const handleUpdateVariable = async () => {
        if (!editingVariable) return;

        try {
            const response = await fetch(`${API_BASE_URL}/update_variable`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`,
                },
                body: JSON.stringify({
                    test_uid: testUid,
                    variable_id: editingVariable.id,
                    variable_name: editedName,
                    new_value: editedValue,
                }),
            });
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            await fetchVariables();
            setEditingVariable(null);
            setEditedName('');
            setEditedValue('');
        } catch (error) {
            console.error('Error updating variable:', error);
        }
    };

    const handleCancelEdit = () => {
        setEditingVariable(null);
        setEditedName('');
        setEditedValue('');
    };

    const fetchVariables = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/read_variables?test_uid=${testUid}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${apiKey}`,
                    'Content-Type': 'application/json'
                },
            });
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            const data = await response.json();
            if (Array.isArray(data.result)) {
                setVariables(sortVariables(data.result));
            } else {
                console.error('Fetched data is not an array:', data.result);
            }
        } catch (error) {
            console.error('Error fetching variables:', error);
        }
    };

    const handleDeleteVariable = async (variableName) => {
        try {
            const queryParams = new URLSearchParams({
                user_id: userId,
                test_uid: testUid,
                variable_name: variableName
            }).toString();
            const response = await fetch(`${API_BASE_URL}/delete_variable?${queryParams}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`,
                },
            });
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            await fetchVariables();
        } catch (error) {
            console.error('Error deleting variable:', error);
        }
    };

    const handleAddVariable = async () => {
        if (!newVariableName || !newVariableValue) return;
        try {
            const response = await fetch(`${API_BASE_URL}/add_variable`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`,
                },
                body: JSON.stringify({
                    test_uid: testUid,
                    variable_name: newVariableName,
                    variable_value: newVariableValue,
                    user_id: userId
                }),
            });
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            await fetchVariables();
            setNewVariableName('');
            setNewVariableValue('');
        } catch (error) {
            console.error('Error adding variable:', error);
        }
    };

    return (
        <div className="bg-white shadow-lg rounded-lg overflow-hidden border border-gray-200">
            <div className="bg-gradient-to-r from-gray-100 to-gray-200 p-6">
                <div className="flex justify-between items-center">
                    <h2 className="text-3xl text-gray-800">{flowDescription}</h2>
                    <div className="flex items-center space-x-4">
                        {!isEditingTask && (
                            <button
                                className="bg-black hover:bg-gray-800 text-white py-2 px-4 rounded-full shadow-md transition duration-300 flex items-center space-x-2 whitespace-nowrap"
                                onClick={() => executeTask(isDownloads)}
                            >
                                <PlayIcon className="h-5 w-5 flex-shrink-0" />
                                <span className="text-sm">Run All</span>
                            </button>
                        )}
                        {isEditingTask ? (
                            <button
                                onClick={toggleEdit}
                                className="bg-white border border-gray-400 text-gray-800 py-2 px-4 rounded-full hover:bg-gray-300 transition duration-300"
                            >
                                Close
                            </button>
                        ) : (
                            <PencilSquareIcon
                                onClick={toggleEdit}
                                className="h-10 w-10 cursor-pointer text-gray-600 hover:text-gray-800 transition duration-300"
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className="p-6 bg-white">
                {!isEditingTask && (
                    <div className="grid grid-cols-2 gap-4 text-gray-700">
                        <p><strong>Platform:</strong> {platform}</p>
                        <p><strong>Website/App:</strong> {appPackage}</p>
                        <p><strong>Schedule:</strong> {schedule || 'Not set'}</p>
                        <div className="flex items-center justify-between">
                            <button
                                onClick={toggleVariables}
                    className="bg-white border border-gray-400 text-gray-800 py-1 px-3 rounded-full hover:bg-gray-300 transition duration-300 text-sm"
                >
                    {isEditingVariables ? 'Close Variables' : 'Edit Variables'}
                            </button>
                        </div>
                    </div>
                )}
                <AnimatePresence>
                    {isEditingTask && (
                        <motion.div
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: 'auto', opacity: 1 }}
                            exit={{ height: 0, opacity: 0 }}
                            transition={{ duration: 0.3 }}
                        >
                            <div className="p-4">
                                <div className="mb-4  ">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="flowDescription">
                                        Test Case Description
                                    </label>
                                    <input
                                        id="flowDescription"
                                        type="text"
                                        className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                                        placeholder="Enter test case description"
                                        value={flowDescription}
                                        onChange={(e) => setFlowDescription(e.target.value)}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="platform">
                                        Platform
                                    </label>
                                    <select
                                        id="platform"
                                        className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                                        value={platform}
                                        onChange={(e) => setPlatform(e.target.value)}
                                    >
                                        <option value="Web">Web</option>
                                        <option value="Android">Android</option>
                                        <option value="iOS" disabled>iOS (not supported yet)</option>
                                    </select>
                                </div>
                                <div className="mb-4">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="appPackage">
                                        Test Website
                                    </label>
                                    {platform === "Web" ? (
                                        <input
                                            id="appPackage"
                                            type="url"
                                            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                                            placeholder="Enter website URL"
                                            value={appPackage}
                                            onChange={(e) => setAppPackage(e.target.value)}
                                        />
                                    ) : (
                                        <input
                                            id="appPackage"
                                            type="text"
                                            className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                                            placeholder="Enter app package name"
                                            value={appPackage}
                                            onChange={(e) => setAppPackage(e.target.value)}
                                        />
                                    )}
                                </div>
                                <div className="mb-4">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="schedule">
                                        Schedule
                                    </label>
                                    <input
                                        id="schedule"
                                        type="text"
                                        className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                                        placeholder="Enter cron schedule (e.g. 0 0 * * *)"
                                        value={schedule}
                                        onChange={(e) => setSchedule(e.target.value)}
                                    />
                                    <p className="mt-1 text-sm text-gray-500">
                                        Use cron syntax: minute hour day-of-month month day-of-week
                                    </p>
                                </div>
                                <div className="mb-4">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="trac">
                                        Tracing
                                    </label>
                                    <div className="flex items-center">
                                        <label className="relative inline-flex items-center cursor-pointer">
                                            <input 
                                                type="checkbox" 
                                                className="sr-only peer" 
                                                checked={tracing}
                                                onChange={(e) => setTracing(e.target.checked)}
                                            />
                                            <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                        </label>
                                        <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                                            {tracing ? 'Enabled' : 'Disabled'}
                                        </span>
                                    </div>
                                    {tracing && (
                                        <div className="mt-2">
                                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="tracingInput">
                                                Tracing Configuration
                                            </label>
                                            <textarea
                                                id="tracingInput"
                                                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 resize-y"
                                                placeholder="Enter tracing configuration"
                                                value={tracingConfig}
                                                onChange={(e) => setTracingConfig(e.target.value)}
                                                rows={4}
                                                style={{minHeight: '100px'}}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="mb-4">
                                    <button
                                        onClick={handleDuplicateTest}
                                        className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                    >
                                        Duplicate Test
                                    </button>
                                </div>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
                {isEditingVariables && (
                    <div className="mt-6 border-t border-gray-200 pt-4 -mx-6 px-6">
                        <h3 className="text-lg text-black mb-4">Variables</h3>
                        {variables.length > 0 ? (
                            <div className="overflow-x-auto mb-4">
                                <table className="w-full ">
                                    <thead>
                                        <tr>
                                            <th className="text-black text-left font-normal p-2">Name</th>
                                            <th className="text-black text-left font-normal p-2">Value</th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {variables.map((variable) => (
                                            <tr key={variable.id} className="border-b">
                                                <td className="p-2 text-black">
                                                    {editingVariable && editingVariable.id === variable.id ? (
                                                        <input
                                                            type="text"
                                                            value={editedName}
                                                            onChange={(e) => setEditedName(e.target.value)}
                                                            className="w-full p-1 border border-gray-300 rounded"
                                                        />
                                                    ) : (
                                                        variable.variable_name
                                                    )}
                                                </td>
                                                <td className="p-2 text-black">
                                                    {editingVariable && editingVariable.id === variable.id ? (
                                                        <input
                                                            type="text"
                                                            value={editedValue}
                                                            onChange={(e) => setEditedValue(e.target.value)}
                                                            className="w-full p-1 border border-gray-300 rounded"
                                                        />
                                                    ) : (
                                                        variable.variable_value
                                                    )}
                                                </td>
                                                <td className="text-right p-2">
                                                    <div className="flex justify-end space-x-2">
                                                        {editingVariable && editingVariable.id === variable.id ? (
                                                            <>
                                                                <button
                                                                    onClick={handleUpdateVariable}
                                                                    className="p-1 rounded-full text-green-600 hover:text-green-800"
                                                                >
                                                                    <CheckCircleIcon className="h-5 w-5" />
                                                                </button>
                                                                <button
                                                                    onClick={handleCancelEdit}
                                                                    className="p-1 rounded-full text-red-600 hover:text-red-800"
                                                                >
                                                                    <XCircleIcon className="h-5 w-5" />
                                                                </button>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <button
                                                                    onClick={() => handleEditClick(variable)}
                                                                    className="btn btn-outline btn-xs rounded-full text-black"
                                                                >
                                                                    Edit
                                                                </button>
                                                                <button
                                                                    onClick={() => handleDeleteVariable(variable.variable_name)}
                                                                    className="btn btn-black btn-xs rounded-full text-white"
                                                                >
                                                                    Delete
                                                                </button>
                                                            </>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <p className="text-gray-600 mb-4">Add variables here</p>
                        )}
                        <div className="mt-4 space-y-2">
                            <input
                                type="text"
                                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                                placeholder="Variable Name"
                                value={newVariableName}
                                onChange={(e) => setNewVariableName(e.target.value)}
                            />
                            <input
                                type="text"
                                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                                placeholder="Variable Value"
                                value={newVariableValue}
                                onChange={(e) => setNewVariableValue(e.target.value)}
                            />
                            <button
                                onClick={handleAddVariable}
                                className="w-full btn btn-black text-white font-bold text-lg py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            >
                                Add
                            </button>
                        </div>
                        <p className="text-sm text-gray-600 mt-4">
                            Wrap your text with {`{$ $}`} to use it as a variable in your test steps.
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default TaskForm;