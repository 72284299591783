import React, { useState, useEffect } from "react";
import { formatRelative } from 'date-fns';
import rrwebPlayer from 'rrweb-player';
import 'rrweb-player/dist/style.css';

function TestHistoryCard({ test, histories }) {
  // State to handle the visibility of metadata popup
  const [showMetadata, setShowMetadata] = useState(false);
  const [showHtmlReport, setShowHtmlReport] = useState(false);
  const [htmlReport, setHtmlReport] = useState(null);
  const [loading, setLoading] = useState(false); // New loading state
  const [currentHistory, setCurrentHistory] = useState(null); // Track the current history
  const getBrowserbaseVideo = async (sessionId) => {
    const url = `https://www.browserbase.com/v1/sessions/${sessionId}/recording`;
    const headers = {
      "X-BB-API-Key": process.env.REACT_APP_BROWSERBASE_API_KEY
    };
    
    try {
      const response = await fetch(url, { headers });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.blob();
    } catch (error) {
      console.error("Error fetching Browserbase video:", error);
      throw error;
    }
  };

  // Toggle metadata popup visibility
  const toggleMetadata = () => {
    setShowMetadata(!showMetadata);
  };
  const toggleHtmlReport = (history) => {
    if (!showHtmlReport) {
      console.log("Metadata URL:", history.metadata);
      setLoading(true); // Set loading to true when fetching starts
      setCurrentHistory(history); // Set the current history
      fetch(history.metadata)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(metadata => {
          if (metadata.html_report_url && metadata.html_report_url['url']) {
            console.log('HTML report URL:', metadata.html_report_url['url']);
            fetch(metadata.html_report_url['url'])
              .then(response => response.text())
              .then(htmlContent => {
                console.log("Setting HTML report");
                setHtmlReport(htmlContent);
                setShowHtmlReport(true);
                setLoading(false); // Set loading to false when fetching is complete
              });
          } else if (metadata.html_report) {
            console.log('Fetching HTML report content');
            setHtmlReport(metadata.html_report);
            setShowHtmlReport(true);
            setLoading(false); // Set loading to false when fetching is completegit 
          }
        })
        .catch(error => {
          console.error("Error fetching HTML report:", error);
          setLoading(false); // Set loading to false in case of error
        });
    } else {
      setShowHtmlReport(false);
      setHtmlReport(null);
      setCurrentHistory(null); // Reset the current history
    }
  };
  const [showVideo, setShowVideo] = useState(false);
  const [videoSrc, setVideoSrc] = useState(null);

  const toggleVideo = (history) => {
    if (!showVideo) {
      setLoading(true);
      setCurrentHistory(history);
      
      // Fetch metadata to get session_id
      fetch(history.metadata)
        .then(response => response.json())
        .then(metadata => {
          if (metadata.session_id) {
            console.log("Session ID:", metadata.session_id);
            return getBrowserbaseVideo(metadata.session_id);
          } else {
            throw new Error('No session_id found in metadata');
          }
        })
        .then(videoBlob => {
          const videoUrl = URL.createObjectURL(videoBlob);
          setVideoSrc(videoUrl);
          setShowVideo(true);
          
          // Initialize rrweb player
          new rrwebPlayer({
            target: document.body, // Make sure to add this element in your JSX
            props: {
              videoBlob
            },
          });
        })
        .catch(error => {
          console.error("Error fetching video:", error);
          // Handle error (e.g., show error message to user)
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setShowVideo(false);
      setVideoSrc(null);
      setCurrentHistory(null);
      // Stop and destroy rrweb player if it exists
      if (window.rrwebPlayer) {
        window.rrwebPlayer.pause();
        window.rrwebPlayer = null;
      }
    }
  };


  useEffect(() => {
    if (showHtmlReport && currentHistory) {
      console.log("HTML Report is now being shown for history:", currentHistory);
      console.log("Current HTML Report:", htmlReport);
    }
  }, [showHtmlReport, currentHistory]);

  // Determine the latest run status and date
  const latestHistory = histories.length > 0 ? histories[histories.length - 1] : null;
  const latestStatus = latestHistory ? (latestHistory.success ? 'Passed' : 'Failed') : 'N/A';
  const latestDate = latestHistory ? formatRelative(new Date(latestHistory.execution_time), new Date()) : 'N/A';
    
  return (
    <div className="card mb-4 shadow-lg bg-white rounded-lg overflow-hidden">
      <div className="px-4 py-5">
        <h2 className="h5 font-bold text-gray-900">{test.testname}</h2>
      </div>
      <ul className="list-group list-group-flush">
        {histories.length > 0 ? (
          histories.map((history) => (
            <li key={history.id} className="list-group-item flex items-center justify-between p-4 border-b">
              <span className={`badge ${history.success ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'} font-semibold px-3 py-1 rounded-full`}>
                {history.success ? 'Passed' : 'Failed'}
              </span>
              <span className="ms-2 text-sm text-gray-500">
                {history.execution_time ? formatRelative(new Date(history.execution_time), new Date()) : 'N/A'}
              </span>
            
              <button 
                onClick={() => toggleHtmlReport(history)}
                className="text-blue-500 hover:text-blue-700 text-sm font-semibold"
              >
                Show HTML Report
              </button>
              <button 
                onClick={() => toggleVideo(history)}
                className="text-blue-500 hover:text-blue-700 text-sm font-semibold"
              >
                View Video
              </button>
              <button 
                onClick={toggleMetadata}
                className="text-blue-500 hover:text-blue-700 text-sm font-semibold"
              >
                View Metadata
              </button>
              {showMetadata && (
                <div className="absolute top-0 left-0 bg-white p-4 shadow-lg rounded-lg">
                  <pre className="text-xs">{JSON.stringify(history.metadata, null, 2)}</pre>
                </div>
              )}
              {showVideo && (
                <div className="fixed inset-0 flex items-center justify-center p-4 z-50" onClick={() => setShowVideo(false)}>
                  <div className="bg-white p-4 shadow-2xl rounded-lg" onClick={(e) => e.stopPropagation()}>
                    <button 
                      onClick={() => setShowVideo(false)}
                      className="absolute top-0 right-0 m-2 text-red-500 hover:text-red-700 text-sm font-semibold"
                    >
                      Close Video
                    </button>
                    {videoSrc && (
                      <video controls width="640" height="480">
                        <source src={videoSrc} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    )}
                  </div>
                </div>
              )}
{showHtmlReport && (
  // Overlay with a more transparent background
  <div 
    className="fixed inset-0 flex items-center justify-center p-4 z-50"
    onClick={() => toggleHtmlReport(history)} // Close popup when clicking on the overlay
    style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)' }} // Darker but not black background
  >
   
    <div 
      className="bg-white w-full max-w-4xl overflow-auto p-4 shadow-2xl rounded-lg relative mx-auto" // Centered with auto margins
      style={{ maxHeight: '90vh', top: '50%', transform: 'translateY(-50%)' }} // Vertically centered
      onClick={(e) => e.stopPropagation()} // Stop propagation to prevent click outside from closing it
    >
      <button 
        onClick={() => toggleHtmlReport()}
        className="absolute top-0 right-0 m-2 text-red-500 hover:text-red-700 text-sm font-semibold"
      >
        Close Report
      </button>
      <iframe
              srcDoc={htmlReport}
              style={{ width: '100%', height: '80vh', border: 'none' }}
              title="HTML Report"
              sandbox="allow-same-origin allow-scripts allow-forms"
              onLoad={() => console.log('Iframe loaded successfully')}
              onError={() => console.error('Error loading iframe content')}
            />
    </div>
  </div>
)}
 {loading && (
                <div className="fixed inset-0 flex items-center justify-center p-4 z-50">
                  <div className="bg-white p-4 shadow-2xl rounded-lg">
                    <p>Loading...</p>
                  </div>
                </div>
              )}
            </li>
          ))
        ) : (
          <li className="list-group-item p-4 text-center text-gray-500">
            No execution history available for this test.
          </li>
        )}
      </ul>
      <div className="px-4 py-3 border-t">
        <p className="text-sm text-gray-700">
        <strong>Latest Run Status:</strong> {latestStatus}
        </p>
        <p className="text-sm text-gray-700">
          <strong>Latest Run Date:</strong> {latestDate}
        </p>
      </div>
    </div>
  );
}

export default TestHistoryCard