import React from "react";
import {
  CubeTransparentIcon,
  AcademicCapIcon,
    BoltIcon,
    ArrowDownIcon,
    CheckCircleIcon

} from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

function WhyCogniSimFunction(props) {
  const items = [
    {
      title: "Actions based on simple instructions",
      description:
        "Go from simple instructions like 'tap the checkmark button' or 'check the username is present' to execution.",
      icon: BoltIcon,
      iconColor: "text-blue-500",
    },
    {
      title: "Learns your app",
      description:
        "Our AI models learn from every verified test case and improve their understanding of your app.",
      icon: AcademicCapIcon,
      iconColor: "text-blue-500",
    },
    {
      title: "Reduces maintenance",
      description:
        "Tests can adapt to code changes as a semantic understanding of your app is learned. What does this mean? You no longer have to refactor tests after frontend changes.",
      icon: ArrowDownIcon,
      iconColor: "text-blue-500",
    },
    {
      title: "Test like humans do",
      description:
          "AI agents will test the way that humans do, by learning a semantic understanding of the app and inferring what the proper steps and checks should be.",
      icon: CheckCircleIcon,
      iconColor: "text-blue-500",
    },
  ];

  return (
      <Section
          size={props.size}
          bgColor={props.bgColor}
          bgImage={props.bgImage}
          bgImageOpacity={props.bgImageOpacity}
          textColor={props.textColor}
      >
        <div className="space-y-16 container">
          <div>
            <CubeTransparentIcon className="text-blue-600 mb-5 inline-block w-16 h-16" />
            <SectionHeader
                title={props.title}
                subtitle={props.subtitle}
                strapline={props.strapline}
                className="lg:w-1/2"
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-16">
            {items.map((item, index) => (
                <div
                    key={index}
                    className="bg-white rounded-lg p-6 shadow-md transition-transform transform hover:scale-105"
                >
                  <item.icon
                      className={
                          "inline-block w-8 h-8 mb-2" +
                          (item.iconColor ? ` ${item.iconColor}` : "")
                      }
                  />
                  <h3 className="text-lg font-bold uppercase tracking-wide mb-2">
                    {item.title}
                  </h3>
                  <p className="leading-relaxed text-gray-600">
                    {item.description}
                  </p>
                </div>
            ))}
          </div>
        </div>
      </Section>
  );
}
//   return (
//     <Section
//       size={props.size}
//       bgColor={props.bgColor}
//       bgImage={props.bgImage}
//       bgImageOpacity={props.bgImageOpacity}
//       textColor={props.textColor}
//     >
//       <div className="space-y-16 container">
//         <div>
//           <CubeTransparentIcon className="text-blue-600 mb-5 inline-block w-16 h-16" />
//           <SectionHeader
//             title={props.title}
//             subtitle={props.subtitle}
//             strapline={props.strapline}
//             className="lg:w-1/2"
//           />
//         </div>
//         <div className="grid grid-cols-1 md:grid-cols-3 gap-16">
//           {items.map((item, index) => (
//             <div key={index}>
//               <h3 className="flex items-center space-x-2 text-lg font-bold uppercase tracking-wide mb-2">
//                 <item.icon
//                   className={
//                     "inline-block w-5 h-5" +
//                     (item.iconColor ? ` ${item.iconColor}` : "")
//                   }
//                 />
//                 <span>{item.title}</span>
//               </h3>
//               <p className="leading-relaxed text-gray-600">
//                 {item.description}
//               </p>
//             </div>
//           ))}
//         </div>
//       </div>
//     </Section>
//   );
// }

export default WhyCogniSimFunction;
