import React, { useState, useEffect } from "react";
import { Transition, Dialog, TransitionChild, DialogTitle, DialogPanel } from "@headlessui/react";
import { useForm } from "react-hook-form";
import FormAlert from "./FormAlert";
import TextField from "./TextField";
import SelectField from "./SelectField";
import Button from "./Button";
import LoadingIcon from "./LoadingIcon";
import { useAuth } from "./../util/auth";
import { useTest, updateTest, createTest } from "./../util/db";

function EditTestModal(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);

  const { register, handleSubmit, errors } = useForm();

  const { data: testData, status: itemStatus } = useTest(props.id);

  const [tasks, setTasks] = useState(testData?.tasks || []);

  useEffect(() => {
    if (testData?.tasks) {
      setTasks(testData.tasks);
    }
  }, [testData]);

  if (props.id && itemStatus !== "success") {
    return null;
  }

  const onSubmit = (data) => {
    setPending(true);

    const query = props.id
      ? updateTest(props.id, { ...data, tasks })
      : createTest({ owner: auth.user.uid, ...data, tasks });

    query
      .then(() => {
        props.onDone();
      })
      .catch((error) => {
        setPending(false);
        setFormAlert({
          type: "error",
          message: error.message,
        });
      });
  };

  return (
    <Transition appear={true} show={true}>
      <Dialog
        as="div"
        className="overflow-y-auto fixed inset-0 z-10"
        onClose={() => props.onDone()}
      >
        <div className="px-4 min-h-screen text-center">
          <TransitionChild
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <DialogPanel className="fixed inset-0 bg-black opacity-30" />
          </TransitionChild>
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <TransitionChild
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="inline-block overflow-hidden p-6 my-8 w-full max-w-md text-left align-middle bg-white rounded-2xl shadow-xl transition-all transform">
              <DialogTitle
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {props.id ? "Update" : "Create"} Test
              </DialogTitle>
              <div className="mt-4">
                {formAlert && (
                  <div className="mb-4">
                    <FormAlert
                      type={formAlert.type}
                      message={formAlert.message}
                    />
                  </div>
                )}
                <form className="space-y-4" onSubmit={(e) => {
                      console.log("handling submit")
                      e.preventDefault();
                      e.stopPropagation();
                       handleSubmit(onSubmit)(e); // Manually call the handleSubmit function with the onSubmit callback
                      }}
                    >
                  <TextField
                    type="text"
                    id="testname"
                    name="testname"
                    placeholder="Test Name"
                    defaultValue={testData && testData.testname}
                    error={errors.testname}
                    inputRef={register({
                      required: "Please enter a test name",
                    })}
                  />
                  <SelectField
                    id="platform"
                    name="platform"
                    placeholder="Platform"
                    defaultValue={testData && testData.platform}
                    error={errors.platform}
                    inputRef={register({
                      required: "Please select a platform",
                    })}
                  >
                    <option value="">Select a platform...</option>
                    <option value="Web">Web</option>
                    <option value="Ios">Ios</option>
                    <option value="Android">Android</option>
                  </SelectField>
                  <TextField
                    type="text"
                    id="link"
                    name="link"
                    placeholder="www.example.com"
                    defaultValue={testData && testData.link}
                    error={errors.link}
                    inputRef={register({
                      required: "Please enter a link",
                    })}
                  />
                  <div className="space-x-2 flex items-stretch">
                    <Button
                      size="md"
                      variant="simple"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        props.onDone();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      size="md"
                      disabled={pending}
                      isBlock={true}
                      className="w-20"
                    >
                      {!pending && <>Save</>}
                      {pending && <LoadingIcon className="w-5" />}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
}

export default EditTestModal;
