import React, { useState } from 'react';
import { useAuth } from '../util/auth';
import { useTestsByOwner, useWorkflowsByOwner, createWorkflow, updateWorkflow } from '../util/db';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

function WorkflowItems() {
  const auth = useAuth();
  const [selectedTests, setSelectedTests] = useState([]);
  const [cronSchedule, setCronSchedule] = useState('');
  const [workflowName, setWorkflowName] = useState('');
  const [isParallel, setIsParallel] = useState(false);
  const [editingWorkflow, setEditingWorkflow] = useState(null);

  const {
    data: tests,
    status: testsStatus,
    error: testsError,
  } = useTestsByOwner(auth.user.uid);

  const {
    data: workflows,
    status: workflowsStatus,
    error: workflowsError,
  } = useWorkflowsByOwner(auth.user.uid);

  const handleTestSelection = (test) => {
    setSelectedTests(prev => 
      prev.some(t => t.id === test.id)
        ? prev.filter(t => t.id !== test.id)
        : [...prev, { id: test.id, name: test.testname }]
    );
  };

  const handleCronScheduleChange = (e) => {
    setCronSchedule(e.target.value);
  };

  const handleWorkflowNameChange = (e) => {
    setWorkflowName(e.target.value);
  };

  const handleParallelChange = (e) => {
    setIsParallel(e.target.checked);
  };

  const handleEditWorkflow = (workflow) => {
    setEditingWorkflow(workflow);
    setWorkflowName(workflow.name);
    setSelectedTests(workflow.test_sequence.map(id => ({ id, name: tests.find(t => t.id === id).testname })));
    setCronSchedule(workflow.schedule);
    setIsParallel(workflow.parallel);
  };

  const clearForm = () => {
    setWorkflowName('');
    setSelectedTests([]);
    setCronSchedule('');
    setIsParallel(false);
    setEditingWorkflow(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const workflowData = {
      name: workflowName,
      test_sequence: selectedTests.map(test => test.id),
      schedule: cronSchedule,
      parallel: isParallel,
      owner: auth.user.uid,
    };
    try {
      if (editingWorkflow) {
        await updateWorkflow(editingWorkflow.id, workflowData);
        alert('Workflow updated successfully!');
      } else {
        await createWorkflow(workflowData);
        alert('Workflow created successfully!');
      }
      clearForm();
    } catch (error) {
      console.error('Error saving workflow:', error);
      alert('Failed to save workflow. Please try again.');
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(selectedTests);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setSelectedTests(items);
  };

  if (testsStatus === 'loading' || workflowsStatus === 'loading') {
    return <div>Loading...</div>;
  }

  if (testsError || workflowsError) {
    return <div>Error loading data: {testsError?.message || workflowsError?.message}</div>;
  }

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Workflows</h1>
      
      {/* Display existing workflows */}
      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Existing Workflows</h2>
        {workflows.length === 0 ? (
          <p>No workflows created yet.</p>
        ) : (
          <ul className="list-disc pl-5">
            {workflows.map(workflow => (
              <li key={workflow.id} className="mb-2">
                <strong>{workflow.name}</strong>
                <ul className="list-circle pl-5">
                  <li>Schedule: {workflow.schedule}</li>
                  <li>Parallel: {workflow.parallel ? 'Yes' : 'No'}</li>
                  <li>Tests: {workflow.test_sequence.length}</li>
                </ul>
                <button
                  onClick={() => handleEditWorkflow(workflow)}
                  className="mt-2 bg-yellow-500 text-white px-2 py-1 rounded text-sm"
                >
                  Edit
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>

      <h2 className="text-2xl font-bold mb-4">
        {editingWorkflow ? 'Edit Workflow' : 'Create New Workflow'}
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-2">Workflow Name</h2>
          <input
            type="text"
            value={workflowName}
            onChange={handleWorkflowNameChange}
            placeholder="Enter workflow name"
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-2">Select Tests</h2>
          {tests.map(test => (
            <label key={test.id} className="block mb-2">
              <input
                type="checkbox"
                checked={selectedTests.some(t => t.id === test.id)}
                onChange={() => handleTestSelection(test)}
                className="mr-2"
              />
              <span className="font-medium">{test.testname}</span>
              <span className="ml-2 text-sm text-gray-500">ID: {test.id}</span>
            </label>
          ))}
        </div>
        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-2">Test Sequence</h2>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="testList">
              {(provided) => (
                <ul {...provided.droppableProps} ref={provided.innerRef} className="border rounded p-2">
                  {selectedTests.map((test, index) => (
                    <Draggable key={test.id} draggableId={test.id} index={index}>
                      {(provided) => (
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="bg-gray-100 p-2 mb-2 rounded flex justify-between items-center"
                        >
                          <span>{index + 1}. {test.name}</span>
                          <span className="text-sm text-gray-500">ID: {test.id}</span>
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-2">Cron Schedule</h2>
          <input
            type="text"
            value={cronSchedule}
            onChange={handleCronScheduleChange}
            placeholder="e.g., 0 9 * * *"
            className="w-full p-2 border rounded"
            required
          />
          <p className="text-sm text-gray-500 mt-1">Format: minute hour day-of-month month day-of-week</p>
        </div>
        <div className="mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={isParallel}
              onChange={handleParallelChange}
              className="mr-2"
            />
            <span className="text-lg">Run tests in parallel</span>
          </label>
        </div>
        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
          {editingWorkflow ? 'Update Workflow' : 'Create Workflow'}
        </button>
        {editingWorkflow && (
          <button
            type="button"
            onClick={clearForm}
            className="ml-2 bg-gray-500 text-white px-4 py-2 rounded"
          >
            Cancel Edit
          </button>
        )}
      </form>
    </div>
  );
}

export default WorkflowItems;