import React from "react";
import Meta from "./../components/Meta";
import ContactSection from "./../components/ContactSection";
import DemoSection from "../components/DemoSection";

function ContactPage(props) {
  return (
    <>
      <Meta title="Contact" />
      <ContactSection
        title="Get in touch"
        subtitle="If you have any questions regarding pricing, plans, or product, contact us and we will get back to you as soon as possible."
        strapline="We reply in 24hrs"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
        <DemoSection
            title={
                <>
                    Want to experience CogniSim <span className="text-blue-600">live</span>?
                </>
            }
            subtitle="Skip the wait and book a live demo today!"
            strapline=""
            size="md"
            bgColor="bg-white"
            bgImage=""
            bgImageOpacity={1}
            textColor=""
        />
    </>
  );
}

export default ContactPage;
