import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Section from "./Section";
import { Link } from "./../util/router";
import TextField from "./TextField";
import Button from "./Button";
import newsletter from "./../util/newsletter";

function Footer(props) {
  const [subscribed, setSubscribed] = useState(false);
  const { handleSubmit, register, errors } = useForm();

  const onSubmit = ({ email }) => {
    setSubscribed(true);
    // Parent component can optionally
    // find out when subscribed.
    props.onSubscribed && props.onSubscribed();
    // Subscribe them
    newsletter.subscribe({ email });
  };

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
      className={props.sticky && "mt-auto"}
    >
      <footer className="container">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
          <div>
            <h4 className="text-sm uppercase font-semibold tracking-wider text-gray-400">
              Product
            </h4>
            <nav className="flex flex-col space-y-3 mt-6">
              {[
                
                { url: "/faq", name: "FAQ" },
                // { url: "/", name: "Features" },
                // { url: "/", name: "Solutions" },
                // { url: "/", name: "Resources" },
              ].map((link, index) => (
                <Link
                  to={link.url}
                  className="font-medium text-gray-400 hover:text-gray-500"
                  key={index}
                >
                  {link.name}
                </Link>
              ))}
            </nav>
          </div>
          <div>
            <h4 className="text-sm uppercase font-semibold tracking-wider text-gray-400">
              Company
            </h4>
            <nav className="flex flex-col space-y-3 mt-6">
              {[
                { url: "/about", name: "Why CogniSim?" },
                { url: "/contact", name: "Contact" },
                // { url: "/legal/terms-of-service", name: "Terms of Service" },  //TODO need to create legal docs
                // { url: "/legal/privacy-policy", name: "Privacy Policy" },
              ].map((link, index) => (
                <Link
                  to={link.url}
                  className="font-medium text-gray-400 hover:text-gray-500"
                  key={index}
                >
                  {link.name}
                </Link>
              ))}
            </nav>
          </div>
          <div>
            <h4 className="text-sm uppercase font-semibold tracking-wider text-gray-400">
              Social
            </h4>
            <div className="mt-6 flex flex-row space-x-4">
              {[
                {
                  url: "https://twitter.com/CogniSimAi",
                  icon: (
                      <path
                          d="M24 4.557a9.83 9.83 0 01-2.828.775 4.932 4.932 0 002.165-2.724 9.864 9.864 0 01-3.127 1.195 4.916 4.916 0 00-3.594-1.555c-3.179 0-5.515 2.966-4.797 6.045A13.978 13.978 0 011.671 3.149a4.93 4.93 0 001.523 6.574 4.903 4.903 0 01-2.229-.616c-.054 2.281 1.581 4.415 3.949 4.89a4.935 4.935 0 01-2.224.084 4.928 4.928 0 004.6 3.419A9.9 9.9 0 010 19.54a13.94 13.94 0 007.548 2.212c9.142 0 14.307-7.721 13.995-14.646A10.025 10.025 0 0024 4.557z"/>
                  ),
                },
                {
                  url: "https://www.linkedin.com/company/cognisim/",
                  icon: (
                      <path
                          d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                  ),
                },
                {
                  url: "https://github.com/CogniSim",
                  icon: (
                      <path
                          d="M12 0C5.374 0 0 5.373 0 12c0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23A11.509 11.509 0 0112 5.803c1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576C20.566 21.797 24 17.3 24 12c0-6.627-5.373-12-12-12z"/>
                  ),
                },
                {
                  url: "mailto:cognisim.ai@gmail.com",
                  icon: (
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                           className="bi bi-envelope" viewBox="0 0 16 16">
                        <path
                            d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
                      </svg>),
                },
              ].map((link, index) => (
                  <a href={link.url} target="_blank" rel="noreferrer" className="text-gray-400 hover:text-blue-600" key={index}>
                    <svg className="inline-block w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                      {link.icon}
                    </svg>
                  </a>
              ))}
            </div>
            {/*<h4 className="mt-10 text-sm uppercase font-semibold tracking-wider text-gray-400">*/}
            {/*  Join Our Newsletter*/}
            {/*</h4>*/}
            {/*{subscribed === true && (*/}
            {/*  <div className="mt-3">You are now subscribed!</div>*/}
            {/*)}*/}
            {/*{subscribed === false && (*/}
            {/*  <form*/}
            {/*    className="mt-6 flex items-start space-x-2"*/}
            {/*    onSubmit={handleSubmit(onSubmit)}*/}
            {/*  >*/}
            {/*    <div className="grow">*/}
            {/*      <TextField*/}
            {/*        type="email"*/}
            {/*        id="email"*/}
            {/*        name="email"*/}
            {/*        placeholder="Email"*/}
            {/*        error={errors.email}*/}
            {/*        size="sm"*/}
            {/*        inputRef={register({*/}
            {/*          required: "Please enter an email address",*/}
            {/*        })}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*    <Button type="submit" size="sm">*/}
            {/*      Subscribe*/}
            {/*    </Button>*/}
            {/*  </form>*/}
            {/*)}*/}
          </div>
        </div>
      </footer>
    </Section>
  );
}

export default Footer;
