import React, { useState, useEffect } from "react";
import { useAuth } from "./../util/auth";
import { getTestExecutions } from "./../util/db";
import {
  AreaChart,
  Area,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  YAxis,
} from "recharts";
import Widget from "./widget";
import List from './table'; // Import the List component
import FailingTests from './failingtests'; // Import the FailingTests component
import { getApiKey } from "./../util/db"; // Ensure this import is correct
import { UserIcon } from '@heroicons/react/24/solid'; // Import the UserIcon from Heroicons v2
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // Use localhost:8000 as the base URL
import { traceSpan } from "../helpers/tracing/index.ts";

function DashboardSection(props) {
  const auth = useAuth();
  const [dataPoints, setDataPoints] = useState([]);
  const [testExecutions, setTestExecutions] = useState(null);
  const [testHistoryCount, setTestHistoryCount] = useState(null);
  const [latestSuccessRate, setLatestSuccessRate] = useState(null);
  const [latestFailureCount, setLatestFailureCount] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  // New state variables for previous values and differences
  const [prevSuccessRate, setPrevSuccessRate] = useState(null);
  const [prevFailureCount, setPrevFailureCount] = useState(null);
  const [successRateDiff, setSuccessRateDiff] = useState(null);
  const [failureCountDiff, setFailureCountDiff] = useState(null); 
  const [apiKey, setApiKey] = useState(null);

  useEffect(() => {
    traceSpan('fetchApiKey', async () => {
      try {
        const key = await getApiKey(auth.user.uid);
        setApiKey(key);
      } catch (error) {
        console.error("Error fetching API key:", error); 
      }
    });
  }, [auth.user.uid]);

  useEffect(() => {
    if (auth.user && apiKey) {
      traceSpan('fetchDashboardData', async () => {
        try {
          const testExecutions = await traceSpan('getTestExecutions', () => 
            getTestExecutions(auth.user.uid)
          );
          setTestExecutions(testExecutions);

          await traceSpan('fetchTestSuccessRates', async () => {
            const url = `${API_BASE_URL}/get_user_test_success_rate?user_id=${auth.user.uid}`;
            console.log(`Fetching test success rates with URL: ${url}`);
            const response = await fetch(url, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${apiKey}`,
                'Content-Type': 'application/json'
              }
            });
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            const points = Object.keys(data.data).map((month) => ({
              name: month,
              PassRate: data.data[month].success_rate,
              TotalTests: data.data[month].total_tests,
            }));
            setDataPoints(points);
          });

          await traceSpan('fetchTestHistoryCount', async () => {
            const url = `${API_BASE_URL}/get_user_tests_and_history_count?user_id=${auth.user.uid}`;
            console.log(`Fetching test history count with URL: ${url}`);
            const response = await fetch(url, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${apiKey}`,
                'Content-Type': 'application/json'
              }
            });

            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            setTestHistoryCount(data.test_history_count);
            setLatestSuccessRate(data.latest_success_rate);
            setLatestFailureCount(data.latest_failure_count);

            // Calculate differences
            if (prevSuccessRate !== null) {
              setSuccessRateDiff(data.latest_success_rate - prevSuccessRate);
            }
            if (prevFailureCount !== null) {
              setFailureCountDiff(data.latest_failure_count - prevFailureCount);
            }

            // Update previous values
            setPrevSuccessRate(data.latest_success_rate);
            setPrevFailureCount(data.latest_failure_count);

            console.log(`Test history count: ${data.test_history_count}`);
          });

          setLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
        }
      });
    }
  }, [auth.user, apiKey]);

  const data = {
    totalTests: testHistoryCount,
    passRate: latestSuccessRate,
    testsToReview: latestFailureCount,
    passRateDiff: successRateDiff !== null ? successRateDiff : undefined, // Only pass if there's a difference
    testsToReviewDiff: failureCountDiff !== null ? failureCountDiff : undefined, // Only pass if there's a difference
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip bg-white p-1 border rounded shadow">
          <p className="text-Black pl-2 label">{`${label}`}</p>
          <p className="text-green-400 p-2 intro">{`PassRate : ${payload[0].value.toFixed(2)}%`}</p>
          <p className="text-gray-400 p-2 desc">{`Total Tests Ran: ${payload[0].payload.TotalTests}`}</p>
        </div>
      );
    }

    return null;
  };

  if (loading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white">
        <div>Loading...</div>
      </div>
    );
  }

  return traceSpan('renderDashboardSection', () => (
    <div className="w-full h-full overflow-auto bg-white flex flex-col">
      
        <div className="flex-grow text-black bg-gray-100 grid grid-cols-1 lg:grid-cols-3 gap-10 m-10 border border-slate-500 rounded-2xl p-4">
          <div className="flex-row lg:col-span-2 gap-8">
            <div className="col-span-1 lg:col-span-2 flex flex-wrap gap-8 my-2">
              <Widget type="totalTests" data={{ totalTests: testHistoryCount }} />
              <Widget type="passRate" data={{ passRate: latestSuccessRate, passRateDiff: successRateDiff }} />
              <Widget type="testsToReview" data={{ testsToReview: latestFailureCount, testsToReviewDiff: failureCountDiff, testsToReviewDiffColor: failureCountDiff > 0 ? 'red' : failureCountDiff < 0 ? 'green' : undefined }} />
            </div>
            {/* Test Suite Pass Rate */}
            <div className="col-span-1 lg:col-span-2 bg-white border border-slate-400 my-8 rounded-lg p-4">
              <p className="font-bold mb-2">Test Suite Pass Rate By Month</p>
              <div className="h-full flex items-center justify-center">
                <ResponsiveContainer width="100%" height={400}>
                  <AreaChart
                    data={dataPoints.slice().reverse()} // Reverse the data points
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                  >
                    <defs>
                      <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#34D399" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#34D399" stopOpacity={0} />
                      </linearGradient>
                    </defs>
                    <XAxis
                      dataKey="name"
                      stroke="gray"
                      tickFormatter={(tick, index) => (index % 2 === 1 ? tick : '')} // Show every second month starting from the second month
                    />
                    <YAxis scale="linear" domain={[1, 0]} /> {/* Added YAxis to flip the graph */}
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip content={<CustomTooltip />} />
                    <Area
                      type="monotone"
                      dataKey="PassRate"
                      stroke="#34D399"
                      fillOpacity={1}
                      fill="url(#total)"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className="col-span-1 lg:col-span-2 bg-white border border-slate-400 rounded-lg my-8 p-4 w-full">
              <p className="font-bold p-2 mb-4">Deployed Test Suite</p>
              <List /> {/* Use the imported List component here */}
            </div>
          </div>
          <div className="flex flex-col gap-8">
            <div className="flex-row lg:col-span-1">
              {/* Run Workflow */}
              <div className="col-span-1 bg-white border border-slate-400 lg:mt-[70px] rounded-lg p-4">
                <p className="font-bold p-2 mb-4">Failing Tests</p>
                <div className="h-full flex items-center justify-center">
                  <FailingTests /> {/* Use the imported FailingTests component here */}
                </div>
              </div>
              {/* User Information */}
              <div className="col-span-1 lg:col-span-1 bg-white border border-slate-400 rounded-lg p-4 mt-6 flex items-center">
                <div className="flex items-center">
                  <div className="rounded-full bg-slate-100 border border-slate-500 h-12 w-12 flex items-center justify-center mr-4">
                    <UserIcon className="h-6 w-6 text-slate-500" />
                  </div>
                  <div>
                    <p className="font-bold">{auth.user.name}</p>
                    <p>
                      Plan:{" "}
                      <span className="bg-gray-500 text-white px-2 py-1 rounded">
                        Pro
                      </span>
                    </p>
                    <p>Test Executions Remaining: {testExecutions}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
  ));
}

export default DashboardSection;