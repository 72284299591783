import React, { useState, useEffect, useRef } from 'react';
import { Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Popover,
  PopoverButton,
  PopoverOverlay,
  PopoverPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { requireAuth } from "./../util/auth";
import { useAuth } from "./../util/auth";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TaskForm from "../components/execute/TaskForm";
import InstructionPanel from "../components/execute/InstructionPanel";
import ControlPanel from "../components/execute/ControlPanel";
import ShowStream from '../components/execute/ShowStream';
import TaskList from "../components/execute/TaskList";
import TaskItem from "../components/execute/TaskItem";
import {
    createTest,
    getTestByUid,
    decrementTestExecutions,
    getTestExecutions,
    addTestHistory,
    getActiveApiKey,
    updateTest
} from "./../util/db";

import { faPlay, faStop, faRecordVinyl, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RightSide from '../components/execute/RightSide';

const BASE_STEP_URL = process.env.REACT_APP_STEP_API;
console.log(BASE_STEP_URL);
function FullScreenExecuteTestPage() {
    const auth = useAuth();
    const [session, loading] = useState(auth.session);
    const [showStream, setShowStream] = useState(false);
    const toggleStream = () => {
        setShowStream(!showStream);
    }
    const [apiKey, setApiKey] = useState(null);
    const [testUid, setTestUid] = useState('');
    // Add local mode
    const [isLocalMode, setIsLocalMode] = useState(false);
    const toggleLocalMode = () => setIsLocalMode(!isLocalMode);
    const BASE_STEP_URL = isLocalMode ? "http://localhost:8001" : process.env.REACT_APP_STEP_API;
    const [viewportSize, setViewportSize] = useState({ width: 1920, height: 1080 });
    const [isEditingTask, setIsEditingTask] = useState(false);
    const [isEditingVariables, setIsEditingVariables] = useState(false);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const urlTestUid = urlParams.get('testUid');

        const storedTestUid = urlTestUid || localStorage.getItem('currentTestUid');

        //If there is one then set the values of the test accordingly
        if (storedTestUid) {
            // Fetch the corresponding test from the database
            setTestUid(storedTestUid);
            getTestByUid(storedTestUid).then(test => {
                console.log(test);
                
                // Not sure if this is the best way to do this
                if (tasks.length === 0) {
                    // If no tasks exist, add a default navigate as first task
                    setTasks([{ step_type: 'navigate', step: test.link, multimodal: false, id: uuidv4() }]);
                }

                
                // setEmail(test.email);
                setFlowDescription(test.testname);
                setAppPackage(test.link);
                setSchedule(test.schedule);
                setTracing(test.tracing);
                console.log("Tracing keys:", Object.keys(test.tracing).join(", "));
                console.log("Test keys:", Object.keys(test).join(", "));
                setTracingConfig(test.tracingconfig);
                const updatedTasks = test.tasks.map(t => ({
                ...t,
                multimodal: t.multimodal || false, // Use existing multimodal value or default to false
                    id: t.id || uuidv4() // Assign a new UUID if no ID exists
                }));
                setTasks(updatedTasks);
                setPlatform(test.platform);

            }).catch(error => {
                console.error("An error occurred while fetching the test:", error);
            });
        }

    }, []);
    //add option for recording breakpoint
    const [breakpointIndex, setBreakpointIndex] = useState(null);

    const [isMultiModal, setIsMultiModal] = useState(false);
    const [isFastExecution, setIsFastExecution] = useState(false);
    // const [email, setEmail] = useState('');
    const [flowdescription, setFlowDescription] = useState('Test Case Name');
    const [appPackage, setAppPackage] = useState('');
    const [topK, setTopK] = useState('');
    const [result, setResult] = useState(null);
    const [tasks, setTasks] = useState([]);
    const [deviceStatus, setDeviceStatus] = useState("Start Device");
    const [deviceStatusColor, setDeviceStatusColor] = useState("Green");
    const [generatedTaskList, setGeneratedTaskList] = useState('');
    const [sessionUrl, setSessionUrl] = useState('');
    const [platform, setPlatform] = useState('Web')
    //Add hover effect to the button
    const [hoverIndex, setHoverIndex] = useState(null); 
    const [hoverTimeoutId, setHoverTimeoutId] = useState(null); 
    //For options on each task 
    const [showOptions, setShowOptions] = useState({});
    //Set schedule
    const [schedule, setSchedule] = useState('');
    const toggleOptions = (index) => {
        setShowOptions(prev =>
            ({
                ...prev,
                [index]: !prev[index]
            }))
    }

    //Set tracing to be true or false
    const [tracing, setTracing] = useState(false);
    const [tracingConfig, setTracingConfig] = useState('');


    //Generate tracking id based on current Session
    
    const [isDeviceLoading, setIsDeviceLoading] = useState(false);
    const userUid = auth.user.uid;
    useEffect(() => {
        //Default to web
        if (!apiKey) {
            console.log(auth.user)
            getActiveApiKey(auth.user.uid)
            .then(apiKey => {
                console.log(`API Key: ${apiKey}`);
                setApiKey(apiKey);
            })
            .catch(error => console.error("An error occurred while fetching the API key:", error));
        }
        
        //setIsDeviceLoading(false);
    }, []);
    useEffect(() => {
        if (apiKey && appPackage) {
            startDevice();
        }
    }, [apiKey,  appPackage]);
    //Function to check if a device exists
    // Function to check if a device exists

    const handleDuplicateTest = async () => {
        const newTestName = prompt("Enter a new name for the new test", `${flowdescription} (Copy)`);
        if (newTestName) {
            const newTest = {
                owner: userUid,
                testname: newTestName,
                platform: platform,
                link: appPackage,
                tasks: tasks,
                schedule: schedule
                
            };
        try {
            const response = await createTest(newTest);
            alert("Test duplicated successfully");
            window.location.href = `/execute?testUid=${response.id}`;
        } catch (error) {
            console.error("Error duplicating test:", error);
                alert("An error occurred while duplicating the test");
            }
        }
    };
    const checkDeviceExists = async (userUid) => {
        try {
            const response = await fetch(`${BASE_STEP_URL}/device_exists/${userUid}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${apiKey}`, // Assuming you need authorization
                    'Content-Type': 'application/json'
                },
            });

            if (response.ok) {
                const result = await response.json();
                return result.exists;
            } else {
                console.error("Failed to check device existence");
                return false;
            }
        } catch (error) {
            console.error("An error occurred while checking dvice existence:", error);
            return false;
        }
    };
  

    // State to store the WebSocket connection
    const [isRecording, setIsRecording] = useState(false);
    const [webSocket, setWebSocket] = useState(null);
    const breakpointIndexRef = useRef(breakpointIndex);

    useEffect(() => {
        breakpointIndexRef.current = breakpointIndex;
    }, [breakpointIndex]);

    const toggleRecording = () => {
        if (webSocket && webSocket.readyState === WebSocket.OPEN) {
            console.log("Stopping recording...");
            webSocket.send("stop");
            webSocket.close();
        } else {
            console.log("Starting recording...");
            if (!testUid) {
                console.error("No Test ID found");
                alert("No Test ID found. Please create or select a test before starting the recording.");
                return;
            }
            if (!userUid) {
                console.error("No User ID found");
                return;
            }

            const protocolStrippedURL = BASE_STEP_URL.replace(/^(http:\/\/|https:\/\/)/, '');
            const wsProtocol = BASE_STEP_URL.startsWith('https') ? 'wss://' : 'ws://';
            const wsURL = `${wsProtocol}${protocolStrippedURL}/ws/record/${userUid}/${testUid}`;
            console.log(wsURL);
            const newWebSocket = new WebSocket(wsURL);

            newWebSocket.onopen = () => {
                console.log("WebSocket connection established. Recording started.");
                newWebSocket.send("Hello server");
                setIsRecording(true);
            };

            newWebSocket.onmessage = (event) => {
                console.log("Message from server:", event.data);
                try {
                    const stepData = JSON.parse(event.data);
                    if (stepData && stepData.id && stepData.step_type && stepData.step) {
                        setTasks(prevTasks => {
                            let updatedTasks = [...prevTasks];
                            const taskIndex = prevTasks.findIndex(task => task.id === stepData.id);
                            const currentBreakpointIndex = breakpointIndexRef.current;
                            console.log("Current breakpoint index: ", currentBreakpointIndex);

                            if (currentBreakpointIndex != null && currentBreakpointIndex < updatedTasks.length ) {
                                if (taskIndex !== -1) {
                                    updatedTasks[taskIndex] = {
                                        ...updatedTasks[taskIndex],
                                        ...stepData,
                                        metadata: stepData.metadata
                                    };
                                } else {
                                    updatedTasks.splice(currentBreakpointIndex + 1, 0, {
                                        ...stepData,
                                        metadata: stepData.metadata
                                    });
                                    setBreakpointIndex(currentBreakpointIndex + 1);
                                }
                                console.log("Updated tasks: ", updatedTasks);
                            } else if (taskIndex !== -1) {
                                console.log("Recorded: Updating existing task");
                                console.log(stepData.metadata);
                                updatedTasks[taskIndex] = {
                                    ...updatedTasks[taskIndex],
                                    ...stepData,
                                    metadata: stepData.metadata 
                                };
                            } else {
                                updatedTasks.push({
                                    ...stepData,
                                    metadata: stepData.metadata 
                                });
                            }
                            return updatedTasks;
                        });
                    } else {
                        console.warn("Received JSON message does not match expected task format:", event.data);
                    }
                } catch (error) {
                    console.warn("Received non-JSON message:", event.data);
                }
            };

            newWebSocket.onerror = (error) => {
                console.error("WebSocket error:", error);
            };

            newWebSocket.onclose = () => {
                console.log("WebSocket connection closed. Recording stopped.");
                setIsRecording(false);
            };
            setWebSocket(newWebSocket);
        }
    };

    // Function to handle input changes in the task list
    //Save test validation

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
   

    const handleCheckedChange = (e, index) => {
        const { checked } = e.target;
        setTasks(prevTasks => prevTasks.map((task, i) => {
            if (i === index) {
                return { ...task, multimodal: checked };
            }
            return task;
        }));
    };
   // Function to make authorized fetch requests
    const authorizedFetch = async (endpoint, method, body = {}) => {
       console.log(BASE_STEP_URL)
        const options = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${apiKey}`
            },
        }
    
        // Only include body if method isn't GET
        if (method !== 'GET') {
            options.body = JSON.stringify(body);
        }
    
        const response = await fetch(`${BASE_STEP_URL}${endpoint}`, options);
        if (response.ok) {
            const result = await response.json();
            return result;
        }
    }

    const startDevice = async () => {
        if (!apiKey) {
            console.error("No API key found");
            return;
        }
        console.log(viewportSize);
        setIsDeviceLoading(true);
        console.log(tracing);
        console.log(tracingConfig);
        const body = {
            platform: platform,
            app_package: appPackage, // Assuming appPackage is the URL you want to start the device with
            email: '', // Assuming there's no email to be sent, otherwise replace '' with the actual email variable
            user_id: userUid,
            test_id: testUid,
            local: isLocalMode, // Assuming isLocalMode is a boolean indicating if it's a local run
            viewport: viewportSize,
            tracing: tracing,
            tracingconfig: tracingConfig

        };
        //random change
        const response = await authorizedFetch("/start_device", "POST", body);
        const result = await response;
        if (result['result'] !== "failure") {
            console.log(result);
            setDeviceStatus("Stop Device");
            setDeviceStatusColor("red");
            if(platform === "Web") {
                console.log(result['result'])
               
                const sessionUrlValue = result['result'];
                //console.log(sessionUrlValue)
                setSessionUrl(sessionUrlValue);
            
            }
        } else {
            console.error("Failed to start device");
        }
        setIsDeviceLoading(false);
    };
    // Function to get endpoint based on platform
    const getEndpoint = async (platform, endpoints) => {
        const endpoint = endpoints[platform];
        if (endpoint) {
            return endpoint;
        } else {
            console.error("No endpoint found for platform:", platform);

        }
    }
    const clickDelay = 300; // Milliseconds

    // Function to execute a single step
    const [testResult, setTestResult] = useState(null);
    // Function to execute entire task
    const executeTask = async (isDownloads) => {
        //Execute a test case based on multiple tasks
        const deviceExists = await checkDeviceExists(userUid);
        if (deviceExists) {
            console.log("Device exists already continuiing execution");
            setDeviceStatus("Stop Device");
            setDeviceStatusColor("red");
        } else {
            console.log("Device does not exist. Starting device...")
            await startDevice();
        }
        console.log("Multi-modal: " + isMultiModal);
        const SPEED_MAPPING = {
            EXTENSIVE: 1,
            NORMAL: 2,
            FAST: 3
        };
        const taskData = {
            platform: platform,
            // email: email,
            flowdescription: flowdescription,
            tasks: tasks,
            app_package: appPackage,
            multimodal: isMultiModal,
            user_id: userUid,
            tracking_id: userUid,
            local: isLocalMode,
            test_id: testUid,
            speed: SPEED_MAPPING[isFastExecution ? "FAST" : "NORMAL"],
            tracing: tracing,
            tracingconfig: tracingConfig
        };
        const testExecutions = await getTestExecutions(auth.user.uid);
        if (testExecutions < tasks.length) {
            alert('You do not have enough test executions left. Please upgrade your plan to continue.');
            return;
        }
        console.log(`Type of email: ${typeof taskData.email}`);
        // get response from Fast API
        const response = await authorizedFetch("/execute_test", "POST", taskData);
        
        if (response) {
            try {
                const parsedResults = JSON.parse(response.result);
                setTestResult(parsedResults);
                console.log("Setting test results")
                console.log(parsedResults);
            } catch (error) {
                console.error("Failed to parse test results:", error);
            }
        }
}


   // Function to stop device
   const killDevice = async () => {
    // Updated endpoint to match the new API endpoint '/stop_device'
    const endpoint = "/stop_device";

    // Assuming tracking_id is the unique identifier for the device to stop
    const requestData = {
        user_id: userUid,
        test_id: testUid
    };

    // Assuming authorizedFetch can handle the new endpoint correctly
    const response = await authorizedFetch(endpoint, "POST", requestData);
    if (response && response.result === "success") {
        console.log("Device stopped successfully");
        setDeviceStatus("Start Device");
        setShowStream(false);
        setSessionUrl('');
        setDeviceStatusColor("red");
    } else {
        console.log("Failed to stop device");
        setDeviceStatus("Stop Device");
        setDeviceStatusColor("red");
    }
    //Duplicate test

    //Duplicate test
   
};

  return (
    <Fragment>
      {apiKey ? (
        <div className="min-h-screen flex flex-col bg-white">
          
          <main className="flex-grow flex overflow-hidden">
            <div className="w-1/3 bg-white p-4 flex flex-col h-full overflow-hidden mt-[5vh]">
              <div 
                className={`flex-grow overflow-auto ${isEditingTask || isEditingVariables ? '' : 'border border-gray-300 rounded-lg'}`}
                style={{
                  maxHeight: 'calc(100vh - 10svh - )', // Subtracting top margin and padding
                  
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <div className="flex-grow">
                  <TaskForm
                    flowDescription={flowdescription}
                    setFlowDescription={setFlowDescription}
                    platform={platform}
                    setPlatform={setPlatform}
                    appPackage={appPackage}
                    setAppPackage={setAppPackage}
                    schedule={schedule}
                    setSchedule={setSchedule}
                    testUid={testUid}
                    handleDuplicateTest={handleDuplicateTest}
                    executeTask={executeTask}
                    isDownloads={false}
                    isEditingTask={isEditingTask}
                    setIsEditingTask={setIsEditingTask}
                    isEditingVariables={isEditingVariables}
                    setIsEditingVariables={setIsEditingVariables}
                    userId={userUid}
                    activeApi={BASE_STEP_URL}
                    apiKey={apiKey}
                    tracing={tracing}
                    setTracing={setTracing}
                    tracingConfig={tracingConfig}
                    setTracingConfig={setTracingConfig}
                  />
                  {!isEditingTask && !isEditingVariables && (
                    <>
                      <TaskList
                        tasks={tasks}
                        setTasks={setTasks}
                        handleCheckedChange={handleCheckedChange}
                        showOptions={showOptions}
                        toggleOptions={toggleOptions}
                        hoverIndex={hoverIndex}
                        setHoverIndex={setHoverIndex}
                        hoverTimeoutId={hoverTimeoutId}
                        setHoverTimeoutId={setHoverTimeoutId}
                        isMultiModal={isMultiModal}
                        setIsMultiModal={setIsMultiModal}
                        executeTask={executeTask}
                        isDownloads={false}
                        appPackage={appPackage}
                        auth={auth}
                        platform={platform}
                        flowdescription={flowdescription}
                        userUid={userUid}
                        isLocalMode={isLocalMode}
                        authorizedFetch={authorizedFetch}
                        testId={testUid}
                        isFastExecution={isFastExecution}
                        setIsFastExecution={setIsFastExecution}
                        breakpointIndex={breakpointIndex}
                        setBreakpoint={setBreakpointIndex}
                        schedule={schedule}
                        setTestUid={setTestUid}
                        isRecording={isRecording}
                        setIsRecording={setIsRecording}
                        toggleRecording={toggleRecording}
                        webSocket={webSocket}
                        setWebSocket={setWebSocket}
                        BASE_STEP_URL={BASE_STEP_URL}
                        apiKey={apiKey}
                        tracing={tracing}
                        tracingConfig={tracingConfig}
                        setTracingConfig={setTracingConfig}
                        setTracing={setTracing}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="w-2/3 bg-gray-200 my-4 p-4 rounded-lg">
              {/* Right column area */}
              <RightSide
                isLocalMode={isLocalMode}
                toggleLocalMode={toggleLocalMode}
                deviceStatus={deviceStatus}
                startDevice={startDevice}
                killDevice={killDevice}
                isDeviceLoading={isDeviceLoading}
                sessionUrl={sessionUrl}
                flowDescription={flowdescription}
                platform={platform}
                appPackage={appPackage}
                userUid={userUid}
                auth={auth}
                tasks={tasks}
                testUid={testUid}
                setTasks={setTasks}
                setViewportSize={setViewportSize}
                setTestUid={setTestUid}
                testResult={testResult}
                apiKey={apiKey}
                breakpointIndex={breakpointIndex}
                setBreakpoint={setBreakpointIndex}
                schedule={schedule}
                setSchedule={setSchedule}

              />
            </div>
           
          </main>
        </div>
      ) : (
        <div className="container mx-auto p-4 space-y-4">
          <h4 className="text-2xl font-bold">Execute Test</h4>
          <p>Please wait while we fetch your API key...</p>
          <p>If it does not load then go to settings and create a new API Key</p>
        </div>
      )}
    </Fragment>

  );
}

export default requireAuth(FullScreenExecuteTestPage);



