import React from "react";
import Meta from "./../components/Meta";
import TestSection from "./../components/TestSection";
import DashboardSection from "./../components/DashboardSection";
import { requireAuth } from "./../util/auth";

function Dashboard({ title, subtitle, strapline, size, bgColor }) {
  return (
    <>
      <Meta title={title || "Dashboard"} />
    
      <DashboardSection
        title={title}
        subtitle={subtitle}
        strapline={strapline}
        size={size}
        bgColor={bgColor}
        // Add any additional props needed for DashboardSection
      />
    </>
  );
}

export default requireAuth(Dashboard);
