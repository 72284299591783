import React, { Fragment, useState, useEffect, useRef, useCallback } from 'react';
import StepTypeSelector from './StepTypeSelector';
import Modal from './Modal';
import { Dialog, Transition, DialogPanel, DialogTitle, TransitionChild } from '@headlessui/react';
import { getTestExecutions } from '../../util/db';
import { EllipsisVerticalIcon, PlayIcon as PlayIconSolid, TrashIcon } from '@heroicons/react/24/solid';
import ReactDOM from 'react-dom';
import './tooltip.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const TaskItem = ({
    task,
    tasks,
    index,
    currentStep,
    handleInputChange,
    toggleOptions,
    showOptions,
    handleCheckedChange,
    handleLocate,
    hoverTimeoutId,
    setHoverTimeoutId,
    setHoverIndex,
    hoverIndex,
    setTasks,
    executeStepSingle,
    runStepsTillHere,
    stepOutput,
    stepTypes,
    isSelected,
    testUid,
    apiKey
}) => {
    const handleValidationTypeChange = (e, index) => {
        const newTasks = [...tasks];
        newTasks[index].metadata = {
            ...newTasks[index].metadata,
            validation_type: e.target.value
        };
        setTasks(newTasks);
    };
    const [isRunStepHovered, setIsRunStepHovered] = useState(false);
    const [countdown, setCountdown] = useState(1);
    const [showRunTillHere, setShowRunTillHere] = useState(false);
    const handleRemoveClick = (index) => {
        const list = [...tasks];
        list.splice(index, 1);
        setTasks(list);
    };
    const handleMouseEnter = () => {
        setIsRunStepHovered(true);
        let count = 1;
        const intervalId = setInterval(() => {
            count--;
            setCountdown(count);
            if (count === 0) {
                clearInterval(intervalId);
                setShowRunTillHere(true);
                setHoverTimeoutId(setTimeout(() => {
                    setHoverIndex(index);
                }, 100));
            }
        }, 1000);
        setHoverTimeoutId(intervalId);
    };

    const handleMouseLeave = () => {
        setIsRunStepHovered(false);
        setCountdown(2);
        setShowRunTillHere(false);
        if (hoverTimeoutId) {
            clearTimeout(hoverTimeoutId);
            clearInterval(hoverTimeoutId);
            setHoverTimeoutId(null);
        }
    };
    const removeInputSelector = () => {
        const newTasks = [...tasks];
        if (newTasks[index].metadata) {
            delete newTasks[index].metadata.input_selector;
            if (Object.keys(newTasks[index].metadata).length === 0) {
                delete newTasks[index].metadata;
            }
        }
        setTasks(newTasks);
    };

    useEffect(() => {
        return () => {
            if (hoverTimeoutId) {
                clearTimeout(hoverTimeoutId);
                clearInterval(hoverTimeoutId);
            }
        };
    }, [hoverTimeoutId]);

    const handleValidationTextChange = (e, index) => {
        const newTasks = [...tasks];
        newTasks[index].metadata = {
            ...newTasks[index].metadata,
            validation_text: e.target.value
        };
        setTasks(newTasks);
    };
    const handleInputChangeTask = (e, index) => {
        const { name, value } = e.target;
        setTasks(prevTasks => {
            return prevTasks.map((task, i) => {
                
                if (i === index) {
                    return { ...task, [name]: value };
                } else {
                    return task;
                }
            });
        });
    };
    const handleDownloadChange = (e, index) => {
        const { checked } = e.target;
        setTasks(prevTasks => prevTasks.map((task, i ) => {
            if (i === index) {
                return { ...task, download: checked };
            }
            return task;
        }));
    }
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [jsonSchema, setJsonSchema] = useState(task.jsonSchema || '');

    const handleJsonSchemaChange = (e) => {
        setJsonSchema(e.target.value)
    }

    const handleSaveJsonSchema = () => {
        setTasks(prevTasks => {
            return prevTasks.map((task, i) => {
                if (i === index) {
                    return { ...task, jsonSchema: jsonSchema };
                } else {
                    return task;
                }
            });
        });
        setIsModalOpen(false);
    }

    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipRef = useRef(null);
    const playIconRef = useRef(null);

    const handleClickOutside = (event) => {
        if (tooltipRef.current && !tooltipRef.current.contains(event.target) && !playIconRef.current.contains(event.target)) {
            setShowTooltip(false);
        }
    };

    const [isEditingSelector, setIsEditingSelector] = useState(false);
    const [editedSelector, setEditedSelector] = useState(task.metadata?.input_selector || '');

    const handleSelectorChange = (e) => {
        setEditedSelector(e.target.value);
    };

    const saveInputSelector = () => {
        const newTasks = [...tasks];
        newTasks[index].metadata = {
            ...newTasks[index].metadata,
            input_selector: editedSelector
        };
        setTasks(newTasks);
        setIsEditingSelector(false);
    };

    useEffect(() => {
        if (showTooltip) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showTooltip]);

    useEffect(() => {
        if (showTooltip && tooltipRef.current && playIconRef.current) {
            const updateTooltipPosition = () => {
                const playIconRect = playIconRef.current.getBoundingClientRect();
                const tooltipRect = tooltipRef.current.getBoundingClientRect();
                
                tooltipRef.current.style.top = `${window.scrollY + playIconRect.bottom + 10}px`;
                tooltipRef.current.style.left = `${playIconRect.left - (tooltipRect.width / 2) + (playIconRect.width / 2)}px`;
            };

            updateTooltipPosition();
            window.addEventListener('scroll', updateTooltipPosition);
            window.addEventListener('resize', updateTooltipPosition);

            return () => {
                window.removeEventListener('scroll', updateTooltipPosition);
                window.removeEventListener('resize', updateTooltipPosition);
            };
        }
    }, [showTooltip]);

    const [codeInput, setCodeInput] = useState(task.metadata?.code_input || '');

    const handleCodeInputChange = (e) => {
        setCodeInput(e.target.value);
        const newTasks = [...tasks];
        newTasks[index].metadata = {
            ...newTasks[index].metadata,
            code_input: e.target.value
        };
        console.log("NEW TASKS", newTasks[index]);
        setTasks(newTasks);
    };

    const [savedVariables, setSavedVariables] = useState([]);

    useEffect(() => {
        console.log("TEST UID", testUid);
        console.log("API KEY", apiKey);
        if (testUid && apiKey) {
            fetchSavedVariables();
        }
    }, [testUid, apiKey]);

    const fetchSavedVariables = async () => {
        console.log('Fetching saved variables...');
        console.log('testUid:', testUid);
        console.log('apiKey:', apiKey);

        if (!testUid || testUid === 'undefined') {
            console.error('Invalid testUid. Cannot fetch variables.');
            return;
        }

        try {
            const response = await fetch(`${API_BASE_URL}/read_variables?test_uid=${testUid}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${apiKey}`,
                    'Content-Type': 'application/json'
                },
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
            }

            const data = await response.json();
            console.log('Fetched variables:', data);
            setSavedVariables(data.result);
        } catch (error) {
            console.error('Error fetching variables:', error);
            // You might want to set an error state here to display to the user
        }
    };


    const fileInputRef = useRef(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadedFileUrl, setUploadedFileUrl] = useState('');

    const handleFileButtonClick = useCallback(() => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }, []);

    const handleFileUpload = useCallback(async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append('file', file);
        formData.append('user_id', apiKey);
        formData.append('folder_name', 'upload_folder');
        formData.append('file_type', file.type);

        try {
            setUploadProgress(0);
            const response = await fetch(`${API_BASE_URL}/upload_download_to_supabase_file`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${apiKey}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            console.log("Upload response:", data);

            if (!data.url) {
                throw new Error('File URL not found in the response');
            }

            setUploadedFileUrl(data.url);

            setTasks(prevTasks => {
                const newTasks = [...prevTasks];
                newTasks[index] = {
                    ...newTasks[index],
                    metadata: {
                        ...newTasks[index].metadata,
                        file_url: data.url
                    }
                };
                return newTasks;
            });

            console.log('File uploaded successfully:', data);
        } catch (error) {
            console.error('Error uploading file:', error);
            // Set an error state here to display to the user
        }
    }, [apiKey, API_BASE_URL, index, setTasks]);

    const modalContent = (
        <Transition show={isModalOpen} as={Fragment}>
            <Dialog 
                as="div" 
                className="fixed inset-0 z-[9999] !important overflow-y-auto"
                onClose={() => setIsModalOpen(false)}
            >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <TransitionChild
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-[10000] !important" />
                    </TransitionChild>

                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>

                    <TransitionChild
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden  transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6 z-[10001] !important">
                            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                Step Output
                            </Dialog.Title>
                            <div className="mt-2 max-h-96 overflow-y-auto">
                                <pre className="whitespace-pre-wrap text-sm text-gray-500">{JSON.stringify(stepOutput, null, 2)}</pre>
                            </div>
                            <div className="mt-4">
                                <button
                                    type="button"
                                    className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
                                    onClick={() => setIsModalOpen(false)}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </TransitionChild>
                </div>
            </Dialog>
        </Transition>
    );


    return (
        <div
            className={`
                bg-white  rounded-lg my-2 px-2 mx-2
                transition-all duration-300 ease-in-out transform
                ${showOptions[index] ? ' py-1' : 'py-4'}
                ${currentStep === index ? 'ring-4 ring-blue-500 ring-opacity-50' : ''}
                ${isSelected ? 'ring-4 ring-red-500 ring-opacity-50' : ''}
                hover: hover:scale-[1.02]
                relative z-10
            `}
            style={{ position: 'relative' }}
        >
            <button
                onClick={() => handleRemoveClick(index)}
                className="absolute bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center hover:bg-red-600 transition-all duration-300 ease-in-out transform hover:scale-110 focus:outline-none focus:ring-2 focus:ring-red-400"
                aria-label="Delete Step"
                style={{ top: '-3px', right: '-2px' }}
            >
                <TrashIcon className="h-3 w-3" />
            </button>

            <div className="flex items-center space-x-2 flex-grow">
                <StepTypeSelector
                    stepType={task.step_type}
                    index={index}
                    stepTypes={stepTypes}
                    className="flex-1"
                    tasks={tasks}
                    setTasks={setTasks}
                    handleInputChange={handleInputChange}
                />
                
                <div className="relative w-full max-w-[90ch]">
                    <textarea
                        name="step"
                        className="flex-1 block w-full rounded-md border-0 py-1.5 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder={task.step_type === 'navigate' ? 'Web url' : task.step_type === 'scrape' ? `Json Schema for Task ${index + 1}` : `Task ${index + 1}`}
                        value={task.step}
                        onChange={e => handleInputChange(e, index)}
                        rows={task.rows || 2}
                        style={{ resize: 'vertical', maxWidth: '80ch' }}
                    ></textarea>
                    <div className="absolute bottom-1 right-1 w-4 h-4 pointer-events-none">
                        {task.step_type === task.original_step_type ? (
                            <span className="text-sm text-gray-500">{task.step_type}</span>
                        ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 text-gray-400">
                                <path d="M16 17.01V11h-2v6.01h-3L15 21l4-3.99h-3z"/>
                                <path d="M9 3L5 6.99h3V13h2V6.99h3L9 3z"/>
                            </svg>
                        )}
                    </div>
                </div>
                <div className="flex items-center">
                    <div className="relative">
                        <PlayIconSolid
                            ref={playIconRef}
                            className="h-10 w-8 text-black cursor-pointer transition duration-200 ease-in-out transform hover:scale-105"
                            onClick={() => setShowTooltip(!showTooltip)}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        />
                        {showTooltip && ReactDOM.createPortal(
                            <div 
                                ref={tooltipRef} 
                                className="tooltip"
                            >
                                <button
                                    className="tooltip-button"
                                    onClick={() => {
                                        executeStepSingle(index);
                                        setShowTooltip(false);
                                    }}
                                >
                                    Run Step
                                </button>
                                <button
                                    className="tooltip-button"
                                    onClick={() => {
                                        runStepsTillHere(index);
                                        setShowTooltip(false);
                                    }}
                                >
                                    Run Steps<br />Till Here
                                </button>
                            </div>,
                            document.body
                        )}
                    </div>
                    <EllipsisVerticalIcon
                        className={`h-[40px] -ml-1 text-black transition-transform duration-200 cursor-pointer ${
                            showOptions[index] ? 'rotate-180' : ''
                        }`}
                        onClick={() => toggleOptions(index)}
                    />
                </div>
            </div>

            {showOptions[index] && (
                <div className="mt-2 mb-2 transition-all duration-300 ease-in-out">
                    <div className="flex items-center justify-center space-x-4">
                        <div className="flex items-center border rounded-lg p-2">
                            <span className="text-gray-700 mr-2">Vision</span>
                            <label className="inline-flex items-center cursor-pointer">
                                <input
                                    type="checkbox"
                                    className="sr-only peer"
                                    checked={task.multimodal}
                                    onChange={e => handleCheckedChange(e, index)}
                                />
                                <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                            </label>
                        </div>
                        <div className="flex items-center border rounded-lg p-2">
                            <span className="text-gray-700 mr-2">Download</span>
                            <label className="inline-flex items-center cursor-pointer">
                                <input
                                    type="checkbox"
                                    className="sr-only peer"
                                    checked={task.download}
                                    onChange={e => handleDownloadChange(e, index)}
                                />
                                <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                            </label>
                        </div>
                        <button
                            className="btn btn-sm btn-outline px-4 py-2 h-10 text-black border border-gray-300 hover:bg-gray-300 hover:border-gray-400 transition-colors duration-200 ease-in-out"
                            onClick={() => handleLocate(index)}
                        >
                            Locate
                        </button>
                    </div>
                    {task.metadata && task.metadata.input_selector && (
                        <div className="relative flex items-center justify-between space-x-2 mt-2 bg-gray-100 p-3 rounded-lg ">
                            <span className="text-gray-700 font-semibold">Input Selector:</span>
                            {isEditingSelector ? (
                                <input
                                    type="text"
                                    value={editedSelector}
                                    onChange={handleSelectorChange}
                                    onBlur={saveInputSelector}
                                    onKeyPress={(e) => e.key === 'Enter' && saveInputSelector()}
                                    className="flex-1 text-blue-600 font-mono bg-white px-2 py-1 rounded border border-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-300"
                                    autoFocus
                                />
                            ) : (
                                <span 
                                    className="flex-1 text-blue-600 font-mono bg-white px-2 py-1 rounded border border-blue-200 cursor-pointer"
                                    onClick={() => setIsEditingSelector(true)}
                                >
                                    {task.metadata.input_selector}
                                </span>
                            )}
                            <button
                                onClick={removeInputSelector}
                                className="absolute top-0 right-0 -mt-2 -mr-2 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center hover:bg-red-600 transition-all duration-300 ease-in-out transform hover:scale-110 focus:outline-none focus:ring-2 focus:ring-red-400"
                                aria-label="Delete Input Selector"
                            >
                                <TrashIcon className="h-4 w-4" />
                            </button>
                        </div>
                    )}

                    {task.step_type.toLowerCase() === 'file_upload' && (
                        <div className="mt-4">
                            <label htmlFor={`fileUpload-${index}`} className="block text-sm font-medium text-gray-700 mb-2">
                                Upload File
                            </label>
                            {task.metadata && task.metadata.file_url ? (
                                <div className="mb-2">
                                    <p className="text-sm text-gray-600">Current file: </p>
                                    <a href={task.metadata.file_url} target="_blank" rel="noopener noreferrer" 
                                       className="text-blue-600 hover:underline break-all">
                                        {task.metadata.file_url}
                                    </a>
                                </div>
                            ) : null}
                            <div className="flex items-center">
                                <button
                                    type="button"
                                    onClick={handleFileButtonClick}
                                    className="cursor-pointer bg-blue-500 text-white py-2 px-4 rounded-l hover:bg-blue-600 transition-colors"
                                >
                                    {task.metadata && task.metadata.file_url ? 'Replace File' : 'Choose File'}
                                </button>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    onChange={handleFileUpload}
                                    className="hidden"
                                    key={uploadedFileUrl} // This will reset the input when a file is uploaded
                                />
                                <span className="border border-gray-300 rounded-r py-2 px-4 bg-gray-50 text-sm text-gray-500 flex-grow">
                                    {(task.metadata && task.metadata.file_url) ? "File selected" : "No file chosen"}
                                </span>
                            </div>
                            {uploadProgress > 0 && (
                                <div className="mt-2">
                                    <div className="bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                                        <div 
                                            className="bg-blue-600 h-2.5 rounded-full" 
                                            style={{width: `${uploadProgress}%`}}
                                        ></div>
                                    </div>
                                    <p className="text-sm text-gray-500 mt-1">{uploadProgress}% uploaded</p>
                                </div>
                            )}
                            {uploadedFileUrl && (
                                <p className="text-sm text-green-600 mt-2">
                                    File uploaded successfully. URL: {uploadedFileUrl}
                                </p>
                            )}
                        </div>
                    )}

                    {task.step_type.toLowerCase() === 'validation' && (
                        <>
                            <div className="flex items-center justify-between space-x-2">
                                <span className="text-gray-700">Validation Type</span>
                                <select
                                    className="form-select mt-1 block w-full rounded-md border-gray-300  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    value={task.metadata.validation_type || 'visibility'}
                                    onChange={(e) => handleValidationTypeChange(e, index)}
                                >
                                    <option value="visibility">Check Visibility</option>
                                    <option value="text">Check Text</option>
                                    <option value="code interpreter">Code Interpreter</option>
                                </select>
                            </div>
                            {task.metadata.validation_type === 'text' && (
                                <div className="mt-2">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Validation Text
                                    </label>
                                    <input
                                        type="text"
                                        id="validationText"
                                        className="mt-1 block w-full rounded-md border-gray-300  focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                        value={task.metadata.validation_text || ''}
                                        onChange={(e) => handleValidationTextChange(e, index)}
                                        placeholder="Enter text to validate"
                                    />
                                </div>
                            )}
                            {task.metadata.validation_type === 'code interpreter' && (
                                <div className="mt-2">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Code Input
                                    </label>
                                    <textarea
                                        className="mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                        value={codeInput}
                                        onChange={handleCodeInputChange}
                                        rows={4}
                                        placeholder="Enter Python code for validation"
                                    />
                                </div>
                            )}
                        </>
                    )}
                            

                </div>
                        
            )}

            
            {Object.keys(stepOutput).length > 0 && (
                <button
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded-lg  transition duration-300 ease-in-out transform hover:scale-105"
                    onClick={() => setIsModalOpen(true)}
                >
                    View Output
                </button>

            
            )}
            <Transition show={isModalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[9999]" onClose={() => setIsModalOpen(false)}>
                <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />
                </TransitionChild>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
                        <TransitionChild
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
                                <DialogTitle as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                    Step Output
                                </DialogTitle>
                                <div className="mt-2 max-h-96 overflow-y-auto">
                                    <pre className="whitespace-pre-wrap text-sm text-gray-500">{JSON.stringify(stepOutput, null, 2)}</pre>
                                </div>
                                <div className="mt-4">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                        onClick={() => setIsModalOpen(false)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
        </div>
    );
};

export default TaskItem;