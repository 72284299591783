import React from 'react'
import { Alert, AlertTitle, AlertDescription, AlertBody, AlertActions} from '../alert';

const ModuleSelectionAlert = ({
    isOpen,
    onClose,
    userTests,
    selectedTest,
    expandedTestID,
    loadingTestId,
    handleTestSelection,
    handleAddModuleFromTest,
    setModuleInsertIndex
}) => {
    return (
    
        <Alert open={isOpen} onClose={onClose}>
            <AlertTitle>Select a test to add</AlertTitle>
            <AlertDescription>Choose a test to view its steps and add as a module</AlertDescription>
            <AlertBody>
                <div className="mt-4 space-y-4">
                    {userTests.length === 0 ? (
                        <p>Loading tests...</p>
                    ) : (
                        userTests.map((test) => (
                            <div key={test.id} className={`border p-4 rounded-lg ${selectedTest && selectedTest.id === test.id ? 'bg-blue-100 border-blue-500' : ''}`}>
                                <h3 className="font-bold">{test.testname}</h3>
                                <button
                                    className={`mt-2 ${
                                        expandedTestID === test.id ? 'bg-red-500' : 'bg-blue-500'
                                    } text-white px-4 py-2 rounded`}
                                    onClick={() => handleTestSelection(test.id)}
                                    disabled={loadingTestId === test.id}
                                >
                                    {loadingTestId === test.id ? (
                                        <span className="animate-pulse">Loading...</span>
                                    ) : expandedTestID === test.id ? (
                                        'Close Steps'
                                    ) : (
                                        'View Steps'
                                    )}
                                </button>
                                {selectedTest && selectedTest.id === test.id && (
                                    <div className="mt-4 space-y-2">
                                        {selectedTest.tasks.map((task, taskIndex) => (
                                            <div key={taskIndex} className="border p-4 rounded-lg">
                                                <h4 className="font-bold">{task.step_type}</h4>
                                                <p>{task.step}</p>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))
                    )}
                </div>
            </AlertBody>
            <AlertActions>
                <button
                    className={`px-4 py-2 rounded ${
                        selectedTest 
                            ? 'bg-green-500 hover:bg-green-600 text-white cursor-pointer' 
                            : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                    }`}
                    onClick={() => handleAddModuleFromTest(selectedTest)}
                    disabled={!selectedTest}
                >
                    Add Module
                </button>   
                <button
                    className="bg-red-500 text-white px-4 py-2 rounded"
                    onClick={() => {
                        onClose();
                        setModuleInsertIndex(null);
                    }}
                >
                    Cancel
                </button>
            </AlertActions>
        </Alert>
    );
};

export default ModuleSelectionAlert;