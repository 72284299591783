import React, { useEffect, useState, useRef , useCallback} from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import {getTestExecutions} from '../../util/db';
import Stepoutput from "./Stepoutput";
import StepTypeSelector from './StepTypeSelector'; // Import the new component
import TaskItem from './TaskItem';
import { AcademicCapIcon as InstructionIcon, CameraIcon as ValidationIcon, CogIcon as ExtractIcon, ArrowLeftIcon as BackIcon, ArrowRightIcon as ForwardIcon, ArrowPathIcon as NavigateIcon, ArrowUturnDownIcon as RefreshIcon, ScissorsIcon as ScrapeIcon, CursorArrowRippleIcon as TapIcon, FingerPrintIcon as SwipeIcon, PencilIcon as InputIcon, TagIcon as SelectIcon, ArrowDownIcon as ScrollDownIcon, ArrowUpIcon as ScrollUpIcon, CursorArrowRippleIcon as HoverIcon, ClockIcon , ArrowUpOnSquareIcon as FileUploadIcon} from '@heroicons/react/24/outline';
import { PlusCircleIcon, PuzzlePieceIcon, StopCircleIcon } from '@heroicons/react/24/solid';
import { Alert, AlertTitle, AlertDescription, AlertBody, AlertActions} from '../alert';
import { useTestsByOwner, getTestByUid, fetchTestsByOwner} from '../../util/db';
import { v4 as uuidv4 } from 'uuid';
import TaskControlPanel from './TaskControlPanel';
import ModuleSelectionAlert from './ModuleSelectionAlert';

const TaskList = ({
    tasks,
    setTasks,
    handleCheckedChange,
    showOptions,
    toggleOptions,
    hoverIndex,
    setHoverIndex,
    hoverTimeoutId,
    setHoverTimeoutId,
    isMultiModal,
    setIsMultiModal,
    isFastExecution,
    setIsFastExecution,
    executeTask,
    auth,
    platform,
    flowdescription,
    userUid,
    isLocalMode,
    authorizedFetch,
    appPackage,
    testId,
    taskProgress,
    breakpointIndex,
    setBreakpoint,
    schedule,
    setTestUid,
    isRecording,
    setIsRecording,
    toggleRecording,
    webSocket,
    setWebSocket,
    BASE_STEP_URL,
    apiKey,
    tracing,
    setTracing,
    tracingConfig,
    setTracingConfig
    }) => {
        const scrollContainerRef = useRef(null);
        const [scrollPercentage, setScrollPercentage] = useState(0);
        const [scrollContainerHeight, setScrollContainerHeight] = useState(0);
        const [scrollThumbHeight, setScrollThumbHeight] = useState(20);
        const [scrollThumbTop, setScrollThumbTop] = useState(0);

        useEffect(() => {
            if (scrollContainerRef.current) {
                setScrollContainerHeight(scrollContainerRef.current.clientHeight);
            }
        }, [tasks]); // Update when tasks change, as this might affect the container's height

        const handleScroll = () => {
            if (scrollContainerRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current;
                const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
                const newThumbHeight = Math.max(20, (clientHeight / scrollHeight) * clientHeight);
                const newThumbTop = ((clientHeight - newThumbHeight) * scrollPercentage) / 100;
                
                setScrollThumbHeight(newThumbHeight);
                setScrollThumbTop(newThumbTop);
            }
        };
         
        const handleInputChange = useCallback((e, index) => {
            const { name, value, metadata } = e.target;
            setTasks(prevTasks => {
              const updatedTask = { ...prevTasks[index], [name]: value };
              
              if (metadata) {
                updatedTask.metadata = { ...updatedTask.metadata, ...metadata };
              }
              
              if (JSON.stringify(prevTasks[index]) !== JSON.stringify(updatedTask)) {
                const newTasks = [...prevTasks];
                newTasks[index] = updatedTask;
                return newTasks;
              }
              
              return prevTasks;
            });
          }, []);
        const stepTypes = [
            { id: 'instruction', name: 'Instruction', icon: <InstructionIcon className="h-5 w-5 flex-shrink-0" />, group: 'Instructions' },
            { id: 'validation', name: 'Validation', icon: <ValidationIcon className="h-5 w-5 flex-shrink-0" />, group: 'Instructions' },
            { id: 'extract', name: 'Extract', icon: <ExtractIcon className="h-5 w-5 flex-shrink-0" />, group: 'Instructions' },
            { id: 'navigate', name: 'Navigate', icon: <NavigateIcon className="h-5 w-5 flex-shrink-0" />, group: 'Manual' },
            { id: 'back', name: 'Back', icon: <BackIcon className="h-5 w-5 flex-shrink-0" />, group: 'Manual' },
            { id: 'forward', name: 'Forward', icon: <ForwardIcon className="h-5 w-5 flex-shrink-0" />, group: 'Manual' },
            { id: 'refresh', name: 'Refresh', icon: <RefreshIcon className="h-5 w-5 flex-shrink-0" />, group: 'Manual' },
            { id: "wait", name: "Wait", icon: <ClockIcon className="h-5 w-5 flex-shrink-0" />, group: "Manual"},
            { id: 'scrape', name: 'Scrape', icon: <ScrapeIcon className="h-5 w-5 flex-shrink-0" />, group: 'Explicit' },
            { id: 'tap', name: 'Click', icon: <TapIcon className="h-5 w-5 flex-shrink-0" />, group: 'Explicit' },
            { id: 'swipe', name: 'Swipe', icon: <SwipeIcon className="h-5 w-5 flex-shrink-0" />, group: 'Explicit' },
            { id: 'input', name: 'Input Text', icon: <InputIcon className="h-5 w-5 flex-shrink-0" />, group: 'Explicit' },
            { id: 'select', name: 'Select', icon: <SelectIcon className="h-5 w-5 flex-shrink-0" />, group: 'Explicit' },
            { id: 'scroll_down', name: 'Scroll Down', icon: <ScrollDownIcon className="h-5 w-5 flex-shrink-0" />, group: 'Explicit' },
            { id: 'scroll_up', name: 'Scroll Up', icon: <ScrollUpIcon className="h-5 w-5 flex-shrink-0" />, group: 'Explicit' },
            { id: "hover", name: "Hover", icon: <HoverIcon className="h-5 w-5 flex-shrink-0" />, group: "Explicit"},
            {id: "file_upload", name: "File Upload", icon: <FileUploadIcon className="h-5 w-5 flex-shrink-0" />, group: "Explicit"},
          ];

        const [stepOutputs, setStepOutputs] = useState({});
        const [stepOutput, setStepOutput] = useState({});
        //Add code for following an execution
        const [currentStep, setCurrentStep] = useState(null);
        const [isCancelled, setIsCancelled] = useState(false);
        const isCancelledRef = useRef(false);

        const executeKsteps = async (start, end) => {
            // Clear step output for steps start to end before execution
            setStepOutputs(prevOutputs => {
                const newOutputs = { ...prevOutputs };
                for (let i = start; i <= end; i++) {
                    delete newOutputs[i];
                }
                return newOutputs;
            });
            
            // Set cancelled to false at beginning of execution
            isCancelledRef.current = false;
            // Execute steps start to end
            for (let stepIndex = start; stepIndex <= end; stepIndex++) {
                console.log(isCancelled);
                if (isCancelledRef.current) break;
                console.log("Executing step", stepIndex);
                setCurrentStep(stepIndex);
                await executeStepSingle(stepIndex);
            }
            setCurrentStep(null);
        }
        //Function to ahndle 'run steps till here' button click
        const runStepsTillHere = async (k) => {
            await executeKsteps(0, k);
        }
        
        const cancelExecution = () => {
            isCancelledRef.current = true;
            console.log("Cancelling execution");
            setCurrentStep(null);
        }

        useEffect(() => {
            if (currentStep !== null && scrollContainerRef.current) {
                const taskElement = document.getElementById(`task-${currentStep}`);
                if (taskElement) {
                    taskElement.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }, [currentStep]);

        const handleLocate = async (index) => {
            //Locate corresponding node and highlight it on browser
            const task = {
                step_type: tasks[index].step_type,
                step: tasks[index].step,
                metadata: tasks[index].metadata
            }
            const multimodal = tasks[index].multimodal;
            const download = tasks[index].download;



            const taskData = {
                platform: platform,
                flowdescription: flowdescription,
                task: task,
                app_package: appPackage,
                multimodal: multimodal,
                user_id: userUid,
                test_id: testId,
                local: isLocalMode,
                download: download
            };
            const response = await authorizedFetch("/locate_element", "POST", taskData);
            const result = await response;
            console.log(result);

    }
        const executeStepSingle = async (index) => {
        
            // Validate that there is a URL set
            if (!appPackage) {
                alert('Please define an app package before running the step');
                return;
            }
            //Get number of test executions that the user has
            const testExecutions = await getTestExecutions(auth.user.uid);
            if (testExecutions < 1) {
                alert('You have no test executions left. Please upgrade your plan to continue.');
                return;
            }
            const task = {
                step_type: tasks[index].step_type,
                step: tasks[index].step,
                metadata: tasks[index].metadata
            };

        
            const multimodal = tasks[index].multimodal;
            const download = tasks[index].download;
            const taskData = {
                platform: platform,
                flowdescription: flowdescription,
                task: task,
                app_package: appPackage,
                multimodal: multimodal,
                user_id: userUid,
                test_id: testId,
                local: isLocalMode,
                download: download
            };
            const response = await authorizedFetch("/execute_step", "POST", taskData);
            const result = await response;
            if (result && result.result !== 'error') {
                console.log(result);
                const outputInfo = JSON.parse(result.result);
                let newStepOutput = {};

                if (task.step_type.toLowerCase() === "validation") {
                    newStepOutput = {
                        result: JSON.stringify(outputInfo.validation_result),
                        reasoning: outputInfo.reasoning
                    };
                } else if (task.step_type.toLowerCase() === "scrape") {
                    newStepOutput = {
                        extracted_data: JSON.stringify(outputInfo.extracted_data)
                    };
                } else if ((stepTypes.find(type => type.id === task.step_type.toLowerCase())?.group === "Explicit") || (stepTypes.find(type => type.id === task.step_type.toLowerCase())?.group === "Instructions")) {
                    newStepOutput = {
                        action_id: outputInfo.action_id,
                        action_type: outputInfo.action_type,
                        action_value: outputInfo.value,
                        action_download: outputInfo.download !== undefined ? outputInfo.download : null
                    };
                }

                setStepOutput(prevStepOutput => ({
                    ...prevStepOutput,
                    [index]: newStepOutput
                }));
            }
        }
       // Select Task prototype
       const [selectedTasks, setSelectedTasks] = useState([])

       const [selectionStart, setSelectionStart] = useState(null);
       // Shift key logic
       const handleTaskSelection = (index, event) => {
        if (event.ctrlKey || event.metaKey) { // Check for Ctrl (Windows/Linux) or Cmd (Mac) key
            if (selectionStart === null) {
                // Start a new selection
                setSelectionStart(index);
                setSelectedTasks([tasks[index].id]);
            } else {
                // Complete the selection
                const start = Math.min(selectionStart, index);
                const end = Math.max(selectionStart, index);
                const newSelectedTasks = tasks.slice(start, end + 1).map(task => task.id);
                
                setSelectedTasks(newSelectedTasks);
                setSelectionStart(null); // Reset for the next selection
            }

            console.log("Selection start:", selectionStart);
            console.log("Selected tasks:", selectedTasks);
        }
       }
    const [showDialog, setShowDialog] = useState(false);
    const handleAddClick = (index) => {
        // Create a new task object with default values include a metadata object for steps that have been recorded
        /*
        * Create a new task object with default values
        * Include a metadata object for recording information
        * Metadata object should include the following:
        * - State - HTML state at time of recording the stap
        * - Selector - The selector used by playwright to identify the element
        * - The corresponding matching element for the state
        * - Image, the image at the time of the step recording
        *   - The id corresponding to the heuristic DOM that was generated
        *  -a11y node data
        */
       if (!flowdescription || !appPackage) {
        setShowDialog(true);
        return;
       }
        const newTask = { step_type: 'instruction', step: '', multimodal: false, metadata: {}, id: uuidv4()} ; // Default task object
        // Split the tasks array at the specified index and insert the new task
        const updatedTasks = [
            ...tasks.slice(0, index + 1),
            newTask,
            ...tasks.slice(index + 1)
        ];
        setTasks(updatedTasks);
        setSelectedTasks([newTask.id]);
        setSelectionStart(index + 1);
    };

       const isTaskSelected = (taskId) => selectedTasks.some(task => task === taskId);
       const executeSelectedTasks = async () => {
        if (selectedTasks.length === 0) return;
        const sortedTaskIndices = selectedTasks.map(taskId => tasks.findIndex(task => task.id === taskId)).sort((a, b) => a - b);
        const startIndex = sortedTaskIndices[0];
        const endIndex = sortedTaskIndices[sortedTaskIndices.length - 1];
        console.log("Executing selected tasks", startIndex, endIndex);
        setSelectedTasks([]);
        setSelectionStart(null);
        await executeKsteps(startIndex, endIndex);
       
       }
       // Delete selected tasks
       const deleteSelectedTasks = () => {
        const newTasks = tasks.filter(task => !selectedTasks.includes(task.id));
        setTasks(newTasks);
        setSelectedTasks([]);
        setSelectionStart(null);
       }
        
        const [isDownloads, setIsDownloads] = useState(false);
        const onDragEnd = (result) => {
            const { source, destination } = result;
            console.log("Dragended", source, destination);
            // If there's no destination (the item was dropped outside of the list), do nothing
            if (!destination) {
                console.log("No destination");
                return;
            }
        
            // If the item is dropped in the same place, do nothing
            if (
                source.droppableId === destination.droppableId &&
                source.index === destination.index
            ) {
                console.log("same place");
                return;
            }
            // Assuming tasks is an array of your items
            const newTasks = Array.from(tasks);
            const [removed] = newTasks.splice(source.index, 1);
            console.log(removed);
            newTasks.splice(destination.index, 0, removed);
            console.log("newTasks");
            console.log(newTasks);
            // Update the state with the new tasks array
            setTasks(newTasks);
            console.log("Tasks updated:", tasks);
        }
        const toggleBreakpoint = (index) => {
            console.log("Toggling breakpoint");
            if (breakpointIndex === index) {
                setBreakpoint(null);
                console.log("Breakpoint removed");
            } else {
                setBreakpoint(index);
                console.log("Breakpoint set to", index);
            }
        }
        //Add module logic plus vars
        const [isModuleAlertOpen, setIsModuleAlertOpen] = useState(false);
        const [userTests, setUserTests] = useState([]);
        const [tests, setTests] = useState([]);
        const [selectedTest, setSelectedTest] = useState(null)
        const [moduleInsertIndex, setModuleInsertIndex] = useState(null);
        const [expandedTestID, setExpandedTestId] = useState(null);
        const [loadingTestId, setLoadingTestId] = useState(null);
        const handleAddModule = async (index) => {
            console.log("Adding module");
            // Get the tests that the user owns # TODO filter by current url
            setModuleInsertIndex(index);
            setIsModuleAlertOpen(true);
            setExpandedTestId(null);
            setSelectedTest(null);
            try {
            const userTests = await fetchTestsByOwner(auth.user.uid);
            console.log("User tests", userTests);
            setUserTests(userTests);
            } catch (error) {
                console.error("Error fetching tests:", error);
            } 
        }
       
        const handleTestSelection = async (testId) => {
            if (expandedTestID === testId) {
                setExpandedTestId(null);
                setSelectedTest(null)
            }
            else {
                setLoadingTestId(testId);
                try {
                const test = await getTestByUid(testId);
                setSelectedTest(test);
                setExpandedTestId(testId);
                } catch (error) {
                    console.error("Error fetching test:", error);
                } finally {
                    setLoadingTestId(null);
                }
            }
            
        }
        //dedup logic for adding module
        const handleAddModuleFromTest = (selectedTest) => {
            if (selectedTest && moduleInsertIndex != null) {
                const newTasks = [...tasks];
                const existingIds = new Set(tasks.map(task => task.id));
                
                const tasksToAdd = selectedTest.tasks.map(task => {
                    if (existingIds.has(task.id)) {
                        // IF the id already exists create a new task with a unique id
                        return {
                            ...task,
                            id: uuidv4()
                        }
                    }
                    return task;
                })
                newTasks.splice(moduleInsertIndex+1, 0, ...tasksToAdd);
                setTasks(newTasks);
                // Select the module

        // Select the newly added tasks
                const newSelectedTasks = tasksToAdd.map(task => task.id);
                setSelectedTasks(newSelectedTasks);
                setIsModuleAlertOpen(false);
                setModuleInsertIndex(null);
            }
        }
  

        return (
            <>
            <div className="task-list flex flex-col h-full relative z-50">
            <div 
    ref={scrollContainerRef}
    className="flex-grow overflow-y-auto pr-2" 
    onScroll={handleScroll}
    style={{ 
        height: `calc(${Math.min(tasks.length, 8) * 80}px + ${Math.min(tasks.length, 8) * 16}px)`,
        minHeight: 'calc(10 * 80px + 8 * 16px)',
        maxHeight: 'calc(15 * 80px + 10 * 16px)'
    }}
>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppableTasks">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef} className="w-full space-y-1">
                                    {tasks.length > 0 && (
                                        <div 
                                            className="relative h-4 group mt-2"
                                            style={{
                                                position: 'relative',
                                                top: '-10px',
                                                zIndex: 10
                                            }}
                                            onMouseEnter={() => setHoverIndex(-1)}
                                            onMouseLeave={() => setHoverIndex(null)}
                                        >
                                            {hoverIndex === -1 && breakpointIndex !== 0 && (
                                                <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 flex space-x-[30px] z-[100]">
                                                    <div className="relative group">
                                                        <button
                                                            className="bg-green-500 hover:bg-green-700 text-white font-bold p-1 rounded transition-opacity"
                                                            onClick={() => handleAddClick(-1)}
                                                        >
                                                            <PlusCircleIcon className="h-5 w-5 text-white" />
                                                        </button>
                                                        <span className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap z-[1000] pointer-events-none">
                                                            Add Step
                                                        </span>
                                                    </div>
                                                    <div className="relative group">
                                                        <button
                                                            className="bg-purple-500 hover:bg-purple-700 text-white font-bold p-1 rounded transition-opacity"
                                                            onClick={() => handleAddModule(0)}
                                                        >
                                                            <PuzzlePieceIcon className="h-5 w-5 text-white" />
                                                        </button>
                                                        <span className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap z-[1000] pointer-events-none">
                                                            Add Module
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {tasks.map((task, index) => (
                                        <React.Fragment key={task.id}>
                                            <Draggable key={task.id} draggableId={`task-${task.id}`} index={index}>
                                                {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    id={`task-${index}`}
                                                    {...provided.dragHandleProps}
                                                    className={`${isTaskSelected(task.id) ? 'bg-red-100' : ''} border border-grey-200 rounded-lg mb-1 ml-2 overflow-hidden`}
                                                    onClick={(e) => handleTaskSelection(index, e)}
                                                >
                                                    <TaskItem
                                                        task={task}
                                                        tasks={tasks}
                                                        index={index}
                                                        currentStep={currentStep}
                                                        handleInputChange={handleInputChange}
                                                        toggleOptions={toggleOptions}
                                                        showOptions={showOptions}
                                                        handleCheckedChange={handleCheckedChange}
                                                        handleLocate={handleLocate}
                                                        hoverTimeoutId={hoverTimeoutId}
                                                        setHoverTimeoutId={setHoverTimeoutId}
                                                        setHoverIndex={setHoverIndex}
                                                        hoverIndex={hoverIndex}
                                                        runStepsTillHere={runStepsTillHere}
                                                        setTasks={setTasks}
                                                        auth={auth}
                                                        platform={platform}
                                                        flowdescription={flowdescription}
                                                        userUid={userUid}
                                                        isLocalMode={isLocalMode}
                                                        authorizedFetch={authorizedFetch}
                                                        appPackage={appPackage}
                                                        testId={testId}
                                                        taskProgress={taskProgress}
                                                        stepTypes={stepTypes}
                                                        executeStepSingle={executeStepSingle}
                                                        stepOutput={stepOutput[index] || {}}
                                                        setStepOutput={setStepOutput}
                                                        isSelected={isTaskSelected(task.id)}
                                                        apiKey={apiKey}
                                                        className="py-1 px-2"
                                                    />
                                                </div>
                                                
                                            )}
                                        </Draggable>
                                        <div 
                                                className="relative h-6 group"
                                                onMouseEnter={() => setHoverIndex(index)}
                                                onMouseLeave={() => setHoverIndex(null)}
                                            >
                                                {hoverIndex === index && breakpointIndex !== index && (
                                                    <div className="absolute z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex space-x-16">
                                                        <div className="relative group">
                                                            <button
                                                                className="bg-red-500 hover:bg-red-700 text-white font-bold p-1 rounded transition-opacity"
                                                                onClick={() => toggleBreakpoint(index)}
                                                            >
                                                                <StopCircleIcon className="h-5 w-5 text-white" />
                                                            </button>
                                                            <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap z-[1000] pointer-events-none">
                                                                Start Recording
                                                            </span>
                                                        </div>
                                                        <div className="relative group">
                                                            <button
                                                                className="bg-green-500 hover:bg-green-700 text-white font-bold p-1 rounded transition-opacity"
                                                                onClick={() => handleAddClick(index)}
                                                            >
                                                                <PlusCircleIcon className="h-5 w-5 text-white" />
                                                            </button>
                                                            <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap z-[1000] pointer-events-none">
                                                                Add Step
                                                            </span>
                                                        </div>
                                                        <div className="relative group">
                                                            <button
                                                                className="bg-purple-500 hover:bg-purple-700 text-white font-bold p-1 rounded transition-opacity"
                                                                onClick={() => handleAddModule(index)}
                                                            >
                                                                <PuzzlePieceIcon className="h-5 w-5 text-white" />
                                                            </button>
                                                            <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap z-[1000] pointer-events-none">
                                                                Add Module
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                                {breakpointIndex === index && (
                                                    <>
                                                        <div className="absolute w-full h-0.5 mt-2 bg-red-500"></div>
                                                        <div className="relative group">
                                                            <button
                                                                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-red-500 hover:bg-red-700 text-white font-bold p-1 mt-2 rounded transition-opacity"
                                                                onClick={() => setBreakpoint(null)}
                                                            >
                                                                <StopCircleIcon className="h-5 w-5 text-white" />
                                                            </button>
                                                            <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 bg-gray-800 text-white text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap z-[1000] pointer-events-none">
                                                                Remove Breakpoint
                                                            </span>
                                                        </div>
                                                    </>
                                                )}
                                            </div>


                                        </React.Fragment>

                                       
                                    ))
                                    
                                    }
                                    {provided.placeholder}
                                    
                                    {/* Fixed position "Add Step" button */}
                                    <div className="bottom-0 w-full bg-white py-2 mb-2mt-3">
                                        <button
                                            className="btn btn-sm btn-success text-white text-lg w-full max-w-xs mx-auto block"
                                            onClick={() => handleAddClick(tasks.length - 1)}
                                        >
                                            Add Step
                                        </button>
                                    </div>
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>

                {/* Custom Scroll Bar */}
                <div 
                    className="absolute right-0 top-0 w-1 "
                    style={{ 
                        height: `${scrollContainerHeight}px`,
                    }}
                >
                    <div 
                        className="absolute w-full bg-grey-400"
                        style={{
                            height: `${scrollThumbHeight}px`,
                            top: `${scrollThumbTop}px`,
                            transition: 'top 0.1s ease-out'
                        }}
                    />
                </div>

                <TaskControlPanel
                isMultiModal={isMultiModal}
                setIsMultiModal={setIsMultiModal}
                isDownloads={isDownloads}
                setIsDownloads={setIsDownloads}
                isFastExecution={isFastExecution}
                setIsFastExecution={setIsFastExecution}
                handleAddClick={handleAddClick}
                tasks={tasks}
                currentStep={currentStep}
                cancelExecution={cancelExecution}
                selectedTasks={selectedTasks}
                executeSelectedTasks={executeSelectedTasks}
                setSelectedTasks={setSelectedTasks}
                setSelectionStart={setSelectionStart}
                deleteSelectedTasks={deleteSelectedTasks}
                executeTask={executeTask}
                showDialog={showDialog}
                setShowDialog={setShowDialog}
                className="flex-shrink-0"
                flowdescription={flowdescription}
                platform={platform}
                appPackage={appPackage}
                userUid={userUid}
                testUid={testId}
                setTestUid={setTestUid}
                schedule={schedule}
                isRecording={isRecording}
                setIsRecording={setIsRecording}
                toggleRecording={toggleRecording}
                webSocket={webSocket}
                setWebSocket={setWebSocket}
                BASE_STEP_URL={BASE_STEP_URL}
                setTasks={setTasks}
                breakpointIndex={breakpointIndex}
                setBreakpoint={setBreakpoint}
                tracing={tracing}
                setTracing={setTracing}
                tracingConfig={tracingConfig}
                setTracingConfig={setTracingConfig}
                />
            </div>
            <ModuleSelectionAlert
                isOpen={isModuleAlertOpen}
                onClose={() => setIsModuleAlertOpen(false)}
                userTests={userTests}
                selectedTest={selectedTest}
                expandedTestID={expandedTestID}
                loadingTestId={loadingTestId}
                handleTestSelection={handleTestSelection}
                handleAddModuleFromTest={handleAddModuleFromTest}
                setModuleInsertIndex={setModuleInsertIndex}
            />

            </>
        );
    }

export default TaskList;