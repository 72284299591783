import React, { useState, useRef, useEffect } from 'react';
import { Switch } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faStop, faDesktop, faRedo } from '@fortawesome/free-solid-svg-icons';
import { createTest, updateTest } from "./../../util/db";
import { Button } from '../../components/buttonnew.js';

function ControlPanel({
  isRecording,
  setIsRecording,
  isLocalMode,
  toggleLocalMode,
  deviceStatus,
  startDevice,
  killDevice,
  flowdescription,
  platform,
  appPackage,
  userUid,
  auth,
  tasks,
  testUid,
  setTasks,
  setViewportSize,
  setTestUid,
  setBreakpoint,
  breakpointIndex,
  schedule,
  setSchedule
}) {
  const BASE_STEP_URL = process.env.REACT_APP_STEP_API;
  const [saveConfirmation, setSaveConfirmation] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [savePrompt, setSavePrompt] = useState("");
  const restartDevice = async () => {
    await killDevice();
    await startDevice();
  };

  // Custom Viewport sizes
  const [customWidth, setCustomWidth] = useState('');
  const [customHeight, setCustomHeight] = useState('');
  const [selectedViewport, setSelectedViewport] = useState('0');
  const viewportSizes = [
    { name: 'Desktop', width: 1920, height: 1080 },
    { name: 'Laptop', width: 1366, height: 768 },
    { name: 'Tablet', width: 375, height: 667 },
    { name: 'Custom', width: null, height: null }
  ];

  const handleViewportChange = (e) => {
    const selectedIndex = e.target.value;
    setSelectedViewport(selectedIndex);
    const selectedSize = viewportSizes[selectedIndex];
    if (selectedSize.width && selectedSize.height) {
      setViewportSize({ width: selectedSize.width, height: selectedSize.height });
      setCustomWidth('');
      setCustomHeight('');
    }
  };

  const handleCustomViewportChange = () => {
    if (customWidth && customHeight) {
      setViewportSize({ width: parseInt(customWidth), height: parseInt(customHeight) });
    }
  };

  const [webSocket, setWebSocket] = useState(null);

  const breakpointIndexRef = useRef(breakpointIndex);
  useEffect(() => {
    breakpointIndexRef.current = breakpointIndex;
  }, [breakpointIndex]);

  const toggleRecording = () => {
    if (webSocket && webSocket.readyState === WebSocket.OPEN) {
      console.log("Stopping recording...");
      webSocket.send("stop"); // Optionally tell the server to stop
      webSocket.close();
    } else {
      console.log("Starting recording...");
      console.log(testUid);
      console.log(userUid);
      if (!testUid) {
        console.error("No Test ID found creating new test");
        setSavePrompt("Please save the test before starting recording.");
        setTimeout(() => setSavePrompt(""), 3000);
        return;
      }
      console.log(testUid);
      if (!userUid) {
        console.error("No User ID found");
        return;
      }

      const protocolStrippedURL = BASE_STEP_URL.replace(/^(http:\/\/|https:\/\/)/, '');
      const wsProtocol = BASE_STEP_URL.startsWith('https') ? 'wss://' : 'ws://';
      const wsURL = `${wsProtocol}${protocolStrippedURL}/ws/record/${userUid}/${testUid}`;
      console.log(wsURL);
      const webSocket = new WebSocket(wsURL);

      webSocket.onopen = () => {
        console.log("WebSocket connection established. Recording started.");
        webSocket.send("Hello server"); // Send a test message to the server
        setIsRecording(true);
      };

      webSocket.onmessage = (event) => {
        console.log("Message from server:", event.data);
        try {
          const stepData = JSON.parse(event.data);
          if (stepData && stepData.id && stepData.step_type && stepData.step) {
            setTasks(prevTasks => {
              let updatedTasks = [...prevTasks];
              // Find the task if it exists
              const taskIndex = prevTasks.findIndex(task => task.id === stepData.id);
              // Get the current breakpoint index
              const currentBreakpointIndex = breakpointIndexRef.current;
              console.log("Current breakpoint index: ", currentBreakpointIndex);

              if (currentBreakpointIndex != null && currentBreakpointIndex < updatedTasks.length) {
                //onst breakpointTaskId = updatedTasks[currentBreakpointIndex].id;
                if (taskIndex !== -1) {
                  // Update the task at the breakpoint
                  updatedTasks[taskIndex] = {
                    ...updatedTasks[taskIndex],
                    ...stepData,
                    metadata: stepData.metadata
                  };
                } else {
                  // Add new task after the breakpoint
                  updatedTasks.splice(currentBreakpointIndex + 1, 0, {
                    ...stepData,
                    metadata: stepData.metadata
                  });
                  // Move the breakpoint to the next index
                  setBreakpoint(currentBreakpointIndex + 1);
                }
                console.log("Updated tasks: ", updatedTasks);
              } else if (taskIndex !== -1) {
                // Update existing task
                console.log("Recorded: Updating existing task");
                console.log(stepData.metadata);
                updatedTasks[taskIndex] = {
                  ...updatedTasks[taskIndex],
                  ...stepData,
                  metadata: stepData.metadata
                };
              } else {
                // Add new task at the end
                updatedTasks.push({
                  ...stepData,
                  metadata: stepData.metadata
                });
              }
              return updatedTasks;
            });
          } else {
            console.warn("Received JSON message does not match expected task format:", event.data);
          }
        } catch (error) {
          console.warn("Received non-JSON message:", event.data);
        }
      };

      webSocket.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      webSocket.onclose = () => {
        console.log("WebSocket connection closed. Recording stopped.");
        setIsRecording(false);
      };
      setWebSocket(webSocket);
    }
  };

  const saveTest = async () => {
    //Reset validation errors
    setValidationErrors({});
    setSaveConfirmation("");
    let errors = {};
    if (!flowdescription) errors.flowdescription = "Test case description is required";
    if (!appPackage) errors.appPackage = "Link to the app is required";
    if (!platform) errors.platform = "Platform is required";
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    //Prepare the test data
    console.log("Schedule: ", schedule);
    const test = {
      owner: userUid,
      testname: flowdescription,
      platform: platform,
      link: appPackage,
      tasks: tasks,
      schedule: schedule
    }
    //Call create test function to save the test to the database
    try {
      let response
      // Check to sev if there is a testuid indicating we're updating an existing test
      if (testUid) {
        test.id = testUid;
        response = await updateTest(testUid, test);
      } else {
        response = await createTest(test);
        testUid = response.id;
        console.log("Test UID: ", testUid);
        setTestUid(testUid);
      }
      console.log(response);
      setSaveConfirmation("Test saved successfully");
      // Clear the confirmation message after 3 seconds
      setTimeout(() => setSaveConfirmation(""), 3000);
    }   catch (error) {
      console.error("An error occurred while saving the test:", error);
      setValidationErrors({saveError: "Failed to save the test. Please try again."});
    }
  }
  return (
    <div className="flex flex-col space-y-4">
      <div className="flex items-center space-x-4">
        <div className="flex items-center">
          <button className="btn border-gray-300 bg-white text-black hover:bg-white hover:text-black h-10 text-sm 
           flex items-center justify-center  transition duration-150 ease-in-out transform hover:-translate-y-0.5 hover:scale-105">
            <FontAwesomeIcon icon={faDesktop} className="text-gray-600 mr-2" />
            <select
              value={selectedViewport}
              onChange={handleViewportChange}
              className="border-none bg-transparent focus:outline-none"
            >
              {viewportSizes.map((size, index) => (
                <option key={index} value={index}>{size.name}</option>
              ))}
            </select>
          </button>
        </div>

        {selectedViewport === '3' && (
          <div className="flex items-center space-x-2">
            <input
              type="number"
              placeholder="W"
              value={customWidth}
              onChange={(e) => setCustomWidth(e.target.value)}
              className="border border-gray-300 rounded-md p-2 w-16 mr-2"
            />
            <span>x</span>
            <input
              type="number"
              placeholder="H"
              value={customHeight}
              onChange={(e) => setCustomHeight(e.target.value)}
              className="border border-gray-300 rounded-md p-2 w-16 mr-2"
            />
            <button
              onClick={handleCustomViewportChange}
              className="bg-blue-500 hover:bg-blue-700 text-white  py-2 px-3 rounded text-sm"
            >
              Set
            </button>
          </div>
        )}
        <div className="flex items-center space-x-2 w-4/5">
          <button
            onClick={deviceStatus === "Start Device" ? startDevice : killDevice}
            className="btn border-gray-300 bg-white text-black hover:bg-white hover:text-black h-12 text-xl  flex items-center justify-center transition duration-150 ease-in-out transform hover:-translate-y-0.5 hover:scale-105 flex-grow"
          >
            <FontAwesomeIcon 
              icon={deviceStatus === "Start Device" ? faPlay : faStop} 
              className={`mr-2 text-2xl ${deviceStatus === "Start Device" ? 'text-green-500' : 'text-red-500'}`} 
            />
            {deviceStatus}
          </button>
          
          {deviceStatus !== "Start Device" && (
            <button
              onClick={restartDevice}
              className="btn border-gray-300 bg-white text-black hover:bg-white hover:text-black h-12 text-xl font-bold flex items-center justify-center transition duration-150 ease-in-out transform hover:-translate-y-0.5 hover:scale-105 flex-grow"
              title="Restart Device"
            >
              <FontAwesomeIcon icon={faRedo} className="text-blue-500 mr-2 text-2xl" />
              Restart
            </button>
          )}
          
          <button
            onClick={toggleLocalMode}
            className="btn border-gray-300 bg-white text-black hover:bg-white hover:text-black h-12 text-xl  flex items-center justify-center transition duration-150 ease-in-out transform hover:-translate-y-0.5 hover:scale-105"
          >
            <FontAwesomeIcon icon={faDesktop} className="text-gray-600 mr-2 text-2xl" />
            Local Mode
            <Switch
              checked={isLocalMode}
              onChange={toggleLocalMode}
              className={`${isLocalMode ? 'bg-gray-400' : 'bg-gray-400'} relative inline-flex h-6 w-10 items-center rounded-full transition-colors duration-200 ease-in-out ml-2`}
            >
              <span className="sr-only">Enable Local Mode</span>
              <span
                className={`${isLocalMode ? 'translate-x-5' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition duration-200 ease-in-out`}
              />
            </Switch>
          </button>
        </div>
      </div>
      {/* Add this block to show save confirmation or validation errors */}
      {(saveConfirmation || validationErrors.saveError) && (
        <div className={`text-center p-2 rounded ${saveConfirmation ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
          {saveConfirmation || validationErrors.saveError}
        </div>
      )}
      {savePrompt && (
        <div className="text-center p-2 rounded bg-yellow-100 text-yellow-700">
          {savePrompt}
        </div>
      )}
    </div>
  );
}

export default ControlPanel;