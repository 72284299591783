import {
  useQuery,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import supabase from "./supabase";
import { lib } from 'crypto-js';

// React Query client
const client = new QueryClient();

/**** USERS ****/

// Fetch user data
// Note: This is called automatically in `auth.js` and data is merged into `auth.user`
export function useUser(uid) {
  // Manage data fetching with React Query: https://react-query.tanstack.com/overview
  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ["user", { uid }],
    // Query function that fetches data
    () =>
      supabase
        .from("users")
        .select(`*, customers ( * )`)
        .eq("id", uid)
        .single()
        .then(handle),
    // Only call query function if we have a `uid`
    { enabled: !!uid }
  );
}

// Fetch user data (non-hook)
// Useful if you need to fetch data from outside of a component
export function getUser(uid) {
  return supabase
    .from("users")
    .select(`*, customers ( * )`)
    .eq("id", uid)
    .single()
    .then(handle);
}

// Update an existing user
export async function updateUser(uid, data) {
  const response = await supabase
    .from("users")
    .update(data)
    .eq("id", uid)
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["user", { uid }]);
  return response;
}

/**** ITEMS ****/
/* Example query functions (modify to your needs) */

// Fetch item data
export function useItem(id) {
  return useQuery(
    ["item", { id }],
    () => supabase.from("items").select().eq("id", id).single().then(handle),
    { enabled: !!id }
  );
}

// Fetch all items by owner
export function useItemsByOwner(owner) {
  return useQuery(
    ["items", { owner }],
    () =>
      supabase
        .from("items")
        .select()
        .eq("owner", owner)
        .order("createdAt", { ascending: false })
        .then(handle),
    { enabled: !!owner }
  );
}

// Create a new item
export async function createItem(data) {
  const response = await supabase.from("items").insert([data]).then(handle);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["items"]);
  return response;
}

// Update an item
export async function updateItem(id, data) {
  const response = await supabase
    .from("items")
    .update(data)
    .eq("id", id)
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await Promise.all([
    client.invalidateQueries(["item", { id }]),
    client.invalidateQueries(["items"]),
  ]);
  return response;
}

// Delete an item
export async function deleteItem(id) {
  const response = await supabase
    .from("items")
    .delete()
    .eq("id", id)
    .then(handle);
  // Invalidate and refetch queries that could have old data
  await Promise.all([
    client.invalidateQueries(["item", { id }]),
    client.invalidateQueries(["items"]),
  ]);
  return response;
}

/**** Tests ****/
//Quer functions for tests
/* Example query functions (modify to your needs) */
export async function createTest(data) {
  const { data: response, error } = await supabase
    .from('tests')
    .insert(data)
    .select();

  if (error) {
    throw error;
  }

  await client.invalidateQueries(["tests"]);
  console.log("Create test response: ", response);
  return response[0];
}

export async function updateTest(id, data) {
  const response = await supabase.from("tests")
    .update(data)
    .eq("id", id)
    .then(handle);

  await Promise.all([
    client.invalidateQueries(["test", { id }]),
    client.invalidateQueries(["tests"]),
  ]);
  return response;
}

// Fetch test data
export function useTest(id) {
  return useQuery(
    ["test", { id }],
    () => supabase.from("tests").select().eq("id", id).single().then(handle),
    { enabled: !!id }
  );
}

//Delete a test
export async function deleteTest(id) {
  const response = await supabase
      .from("tests")
      .delete()
      .eq("id", id)
      .then(handle);

  await Promise.all([
    client.invalidateQueries(["test", { id }]),
    client.invalidateQueries(["tests"]),
  ]);
  return response;

}
//Fetch all tests by owner
export function useTestsByOwner(owner) {
  return useQuery(
    ["tests", { owner }],
    () =>
      supabase
        .from("tests")
        .select()
        .eq("owner", owner)
        .order("createdAt", { ascending: false })
        .then(handle),
    { enabled: !!owner }
  );
}
//Fetch tests by ownder
export async function fetchTestsByOwner(owner) {
  try {
    const {data, error} = await supabase
    .from("tests")
    .select()
    .eq("owner", owner)
    .order("createdAt", {ascending: false});
    console.log("Data", data);
    return data;
  } catch (error) {
    console.error("Error fetching tests by owner:", error);
    throw error;
  }
}
// Fetch a test by its UID
export function getTestByUid(id) {
  return supabase
    .from("tests")
    .select()
    .eq("id", id)
    .single()
    .then(handle);
}

//Get Number of test executions user has
export async function getTestExecutions(uid) {
  const { data: user, error } = await supabase
    .from("users")
    .select("test_executions")
    .eq("id", uid)
    .single();

  if (error) {
    throw error;
  }

  return user.test_executions;
}

export async function decrementTestExecutions(uid, decrementBy) {
    // Fetch the current number of test_executions for the user
    const { data: user, error } = await supabase
      .from("users")
      .select("test_executions")
      .eq("id", uid)
      .single();

    if (error) {
      throw error;
    }

    // Make sure decrementBy is a number, not a Promise
    decrementBy = await Promise.resolve(decrementBy);

    // Calculate the new total
    let newTotal = (user.test_executions || 0) - decrementBy;

    // If newTotal is less than 0, set it to 0
    if (newTotal < 0) {
      newTotal = 0;
    }

    // Update the user's test_executions with the new total
    const response = await supabase
      .from("users")
      .update({ test_executions: newTotal })
      .eq("id", uid)
      .then(handle);

    // Invalidate and refetch queries that could have old data
    // Note: This line requires a 'client' object with 'invalidateQueries' method. 
    // If you don't have such an object, you might need to remove this line.
    //await client.invalidateQueries(["user", { uid }]);

    return response;
  }
/**** HELPERS ****/
// Add a new test history
export async function addTestHistory(testHistoryData) {
  /*
  * Test history schema
  * {
  *  id: UUID,
  *  test_uid: UUID,
  *  success: BOOLEAN,
  *  execution_time: TIMESTAMP WITH TIME ZONE,
  *  tasks_executed: INTEGER,
  *  metadata: JSONB
  * }
  */
  const response = await supabase
    .from("test_history")
    .insert([testHistoryData])
    .then(handle);

  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["test_history"]);

  return response;
}

export async function getTestHistory(testUid) {
  return supabase
    .from("test_history")
    .select()
    .eq("test_uid", testUid)
    .then(handle);
}
export async function getTestHistoriesBatch(testUids) {
  // Assuming 'supabase' is your API wrapper and it can handle an array of IDs
  return supabase
    .from("test_history")
    .select()
    .in("test_uid", testUids) // This is an example, adjust based on your API capabilities
    .then(handle);
}

// Get response data or throw error if there is one
function handle(response) {
  console.log("Response: ", response);
  if (response.error) throw response.error;
  return response.data;
}

//API

function generateApiKey() {
  return lib.WordArray.random(64).toString();
}
//Function to get Existing API key for a user
export async function getApiKey(uid) {
  const { data: user, error } = await supabase
    .from("api_keys")
    .select("api_key")
    .eq("user_id", uid)
    .maybeSingle();

  return user ? user.api_key : false;
}
//Get existing active API key for a user first one
// export async function getActiveApiKey(uid) {
//   // Return single active api key
//   const { data: user, error } = await supabase
//     .from("api_keys")
//     .select("api_key")
//     .eq("user_id", uid)
//     .eq("is_active", true)
//     .maybeSingle();
//   return user ? user.api_key : false;
// }
//Function to delete an API key
// Function to delete an API key based on the api_key attribute
export async function deleteApiKey(apiKeyValue) {
  const { error } = await supabase
    .from("api_keys")
    .delete()
    .match({ api_key: apiKeyValue }); // Use the api_key attribute for matching

  if (error) {
    console.error("Error deleting API key:", error);
    return false;
  }

  return true;
}

export async function getActiveApiKey(uid) {
  const { data: apiKeys, error } = await supabase
    .from("api_keys")
    .select("api_key")
    .eq("user_id", uid)
    .eq("is_active", true)
    .order('created_at', { ascending: true }) // Assuming 'created_at' is a timestamp of when the key was added
    .limit(1); // Limit to only one result

  if (error) {
    console.error("Error fetching active API key:", error);
    return false;
  }

  // Since we're limiting to 1, we can safely return the first item if it exists
  return apiKeys.length > 0 ? apiKeys[0].api_key : false;
}
//Get all active API keys for a user
export async function getAllActiveApiKeys(uid) {
  const {data: apiKeys, error} = await supabase.
    from("api_keys")
    .select("api_key")
    .eq("user_id", uid)
    .eq("is_active", true)

  if (error) {
    console.error("Error fetching active API keys:", error);

  }
  return apiKeys.map(apiKey => apiKey.api_key)
  
}

//Functino to create a new API key for a user
export async function createApiKey(uid) {
  // Check if the user already has an API key
  let existingApiKey = null;
  try {
    const {data: existingApiKey, error} = await getActiveApiKey(uid);
    if (error) {
      throw error;
    }
  } catch (error) {
    if (error.code === "PGRST116") {
      existingApiKey = false;
    } else {
      throw error;
    }
  }

  if (existingApiKey) {
    // If they do, return that one
    return existingApiKey.api_key;
  }
  const newapiKey = generateApiKey();
  //Store the api key in database
  await supabase
    .from('api_keys')
    .insert( 
      {
        user_id: uid,
        api_key: newapiKey,
        is_active: true
      }
    )
    .single();
  
  return

}


// React Query context provider that wraps our app
export function QueryClientProvider(props) {
  return (
    <QueryClientProviderBase client={client}>
      {props.children}
    </QueryClientProviderBase>
  );
}

/**** WORKFLOWS ****/

// Fetch all workflows by owner
export function useWorkflowsByOwner(owner) {
  return useQuery(
    ["workflows", { owner }],
    () =>
      supabase
        .from("workflows")
        .select()
        .eq("owner", owner)
        .order("created_at", { ascending: false })
        .then(handle),
    { enabled: !!owner }
  );
}
// Update an existing workflow
export async function updateWorkflow(id, data) {
  const response = await supabase
    .from("workflows")
    .update(data)
    .eq("id", id)
    .then(handle);

  // Invalidate and refetch queries that could have old data
  await Promise.all([
    client.invalidateQueries(["workflow", { id }]),
    client.invalidateQueries(["workflows"]),
  ]);

  return response;
}
//Fetch history metadata
export async function getHistoryIdInfo(historyId) {
  console.log(historyId);
  const response = await supabase.from("test_history").select("*").eq("id", historyId).single().then(handle);
  console.log(response);
  return response;
}


// Create a new workflow
export async function createWorkflow(data) {
  const response = await supabase.from("workflows").insert([data]).then(handle);
  // Invalidate and refetch queries that could have old data
  await client.invalidateQueries(["workflows"]);
  return response;
}