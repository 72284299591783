import React, { useRef, useEffect, useState } from 'react';

import {motion} from 'framer-motion'
import { Dialog, DialogTitle, DialogDescription, DialogBody, DialogActions } from '../dialog'
import { Button } from '../buttonnew'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faPlay, faStop, faRecordVinyl, faTrash } from '@fortawesome/free-solid-svg-icons';
import { createTest, updateTest } from "./../../util/db";
import { Cog6ToothIcon } from '@heroicons/react/24/outline';

const TaskControlPanel = ({
    isMultiModal,
    setIsMultiModal,
    isDownloads,
    setIsDownloads,
    isFastExecution,
    setIsFastExecution,
    handleAddClick,
    tasks,
    currentStep,
    cancelExecution,
    selectedTasks,
    executeSelectedTasks,
    setSelectedTasks,
    setSelectionStart,
    deleteSelectedTasks,
    executeTask,
    showDialog,
    setShowDialog,
    flowdescription,
    platform,
    appPackage,
    userUid,
    testUid,
    setTestUid,
    schedule,
    isRecording,
    setIsRecording,
    toggleRecording,
    webSocket,
    setWebSocket,
    BASE_STEP_URL,
    setTasks,
    breakpointIndex,
    setBreakpointIndex,
    tracing,
    setTracing,
    tracingConfig,
    setTracingConfig
}) => {
    const panelRef = useRef(null);

    const isEmptyTaskList = tasks.length === 0;

    const buttonVariants = {
        idle: { scale: 1 },
        animate: { scale: [1, 1.1, 1], transition: { repeat: Infinity, duration: 2 } }
    };

    const [isSticky, setIsSticky] = useState(false);

    const [saveDialogOpen, setSaveDialogOpen] = useState(false);
    const [saveMessage, setSaveMessage] = useState("");

    const [showSettings, setShowSettings] = useState(false);

    const [showSelectedTasksActions, setShowSelectedTasksActions] = useState(false);

    useEffect(() => {
        const hasSelectedTasks = selectedTasks.length > 0;
        setShowSelectedTasksActions(hasSelectedTasks);
        if (hasSelectedTasks) {
            setShowSettings(false);  // Close settings drawer when items are selected
        }
    }, [selectedTasks]);

    useEffect(() => {
        const handleScroll = () => {
            const panelTop = panelRef.current.offsetTop;
            const panelHeight = panelRef.current.offsetHeight;
            const windowHeight = window.innerHeight;
            const scrollPosition = window.scrollY;

            if (scrollPosition + windowHeight > panelTop + panelHeight) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [panelRef]);

    const saveTest = async () => {
        let errors = {};
        if (!flowdescription) errors.flowdescription = "Test case description is required";
        if (!appPackage) errors.appPackage = "Link to the app is required";
        if (!platform) errors.platform = "Platform is required";
        if (Object.keys(errors).length > 0) {
            return;
        }
        if (tracing && !tracingConfig) {
            errors.tracingConfig = "Tracing configuration is required";
        }

        console.log(tracingConfig)
        console.log(tracing)

        const test = {
            owner: userUid,
            testname: flowdescription,
            platform: platform,
            link: appPackage,
            tasks: tasks,
            schedule: schedule,
            tracing: tracing,
            tracingconfig: tracingConfig
        };

        try {
            let response;
            if (testUid) {
                test.id = testUid;
                response = await updateTest(testUid, test);
            } else {
                response = await createTest(test);
                setTestUid(response.id);
            }
            setSaveMessage("Test saved successfully");
        } catch (error) {
            console.error("An error occurred while saving the test:", error);
            setSaveMessage("Failed to save the test. Please try again.");
        }
        setSaveDialogOpen(true);
    };

    const breakpointIndexRef = useRef(breakpointIndex);

    useEffect(() => {
        breakpointIndexRef.current = breakpointIndex;
    }, [breakpointIndex]);

    const handleRecordingToggle = () => {
        if (isRecording) {
            // Stop recording
            if (webSocket && webSocket.readyState === WebSocket.OPEN) {
                console.log("Stopping recording...");
                webSocket.send("stop");
                webSocket.close();
            }
        } else {
            // Start recording
            console.log("Starting recording...");
            if (!testUid) {
                console.error("No Test ID found");
                // You might want to show an alert or set some state to display a message
                return;
            }
            if (!userUid) {
                console.error("No User ID found");
                return;
            }

            const protocolStrippedURL = BASE_STEP_URL.replace(/^(http:\/\/|https:\/\/)/, '');
            const wsProtocol = BASE_STEP_URL.startsWith('https') ? 'wss://' : 'ws://';
            const wsURL = `${wsProtocol}${protocolStrippedURL}/ws/record/${userUid}/${testUid}`;
            console.log(wsURL);
            const newWebSocket = new WebSocket(wsURL);

            newWebSocket.onopen = () => {
                console.log("WebSocket connection established. Recording started.");
                newWebSocket.send("Hello server");
                setIsRecording(true);
            };

            newWebSocket.onmessage = (event) => {
                console.log("Message from server:", event.data);
                try {
                    const stepData = JSON.parse(event.data);
                    if (stepData && stepData.id && stepData.step_type && stepData.step) {
                        setTasks(prevTasks => {
                            let updatedTasks = [...prevTasks];
                            const taskIndex = prevTasks.findIndex(task => task.id === stepData.id);
                            const currentBreakpointIndex = breakpointIndexRef.current;

                            if (currentBreakpointIndex != null && currentBreakpointIndex < updatedTasks.length) {
                                if (taskIndex !== -1) {
                                    updatedTasks[taskIndex] = {
                                        ...updatedTasks[taskIndex],
                                        ...stepData,
                                        metadata: stepData.metadata
                                    };
                                } else {
                                    updatedTasks.splice(currentBreakpointIndex + 1, 0, {
                                        ...stepData,
                                        metadata: stepData.metadata
                                    });
                                    setBreakpointIndex(currentBreakpointIndex + 1);
                                }
                            } else if (taskIndex !== -1) {
                                updatedTasks[taskIndex] = {
                                    ...updatedTasks[taskIndex],
                                    ...stepData,
                                    metadata: stepData.metadata
                                };
                            } else {
                                updatedTasks.push({
                                    ...stepData,
                                    metadata: stepData.metadata
                                });
                            }
                            return updatedTasks;
                        });
                    } else {
                        console.warn("Received JSON message does not match expected task format:", event.data);
                    }
                } catch (error) {
                    console.warn("Received non-JSON message:", event.data);
                }
            };

            newWebSocket.onerror = (error) => {
                console.error("WebSocket error:", error);
            };

            newWebSocket.onclose = () => {
                console.log("WebSocket connection closed. Recording stopped.");
                setIsRecording(false);
            };

            setWebSocket(newWebSocket);
        }
    };

    return (
        <>
            {showSelectedTasksActions && (
                <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    className="mt-4 p-4 bg-gray-100 transition-all duration-300 ease-in-out flex justify-center"
                >
                    <div className="flex space-x-4 justify-center">
                        <Button
                            onClick={executeSelectedTasks}
                            color="green"
                            className="text-white font-bold py-2 px-4 rounded flex items-center justify-center shadow-lg transition duration-150 ease-in-out transform hover:-translate-y-0.5 hover:scale-105"
                        >
                            <FontAwesomeIcon icon={faPlay} className="mr-2 text-xl" />
                            Run Selected ({selectedTasks.length})
                        </Button>
                        <Button
                            onClick={() => {
                                setSelectedTasks([]);
                                setSelectionStart(null);
                            }}
                            color="yellow"
                            className="text-white font-bold py-2 px-4 rounded flex items-center justify-center shadow-lg transition duration-150 ease-in-out transform hover:-translate-y-0.5 hover:scale-105"
                        >
                            Clear Selection
                        </Button>
                        <Button
                            onClick={deleteSelectedTasks}
                            color="red"
                            className="text-white font-bold py-2 px-4 rounded flex items-center justify-center shadow-lg transition duration-150 ease-in-out transform hover:-translate-y-0.5 hover:scale-105"
                        >
                            <FontAwesomeIcon icon={faTrash} className="mr-2 text-xl" />
                            Delete Selected
                        </Button>
                    </div>
                </motion.div>
            )}
            <div ref={panelRef} className={`task-control-panel bg-gray-100 ${isSticky ? 'sticky bottom-0' : ''} p-4`}>
                <div className="flex justify-between items-center">
                    <div className="flex items-center space-x-2">
                        <div className="flex flex-col space-y-2">
                            {/* Vision toggle */}
                            {/* Downloads toggle */}
                            {/* Fast Execution toggle */}
                        </div>
                    </div>
                    <motion.button
                        onClick={() => setShowSettings(!showSettings)}
                        className="p-2 rounded-full hover:bg-gray-200 transition-colors duration-200"
                        whileTap={{ rotate: 360 }}
                        transition={{ duration: 0.3 }}
                    >
                        <Cog6ToothIcon className="h-6 w-6 text-gray-600" />
                    </motion.button>
                    <Button
                        onClick={saveTest}
                        color="white"
                        className="text-white font-bold py-2 px-4 rounded flex items-center justify-center shadow-lg transition duration-150 ease-in-out transform hover:-translate-y-0.5 hover:scale-105"
                    >
                        <FontAwesomeIcon icon={faSave} className="mr-2 text-xl" />
                        Save Test
                    </Button>
                    <Button
                        onClick={toggleRecording}
                        color={isRecording ? "red" : "white"}
                        className="text-white font-bold py-2 px-4 rounded flex items-center justify-center shadow-lg transition duration-150 ease-in-out transform hover:-translate-y-0.5 hover:scale-105"
                    >
                        <FontAwesomeIcon icon={isRecording ? faStop : faRecordVinyl} className="mr-2 text-xl" />
                        {isRecording ? "Stop Recording" : "Start Recording"}
                    </Button>
                    {currentStep !== null && (
                        <button 
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:scale-110"
                            onClick={cancelExecution}
                        >
                            Cancel Execution
                        </button>
                    )}
                </div>
            </div>
            {showSettings && (
                <div className="mt-4 p-4  transition-all duration-300 ease-in-out flex justify-center">
                    <div className="flex space-x-4 justify-center">
                        <ToggleSwitch 
                            label="Vision" 
                            checked={isMultiModal} 
                            onChange={setIsMultiModal} 
                        />
                        <ToggleSwitch 
                            label="Downloads" 
                            checked={isDownloads} 
                            onChange={setIsDownloads} 
                        />
                        <ToggleSwitch 
                            label="Fast Execution" 
                            checked={isFastExecution} 
                            onChange={setIsFastExecution} 
                        />
                    </div>
                </div>
            )}
            <Dialog 
                open={showDialog} 
                onClose={() => setShowDialog(false)} 
                className="fixed -top-[9999] "
            >
                <DialogTitle>Add Step</DialogTitle>
                <DialogDescription>
                    Please fill in the required information before adding a step.
                </DialogDescription>
                <DialogBody>
                    <p>Both the <strong>Test Case Description</strong> and <strong>Test Website/App Package</strong> are required fields.</p>
                </DialogBody>
                <DialogActions>
                    <Button onClick={() => setShowDialog(false)}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog 
                open={saveDialogOpen} 
                onClose={() => setSaveDialogOpen(false)} 
                className="fixed -top-[9999] "
            >
                <DialogBody>
                    <p>{saveMessage}</p>
                </DialogBody>
                <DialogActions>
                    <Button onClick={() => setSaveDialogOpen(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

const ToggleSwitch = ({ label, checked, onChange }) => (
    <div className="flex items-center space-x-2 border rounded-lg p-2">
        <span className="text-sm text-gray-700">{label}</span>
        <label className="inline-flex items-center cursor-pointer">
            <input
                type="checkbox"
                className="sr-only peer"
                checked={checked}
                onChange={(e) => onChange(e.target.checked)}
            />
            <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
        </label>
    </div>
);

export default TaskControlPanel;