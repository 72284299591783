import React, { useEffect, useState } from "react";
import Meta from "../components/Meta";
import { requireAuth, useAuth } from "../util/auth";
import {useTestsByOwner, getTestHistory, getTestHistoriesBatch} from "../util/db";
import TestHistoryCard from "../components/TestHistoryCard";
import { formatRelative } from 'date-fns';
import { debounce } from 'lodash';
import testhistory from "../pages/testhistory";
function TestHistoryPage(props) {
  //Get auth
  const auth = useAuth();
  const user_uid = auth.user.uid;
  const [currentPage, setCurrentPage] = useState(1);
  const [testsPerPage, setTestsPerPage] = useState(100); 
  // State for tests and test history
  const [tests, setTests] = useState([]);
  const [testHistory, setTestHistory] = useState([]);
  const [testsData, setTestsData] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredTestsData, setFilteredTestsData] = useState([]);
  const [hasFetchedHistories, setHasFetchedHistories] = useState(false);
  // Get tests by owner
  const { data: testData } = useTestsByOwner(user_uid);
  // Get test history for each test
 
  // In your useEffect where you fetch test histories'
   // Helper function for grouping tests by their link attribute
   function groupTestsByLink(testsArray) {
    return testsArray.reduce((groups, test) => {
      const group = groups[test.link] || [];
      group.push(test);
      groups[test.link] = group;
      return groups;
    }, {});
  }
  //Change to fetch for currently available tests only
  useEffect(() => {
    if (testData?.length > 0 && !hasFetchedHistories) {
      const fetchTestHistories = async () => {
        let histories = []; 
        try {
          const batchSize = 100;
          for (let i = 0; i < testData.length; i += batchSize) {
            const batch = testData.slice(i, i + batchSize).map(test => test.id);
            const batchHistories = await getTestHistoriesBatch(batch);
            histories = histories.concat(batchHistories);
          }
          setHasFetchedHistories(true);
        }
        catch (error) {
          console.error("Failed to fetch test histories", error);

        }
        
        //const histories = await Promise.all(historyPromises);
        // Create a lookup object that maps test IDs to their histories
        const historyLookup = histories.reduce((lookup, history) => {
          if (!lookup[history.test_uid]) {
            lookup[history.test_uid] = [];
          }
          lookup[history.test_uid].push(history);
          return lookup;
        }, {});
        console.log(historyLookup)
        setTestHistory(historyLookup);
        //Sort tests based on number of executions
        const sortedTestsData = [...testData].sort((a, b) => {
          return (historyLookup[b.id]?.length || 0) - (historyLookup[a.id]?.length || 0);
        });
        setTestsData(sortedTestsData);
      };
      fetchTestHistories();
    }
}, [testData, hasFetchedHistories]);
 // Search filter logic
 useEffect(() => {
  if (testData && searchTerm) {
    const filteredData = testData.filter(test => 
      test.testname.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredTestsData(filteredData);
  } else {
    setFilteredTestsData(testData);
  }
}, [searchTerm, testData]);

const Pagination = ({ testsPerPage, totalTests, paginate, currentPage }) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalTests / testsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="join">
      {pageNumbers.map(number => (
        <button
          key={number}
          onClick={(e) => {
            e.preventDefault();
            paginate(number);
          }}
          className={`join-item btn ${currentPage === number ? 'btn-active' : ''}`}
        >
          {number}
        </button>
      ))}
    </div>
  );
};
  const indexOfLastTest = currentPage * testsPerPage;
  const indexOfFirstTest = indexOfLastTest - testsPerPage;
  const currentFilteredTests = filteredTestsData
    ? filteredTestsData.slice(indexOfFirstTest, indexOfLastTest)
    : [];

  const currentGroupedTests = groupTestsByLink(currentFilteredTests);

  //console.log(tests)
  return (
    <>
      <Meta title="Test History" />
      <div className="container mt-4">
        <input
          type="text"
          placeholder="Search by test name..."
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          className="search-input" // Ensure this class exists or add relevant styling
        />
        <Pagination testsPerPage={testsPerPage} totalTests={filteredTestsData?.length || 0} paginate={setCurrentPage} />
          {Object.entries(currentGroupedTests).map(([link, tests], index) => (
          <div key={index}>
            <h3 className='p-4 border-b border-gray-200 text-lg font-semibold'>{link}</h3>
            {tests.map((test, testIndex) => {
              const relatedHistories = testHistory[test.id] || [];
              const isLastCard = testIndex === tests.length - 1;
              return (
                <TestHistoryCard key={test.id} test={test} histories={relatedHistories} showStatusAndDate={isLastCard} />
              );
            })}
          </div>
        ))}
      </div>
    </>


  );
}

export default TestHistoryPage;