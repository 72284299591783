import React, { useEffect, useRef } from 'react';
import rrwebPlayer from 'rrweb-player';
import 'rrweb-player/dist/style.css';

const VideoPlayerModal = ({ isOpen, onClose, videoEvents }) => {
  const playerContainerRef = useRef(null);

  useEffect(() => {
    if (isOpen && videoEvents) {
      const player = new rrwebPlayer({
        target: playerContainerRef.current,
        props: {
          events: videoEvents,
          width: 800,
          height: 450,
          autoPlay: true,
        },
      });

      return () => {
        if (player) {
          player.pause();
        }
      };
    }
  }, [isOpen, videoEvents]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-4xl w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Video Playback</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>
        <div ref={playerContainerRef} className="w-full aspect-video"></div>
      </div>
    </div>
  );
};

export default VideoPlayerModal;