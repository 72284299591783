import { useState, useEffect, useRef } from 'react';
import {
  BeakerIcon,
  Cog6ToothIcon,
  ArchiveBoxIcon,
  PlayIcon,
  UserIcon,
  DocumentIcon,
  UsersIcon,
  XMarkIcon,
  ChartBarSquareIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ArrowPathIcon  // Add this line
} from '@heroicons/react/24/outline';
import { Link, useLocation, useHistory } from 'react-router-dom';

function useCurrentPage(path) {
  const location = useLocation();
  return location.pathname === path;
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Example() {
  const location = useLocation();
  const history = useHistory(); // Add this line
  const [isExpanded, setIsExpanded] = useState(true);
  const prevLocationRef = useRef(location);
  const sidebarRef = useRef(null);
  const [isHovering, setIsHovering] = useState(null);
  const toggleButtonRef = useRef(null);

  const navigation = [
    { name: 'Dashboard', href: "/dashboard", icon: ChartBarSquareIcon, current: false },
    { name: 'Execute', href: "/execute", icon: PlayIcon, current: false },
    { name: 'Tests', href: "/tests", icon: BeakerIcon, current: false },
    { name: 'Workflows', href: "/workflow", icon: ArrowPathIcon, current: false },  // Updated this line
  ];

  const docs = [
    { name: 'Docs', href: "https://docs.revyl.ai/introduction", icon: DocumentIcon, current: false },
  ];

  const settings = [
    { name: 'Account', href: '/settings/general', icon: UserIcon, current: false },
  ];

  const signout = [
    { name: 'Sign Out', href: '/signout', icon: XMarkIcon, current: false },
  ];

  const [navItems, setNavItems] = useState(navigation);
  const [settingItems, setSettingItems] = useState(settings);

  useEffect(() => {
    setNavItems(navItems.map(item => ({
      ...item,
      current: location.pathname === item.href
    })));
    setSettingItems(settingItems.map(item => ({
      ...item,
      current: location.pathname === item.href
    })));
  }, [location.pathname]);

  useEffect(() => {
    if (location !== prevLocationRef.current) {
      prevLocationRef.current = location;
    }
  }, [location]);

  const toggleSidebar = (e) => {
    e.stopPropagation();
    setIsExpanded((prev) => !prev);
  };

  const handleLinkClick = (e, href) => {
    e.preventDefault();
    e.stopPropagation();
    setTimeout(() => {
      setIsExpanded(false);
      history.push(href); // Use history.push instead of navigate
    }, 150);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        toggleButtonRef.current &&
        !toggleButtonRef.current.contains(event.target) &&
        !event.target.closest('.sidebar-container') // Add this line
      ) {
        setIsExpanded(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sidebarRef, toggleButtonRef]);

  return (
    <div className="relative">
      <div className="relative">
        {/* Static sidebar for desktop */}
        <div
          className={`fixed inset-y-0 left-0 z-50 flex flex-col h-screen justify-center transition-all duration-300 ease-in-out ${
            isExpanded ? 'w-44' : 'w-24'
          } bg-green-600 sidebar-container overflow-visible`}
        >
          {/* Sidebar component */}
          <div ref={sidebarRef} className="flex grow flex-col gap-y-5 overflow-visible px-4 items-center relative h-full">
            {/* Toggle button */}
            <div
              ref={toggleButtonRef}
              onClick={toggleSidebar}
              className="absolute top-1/2 -right-5 transform -translate-y-1/2 cursor-pointer z-[9999] bg-green-600 rounded-r-full px-0 py-6 transition-all duration-300 ease-in-out"
            >
              {isExpanded ? (
                <ChevronLeftIcon className="h-6 w-6 text-white" />
              ) : (
                <ChevronRightIcon className="h-6 w-6 text-white" />
              )}
            </div>

            <div className="flex h-32 shrink-0 items-center justify-center">
              <svg
                className="opacity-75 hi-outline hi-cube-transparent inline-block z-50 w-10 h-10"
                stroke="white"
                fill="none"
                viewBox="0 0 24 24"
                onClick={(e) => e.stopPropagation()} // Prevent sidebar toggle on icon click
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"
                />
              </svg>
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-9">
                <li>
                  <ul role="list" className="-mx-2 space-y-16 sm:pt-10 lg:pt-16 justify-center">
                    {navItems.map((item) => (
                      <li key={item.name} className="group relative">
                        <Link
                          to={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-green-300 text-slate-900'
                              : 'text-white hover:text-white hover:bg-gray-800',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                          onMouseEnter={() => setIsHovering(item.name)}
                          onMouseLeave={() => setIsHovering(null)}
                          onClick={(e) => handleLinkClick(e, item.href)}
                        >
                          <div className="flex items-center relative">
                            <item.icon
                              className="h-10 w-10 z-50 shrink-0"
                              aria-hidden="true"
                            />
                            {isExpanded && <span className="ml-4">{item.name}</span>}
                          </div>
                        </Link>
                        {!isExpanded && isHovering === item.name && (
                          <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2">
                            <span className="bg-gray-900 text-white text-xs rounded-md px-2 py-1 z-60">
                              {item.name}
                            </span>
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                  <ul role="list" className="-mx-2 space-y-16 sm:pt-10 lg:pt-16 justify-center">
                    {docs.map((item) => (
                      <li key={item.name} className="group relative">
                        <a
                          href={item.href}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classNames(
                            item.current
                              ? 'bg-green-300 text-slate-900'
                              : 'text-white hover:text-white hover:bg-gray-800',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                          onMouseEnter={() => setIsHovering(item.name)}
                          onMouseLeave={() => setIsHovering(null)}
                          onClick={(e) => e.stopPropagation()} // Add this line
                        >
                          <div className="flex items-center relative">
                            <item.icon
                              className="h-10 w-10 z-50 shrink-0"
                              aria-hidden="true"
                            />
                            {isExpanded && <span className="ml-4">{item.name}</span>}
                          </div>
                        </a>
                        {!isExpanded && isHovering === item.name && (
                          <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2">
                            <span className="bg-gray-900 text-white text-xs rounded-md px-2 py-1 z-60">
                              {item.name}
                            </span>
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                  <ul role="list" className="-mx-2 space-y-1 md:pt-20 lg:pt-28 justify-center">
                    {settingItems.map((item) => (
                      <li key={item.name} className="group relative">
                        <Link
                          to={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-green-300 text-slate-900'
                              : 'text-white hover:text-white hover:bg-gray-800',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                          onMouseEnter={() => setIsHovering(item.name)}
                          onMouseLeave={() => setIsHovering(null)}
                          onClick={(e) => handleLinkClick(e, item.href)}
                        >
                          <div className="flex items-center relative">
                            <item.icon
                              className="h-10 w-10 z-50 shrink-0"
                              aria-hidden="true"
                            />
                            {isExpanded && <span className="ml-4">{item.name}</span>}
                          </div>
                        </Link>
                        {!isExpanded && isHovering === item.name && (
                          <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2">
                            <span className="bg-gray-900 text-white text-xs rounded-md px-2 py-1 z-60">
                              {item.name}
                            </span>
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <main className={`transition-all duration-300 ease-in-out ${isExpanded ? 'ml-28' : 'ml-8'} py-50 relative z-0`}>
          <div className="px-4 sm:px-6 md:px-8">{/* Your content */}</div>
        </main>
      </div>
    </div>
  );
}