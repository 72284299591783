import React from "react";
import ReactDom from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./pages/_app";
import * as serviceWorker from "./serviceWorker";

// ReactDom.render(
//   <Router>
//     <App />
//   </Router>,
//   document.getElementById("root")
// );
const root = ReactDom.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <App />
  </Router>
  
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
