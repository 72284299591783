import React from "react";
import Meta from "./../components/Meta";
import PricingSection from "./../components/PricingSection";

function PricingPage(props) {
  return (
    <>
      <Meta title="Pricing" />
      <PricingSection
        title="Choose the best plan"
        subtitle="Explore testing plans that fit your need. Immediately save development time and start automating your tests on day one."
        strapline="Pricing"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
    </>
  );
}

export default PricingPage;
