import React from "react";
import Meta from "./../components/Meta";
import DashboardSection from "./../components/DashboardSection";
import TestSection from "./../components/TestSection";
import { requireAuth } from "./../util/auth";

function TestPage(props) {
  return (
    <>
      <Meta title="Tests" />
        <TestSection
            title="Tests"
            subtitle=""
            strapline=""
            size="md"
            bgColor="bg-white"
            />
        </>
  
  );
}

export default requireAuth(TestPage);
