import React from "react";
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import { useAuth } from "./../util/auth";
import { useLocation, Link } from "react-router-dom";
import { ArrowLeftIcon } from '@heroicons/react/24/solid';

const MainComponent = (props) => {
  const auth = useAuth();
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  return (
    <div>
      {location.pathname === "/execute" ? (
        <div className="relative z-50">
          <Link
            to="/tests"
            className="fixed top-5 left-4 bg-black hover:bg-gray-800 text-white p-2 rounded-full shadow-lg transition duration-300 ease-in-out flex items-center justify-center z-50 group"
            title="Go to Tests"
          >
            <ArrowLeftIcon className="h-5 w-5" />
            <span className="absolute invisible group-hover:visible bg-black text-white text-xs rounded-full py-1 px-2 left-full ml-2 whitespace-nowrap">
              Go to Tests
            </span>
          </Link>
        </div>
      ) : (
        <>
          {(auth.user && !isHomePage && !location.pathname.startsWith("/auth")) && (
            <Sidebar/>
          )}
          {(!auth.user || isHomePage || location.pathname.startsWith("/auth")) && (
            <Navbar bgColor="green-500" />
          )}
        </>
      )}
    </div>
  );
}

export default MainComponent;