import React from 'react';
import PropTypes from 'prop-types';

const Button = ({
  borderRadius = '20px',
  backgroundColor,
  textSize = '1rem',
  font = 'inherit',
  outlineColor = 'transparent',
  className,
  children,
  onClick,
  style,
}) => {
  return (
    <button
      style={{
        borderRadius: borderRadius,
        background: backgroundColor,
        fontSize: textSize,
        fontFamily: font,
        outline: `2px solid ${outlineColor}`,
        ...style,
      }}
      className={`px-7 py-2 transition duration-200 ease-in-out transform hover:scale-105 ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  borderRadius: PropTypes.string,
  backgroundColor: PropTypes.string,
  textSize: PropTypes.string,
  font: PropTypes.string,
  outlineColor: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  style: PropTypes.object,
};

export default Button;
