import React, { useState, useEffect, useRef } from 'react';
import { useRouter } from './../util/router';
import PageLoader from "./../components/PageLoader";
import rrwebPlayer from 'rrweb-player';
import 'rrweb-player/dist/style.css';

import { getHistoryIdInfo } from './../util/db';
import { ChevronDownIcon } from '@heroicons/react/24/solid';

function Report() {
  const router = useRouter();
  const [htmlContent, setHtmlContent] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reportMetadata, setReportMetadata] = useState(null);
  const [videoEvents, setVideoEvents] = useState(null);
  const playerContainerRef = useRef(null);

  useEffect(() => {
    const fetchReport = async () => {
      const { testId, token } = router.query;

      if (!testId || !token) {
        setError('Invalid URL parameters');
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/shared-report?testId=${testId}&token=${token}`);
        if (!response.ok) {
          throw new Error('Failed to fetch report');
        }
        const data = await response.json();
        const historyId = data.history_id;

        if (data.status === "success") {
          console.log("success");
          const historyInfo = await getHistoryIdInfo(historyId);

          if (historyInfo.metadata) {
            const metadataResponse = await fetch(historyInfo.metadata);
            const metadata = await metadataResponse.json();
            console.log(metadata);
            if (!metadataResponse.ok) {
              throw new Error('Network response was not ok');
            }
            if (!metadata.platform) {
              metadata.platform = "android";
            }

            setReportMetadata({
              status: historyInfo.success,
              testName: metadata.test_name,
              execution_time: historyInfo.execution_time,
              platform: metadata.platform || "android"
            });
            console.log(metadata.platform);
            console.log(metadata.video_url);
            if (metadata.video_url && metadata.platform === "android") {
              console.log("Android video URL: ", metadata.video_url['url']);
              setVideoUrl(metadata.video_url['url']);
            }
            let videoContent = null;
            if (metadata.video_url && metadata.platform === "web") {
              try {
                const videoResponse = await fetch(metadata.video_url['url']);
                console.log("Platform: ", metadata.platform);
                const videoContent = await videoResponse.json(); // Move this line inside the try block
                setVideoEvents(videoContent);
              } catch (error) {
                console.error('Error fetching video events:', error);
              }
            }

            if (metadata.html_report_url && metadata.html_report_url['url']) {
              const htmlResponse = await fetch(metadata.html_report_url['url']);
              const htmlContent = await htmlResponse.text();
              setHtmlContent(htmlContent);
            } else if (metadata.html_report_url) {
              const htmlResponse = await fetch(metadata.html_report_url);
              const htmlContent = await htmlResponse.text();
              setHtmlContent(htmlContent);

            }
          }
          console.log(videoEvents);

        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);

      }
    };

    fetchReport();
  }, [router.query]);
  useEffect(() => {
    if (videoEvents && playerContainerRef.current) {
        const player = new rrwebPlayer({
          target: playerContainerRef.current,
          props: {
            events: videoEvents,
            width: 800,
            height: 450,
            autoPlay: false,
          },
        });
  
        return () => {
          player.pause();
        };
      }
  }, [htmlContent]);

  const scrollToVideo = (e) => {
    e.preventDefault();
    const videoElement = document.getElementById('video');
    if (videoElement) {
      videoElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  if (loading) return <PageLoader />;
  if (error) return <div className="h-screen flex items-center justify-center text-red-500 text-xl">{error}</div>;

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      {reportMetadata && (
        <header className="bg-blue-600 text-white py-4 px-4 shadow-lg">
          <div className="container mx-auto">
            <h1 className="text-3xl font-bold mb-2">{reportMetadata.testName}</h1>
            <div className="flex items-center justify-between">
              <p className="text-sm">Executed on: {new Date(reportMetadata.execution_time).toLocaleString()}</p>
              <span className={`px-3 py-1 rounded-full text-xs font-semibold ${
                reportMetadata.status === true ? 'bg-green-500 text-green-900' : 'bg-red-500 text-red-900'
              }`}>
                {reportMetadata.status === true ? 'Success' : 'Failure'}
              </span>
            </div>
          </div>
        </header>
      )}
      
      <main className="flex-grow flex flex-col items-center">
        <div className="w-11/12 max-w-4xl h-[calc(100vh-12rem)] p-4 overflow-hidden flex flex-col">
          <h2 className="text-2xl font-bold mb-2 text-center">Test Report</h2>
          <div className="flex-grow relative border border-gray-300 rounded-lg shadow-md overflow-hidden">
            <div className="absolute inset-0 overflow-auto">
              <iframe
                srcDoc={htmlContent}
                className="w-full h-full border-none"
                title="HTML Report"
                sandbox="allow-same-origin allow-scripts allow-forms"
              />
            </div>
          </div>
        </div>
        
        {(videoUrl || videoEvents) && (
          <div className="h-12 flex items-center justify-center bg-gray-200 w-full">
            <button 
              onClick={scrollToVideo}
              className="flex items-center text-blue-600 hover:text-blue-800 transition-colors duration-200"
              aria-label="Scroll to video"
            >
              <span className="mr-2">View Test Recording</span>
              <ChevronDownIcon className="h-5 w-5" />
            </button>
          </div>
        )}
      </main>
      
      <div id="video" className="h-screen flex items-center justify-center">
        {videoUrl && reportMetadata?.platform === "android" && (
          <video
            src={videoUrl}
            controls
            className="max-w-full max-h-full"
            style={{ width: 'auto', height: 'auto' }}
          >
            Your browser does not support the video tag.
          </video>
        )}
        {videoEvents && reportMetadata?.platform === "web" && (
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-4xl w-full">
            <h3 className="text-xl font-semibold mb-4">Web Session Replay</h3>
            <div ref={playerContainerRef} className="w-full aspect-video border border-gray-200 rounded-md overflow-hidden">
              {/* rrweb player will be rendered here */}
            </div>
          </div>
        )}
      </div>
      <footer className="bg-gray-200 py-2 text-center text-gray-600 text-sm">
        <p>&copy; 2024 Revyl. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Report;