import React from "react";
import Meta from "./../components/Meta";
//import HeroSection2 from "./../components/HeroSection2";
//import StatsSection from "./../components/StatsSection";
//import TeamBiosSection from "./../components/TeamBiosSection";
import CtaSection from "./../components/CtaSection";
import WhyCogniSimSection from "../components/WhyCogniSimSection";

function AboutPage(props) {
  return (
    <>
      <Meta title="About" description="Learn about our company and team" />
      {/*<HeroSection2*/}
      {/*  title={*/}
      {/*    <>*/}
      {/*        Why <span className="font-light"><span style={{ fontWeight: 'bold', color: '#2463EB' }}>CogniSim?</span></span>*/}
      {/*    </>*/}
      {/*  }*/}
      {/*  subtitle="A passionate team who are here to help you code and build your business using powerful tools."*/}
      {/*  strapline=""*/}
      {/*  size="md"*/}
      {/*  bgColor="bg-white"*/}
      {/*  bgImage=""*/}
      {/*  bgImageOpacity={1}*/}
      {/*  textColor=""*/}
      {/*  leftImage="https://images.unsplash.com/photo-1542744173-8e7e53415bb0?crop=entropy&fit=crop&h=800&w=1280"*/}
      {/*  rightImage="https://images.unsplash.com/photo-1554232456-8727aae0cfa4?crop=entropy&fit=crop&h=800&q=80&w=600"*/}
      {/*/>*/}
        <WhyCogniSimSection
            title={
                <>
                    Why <span className="font-light"><span style={{ fontWeight: 'bold', color: '#2463EB' }}>CogniSim?</span></span>
                </>
            }
            subtitle="CogniSim is an innovative company leveraging AI agents to automate the creation and execution of end-to-end tests for any application, be it web or mobile."
            strapline=""
            size="md"
            bgColor="bg-white"
            bgImage=""
            bgImageOpacity={1}
            textColor=""
        />
      {/*<StatsSection*/}
      {/*  title="Trusted by thousands of professionals"*/}
      {/*  subtitle="Web developers from all over the world are using our products. Join them and build something amazing!"*/}
      {/*  strapline="Inspiring Results"*/}
      {/*  size="md"*/}
      {/*  bgColor="bg-white"*/}
      {/*  bgImage=""*/}
      {/*  bgImageOpacity={1}*/}
      {/*  textColor=""*/}
      {/*/>*/}
      {/*<TeamBiosSection*/}
      {/*  title="Meet our amazing team"*/}
      {/*  subtitle="They are working nonstop behind the scenes to help you build better products, web services and websites."*/}
      {/*  strapline=""*/}
      {/*  size="md"*/}
      {/*  bgColor="bg-white"*/}
      {/*  bgImage=""*/}
      {/*  bgImageOpacity={1}*/}
      {/*  textColor=""*/}
      {/*/>*/}
      <CtaSection
        title={
          <>
            Begin testing <span className="text-blue-600">today</span>!
          </>
        }
        subtitle="Save development time from day one by automating all of your end to end tests."
        strapline=""
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
    </>
  );
}

export default AboutPage;
