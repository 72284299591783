import React from "react";
import { useLocation } from "react-router-dom";

function SectionHeader(props) {
  const location = useLocation();
  // Render nothing if no title or subtitle
  if (!props.title && !props.subtitle && !props.strapline) {
    return null;
  }
  return (
    <header
      className={
        "mb-8 last:mb-0" + (props.className ? ` ${props.className}` : "")
      }
    >
      {props.strapline && (
        <div className="text-sm uppercase font-bold tracking-wider mb-1   ">
          {props.strapline}
        </div>
      )}

      {props.title && (
        <h1 className={`text-3xl md:text-4xl font-extrabold ${location.pathname === "/" ? 'text-white' : 'text-slate-900'}`}>{props.title}</h1>
      )}

      {props.subtitle && (
        <h2 className="mt-4 inline-block text-lg  md:text-xl md:leading-relaxed font-medium 1 max-w-screen-sm text-white">
          {props.subtitle}
        </h2>
      )}
    </header>
  );
}

export default SectionHeader;
