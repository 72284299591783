import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Make sure to import Link
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AssessmentIcon from "@mui/icons-material/Assessment";

const Widget = ({ type, data }) => {
  const [widgetData, setWidgetData] = useState({
    title: "",
    amount: 0,
    icon: null,
    showLink: false,
  });

  useEffect(() => {
    if (data) {
      switch (type) {
        case "totalTests":
          setWidgetData({
            title: "TOTAL TESTS",
            amount: data.totalTests,
            icon: <AssessmentIcon style={{ color: "gray" }} />,
            showLink: true,
          });
          break;
        case "passRate":
          setWidgetData({
            title: "PASS RATE",
            amount: `${data.passRate}%`,
            icon: <CheckCircleIcon style={{ color: "green" }} />,
            showLink: false,
          });
          break;
        case "testsToReview":
          setWidgetData({
            title: "TESTS TO REVIEW",
            amount: data.testsToReview,
            icon: <HighlightOffIcon style={{ color: "red" }} />,
            showLink: false,
          });
          break;
        default:
          setWidgetData({
            title: "UNKNOWN",
            amount: 0,
            icon: null,
            showLink: false,
          });
          break;
      } 
    }
  }, [type, data]);

  const widgetStyle = {
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
    padding: "20px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    height: "120px",
  };

  const leftStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  };

  const rightStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  };

  const titleStyle = {
    fontWeight: "bold",
    fontSize: "14px",
    color: "#6B7280",
  };

  const counterStyle = {
    fontSize: "24px",
    fontWeight: "600",
    color: "#111827",
  };

  const linkStyle = {
    fontSize: "12px",
    color: "#4B5563",
    textDecoration: "underline",
    cursor: "pointer",
  };

  const iconStyle = {
    backgroundColor: type === "totalTests" ? "#E5E7EB" : 
                     type === "passRate" ? "#D1FAE5" : "#FEE2E2",
    borderRadius: "50%",
    padding: "8px",
  };

  return (
    <div style={widgetStyle}>
      <div style={leftStyle}>
        <span style={titleStyle}>{widgetData.title}</span>
        <span style={counterStyle}>{widgetData.amount}</span>
        {widgetData.showLink && (
          <Link to="/tests" style={linkStyle}>
            See all tests
          </Link>
        )}
      </div>
      <div style={rightStyle}>
        <div style={iconStyle}>{widgetData.icon}</div>
      </div>
    </div>
  );
};

export default Widget;