import React, { useState, useEffect, useRef } from "react";
import {
  StarIcon,
  PencilSquareIcon,
  TrashIcon,
  PlayIcon,
  DocumentDuplicateIcon,
  ClipboardDocumentIcon,
  ClipboardDocumentCheckIcon,
  PlayCircleIcon
} from "@heroicons/react/24/solid";
import FormAlert from "./FormAlert";
import Button from "./Button";
import EditTestModal from "./EditTestModal";
import { useAuth } from "./../util/auth";
import { updateTest, useTestsByOwner, deleteTest, createTest, getApiKey } from "./../util/db";
import { useRouter } from "./../util/router";
import TestHistoryCard from './TestHistoryStatus';
import TestHistoryPagination from './TestHistoryPagination';
import TestHtmlReportModal from './TestHtmlReportModal';

import rrwebPlayer from 'rrweb-player';
import 'rrweb-player/dist/style.css';
import VideoPlayerModal from './VideoPlayerModal';
function TestItems(props) {
  const auth = useAuth();
  const router = useRouter();
  const [selectedLink, setSelectedLink] = useState(null);
  const [isLinkManuallySelected, setIsLinkManuallySelected] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [testsPerPage] = useState(500);
  const [searchTerm, setSearchTerm] = useState('');
  const [creatingTest, setCreatingTest] = useState(false);
  const [updatingTestId, setUpdatingTestId] = useState(null);
  const [apiKey, setApiKey] = useState(null);
  const [statuses, setStatuses] = useState({});
  const [histories, setHistories] = useState({});
  const [showMetadata, setShowMetadata] = useState(false);
  const [showHtmlReport, setShowHtmlReport] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentHistory, setCurrentHistory] = useState(null);
  const [htmlReport, setHtmlReport] = useState(null);
  const [viewingMetadataFor, setViewingMetadataFor] = useState(null);
  const [metadataContent, setMetadataContent] = useState(null);
  const memoizedHistories = useRef({});
  const memoizedStatuses = useRef({});
  const [copiedId, setCopiedId] = useState(null);
  const [isLocalMode, setIsLocalMode] = useState(false);
  const BASE_STEP_URL = isLocalMode ? "http://localhost:8001" : process.env.REACT_APP_STEP_API;
  const [showVideo, setShowVideo] = useState(false);
  const [videoSrc, setVideoSrc] = useState(null);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [videoEvents, setVideoEvents] = useState(null);
  
  const toggleVideo = (history) => {
    if (!showVideo) {
      setLoading(true);
      setCurrentHistory(history);
    
      // Fetch metadata to get session_id
      fetch(history.metadata)
        .then(response => response.json())
        .then(metadata => {
          if (metadata.session_id) {
            console.log("Session ID:", metadata.session_id);
            return fetchBrowserbaseVideo(metadata.session_id, apiKey);
          } else {
            throw new Error('No session_id found in metadata');
          }
        })
        .then(videoEvents => {
          console.log("Video Events:", videoEvents);
          setVideoEvents(videoEvents);
          setIsVideoModalOpen(true);
          setShowVideo(true);
        })
        .catch(error => {
          console.error("Error fetching video:", error);
          // Handle error (e.g., show error message to user)
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setShowVideo(false);
      setVideoEvents(null);
      setCurrentHistory(null);
      setIsVideoModalOpen(false);
    }
  };

  const getBrowserbaseVideo = async (sessionId) => {
    const url = `https://www.browserbase.com/v1/sessions/${sessionId}/recording`;
    const headers = {
      "X-BB-API-Key": process.env.REACT_APP_BROWSERBASE_API_KEY
    };
    
    try {
      const response = await fetch(url, { headers });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.blob();
    } catch (error) {
      console.error("Error fetching Browserbase video:", error);
      throw error;
    }
  };
  //History pagination
  const [historyPage, setHistoryPage] = useState({});
  const [historyPerPage] = useState(10);
  const paginateHistory = (testId, pageNumber) => {
    setHistoryPage(prevPages => ({ ...prevPages, [testId]: pageNumber }));
    // You might need to fetch new data here if you're not storing all history items client-side
    // loadHistoryPage(testId, pageNumber);
  };
 
   // Function to make authorized fetch requests
  const authorizedFetch = async (endpoint, method, body = {}) => {
    console.log(BASE_STEP_URL);
    const options = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiKey}`
      },
    };

    // Only include body if method isn't GET
    if (method !== 'GET') {
      options.body = JSON.stringify(body);
    }

    const response = await fetch(`${BASE_STEP_URL}${endpoint}`, options);
    if (response.ok) {
      const result = await response.json();
      return result;
    }
  };



  const {
    data: tests,
    status: testsStatus,
    error: testsError,
  } = useTestsByOwner(auth.user.uid, selectedLink);
  const testsExist = tests && tests.length > 0;
  const testsAreEmpty = !tests || Object.values(tests).every(linkTests => linkTests.length === 0);

  useEffect(() => {
    const fetchApiKey = async () => {
      try {
        const key = await getApiKey(auth.user.uid);
        setApiKey(key);
      } catch (error) {
        console.error("Error fetching API key:", error);
      }
    };

    fetchApiKey();
  }, [auth.user.uid]);

  useEffect(() => {
    //Fetch the statuses of the test
    const fetchStatuses = async () => {
      if (tests && apiKey) {
        const filteredTests = selectedLink
          ? tests.filter(test => test.link === selectedLink)
          : tests;

        const initialStatuses = filteredTests.reduce((acc, test) => {
          acc[test.id] = { status: 'loading' };
          return acc;
        }, {});
        setStatuses(initialStatuses);

        for (const test of filteredTests) {
          if (memoizedStatuses.current[test.id]) {
            setStatuses(prevStatuses => ({
              ...prevStatuses,
              [test.id]: memoizedStatuses.current[test.id]
            }));
          } else {
            const status = await fetchLastStatus(test.id, apiKey);
            memoizedStatuses.current[test.id] = status;
            setStatuses(prevStatuses => ({
              ...prevStatuses,
              [test.id]: status
            }));
          }
        }
      }
    };

    fetchStatuses();
  }, [tests, apiKey, selectedLink]);

  useEffect(() => {
    const fetchHistories = async () => {
      console.log("Fetching histories for ", selectedLink);
      if (tests && apiKey) {
        const filteredTests = selectedLink
          ? tests.filter(test => test.link === selectedLink)
          : tests;

        console.log("Filtered Tests for selected link:", filteredTests);

        const initialHistories = filteredTests.reduce((acc, test) => {
          acc[test.id] = { history: 'loading' };
          return acc;
        }, {});

        console.log("Initial Histories:", initialHistories);

        setHistories(initialHistories);

        try {
          const historiesPromises = filteredTests.map(async test => {
            if (memoizedHistories.current[test.id]) {
              return { id: test.id, history: memoizedHistories.current[test.id] };
            }

            console.log(`Fetching history for test ID: ${test.id}`);
            const history = await fetchTestHistory(test.id, apiKey);
            memoizedHistories.current[test.id] = history;
            return { id: test.id, history };
          });

          const histories = await Promise.all(historiesPromises);

          const updatedHistories = histories.reduce((acc, { id, history }) => {
            acc[id] = history;
            return acc;
          }, {});

          console.log("Updated Histories before setting state:", updatedHistories);

          setHistories(prevHistories => {
            const newHistories = {
              ...prevHistories,
              ...updatedHistories
            };
          
            // Update hasMoreHistory state based on the fetched histories
            const updatedHasMoreHistory = Object.keys(newHistories).reduce((acc, testId) => {
              acc[testId] = newHistories[testId].length >= historyPerPage; // Assuming historyPerPage is the default limit
              return acc;
            }, {});
            
            setHasMoreHistory(prevHasMoreHistory => ({
              ...prevHasMoreHistory,
              ...updatedHasMoreHistory
            }));
       
            console.log("Final Histories to set:", newHistories);
            return newHistories;
          });

          console.log('Histories updated for all tests');
        } catch (error) {
          console.error('Error fetching histories:', error);
        }
      }
    };

    fetchHistories();
  }, [tests, apiKey, selectedLink]);
  const [copiedMessage, setCopiedMessage] = useState(null);
  const handleCopyId = (id) => {
    navigator.clipboard.writeText(id).then(() => {
      setCopiedId(id);
      setCopiedMessage("ID Copied");
      setTimeout(() => {
        setCopiedId(null);
        setCopiedMessage(null);
      }, 2000); // Reset after 2 seconds
    });
  };


  const handleDuplicateTest = async (test) => {
    const newTestName = prompt("Enter a name for the new test", `${test.testname} (Copy)`);
    if (newTestName) {
      const newTest = {
        ...test,
        testname: newTestName
      };
      delete newTest.id;
      try {
        await createTest(newTest);
        alert("Test duplicated successfully");
      } catch (error) {
        console.error("Error duplicating test:", error);
        alert("An error occurred while duplicating the test");
      }
    }
  };
  const [generatingLink, setGeneratingLink] = useState(false);

  const generateShareableReportLink = async (entry) => {
    console.log("Entry keys:", Object.keys(entry));
    setGeneratingLink(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/generate_shareable_report_link`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`
        },
        body: JSON.stringify({
          test_id: entry.test_uid,
          history_id: entry.id,
          origin: window.location.origin
        })
      });

      if (!response.ok) {
        throw new Error('Failed to generate shareable link');
      }

      const data = await response.json();
      navigator.clipboard.writeText(data.shareable_link);
      alert("Shareable report link copied to clipboard");
    } catch (error) {
      console.error("Error generating shareable link:", error);
      alert("Failed to generate shareable link. Please try again.");
    } finally {
      setGeneratingLink(false);
    }
  };
  
  const API_BASE_URL =process.env.REACT_APP_API_BASE_URL;

  async function fetchTestHistory(testId, authToken, limit = 15, offset = 0) {
    const url = `${API_BASE_URL}/get_test_history?test_id=${testId}&limit=${limit}&offset=${offset}`;
    console.log(`Fetching test history for test ID: ${testId} with URL: ${url}`);
    
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      console.log(`Data received for test ID ${testId}:`, data);
      return data;
    } catch (error) {
      console.error(`Error fetching history for test ID ${testId}:`, error);
      throw error;
    }
  }
  //Load more than initial history logic
  const [historyOffsets, setHistoryOffsets] = useState({});
  const [hasMoreHistory, setHasMoreHistory] = useState({});


  const loadMoreHistory = async (testId) => {
    const currentHistory = histories[testId] || [];
    const currentOffset = historyOffsets[testId] || 0;
    const newOffset = currentOffset + historyPerPage;
    console.log(newOffset);
    try {
      const newHistory = await fetchTestHistory(testId, apiKey, historyPerPage, newOffset);
      setHistories(prevHistories => ({
      ...prevHistories,
      [testId]: [...currentHistory, ...newHistory]
    }));
    setHistoryOffsets(prevOffsets => ({
      ...prevOffsets,
      [testId]: newOffset
    }));
    setHasMoreHistory(prevHasMore => ({
      ...prevHasMore,
      [testId]: newHistory.length >= historyPerPage
    }));
    } catch (error) {
      console.error('Error loading more history:', error);
    }
  }

  const downloadMetadata = async (entry) => {
    try {
      const response = await fetch(entry.metadata);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const metadata = await response.text();
      
      // Create a Blob from the metadata
      const blob = new Blob([metadata], { type: 'application/json' });
      
      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(blob);
      
      // Create a temporary anchor element
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `metadata_${entry.id}.json`;
      
      // Append the anchor to the body, trigger the download, and remove the anchor
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading metadata:", error);
      alert("Error downloading metadata. Please try again.");
    }
  };
  async function fetchBrowserbaseVideo(sessionId, authToken) {
    const url = `${BASE_STEP_URL}/get_browserbase_video?session_id=${sessionId}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${authToken}`,
  
        }
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      // Log the type of data.video
      console.log('Type of data.video:', typeof data.video);
      
      // If data.video is an object or array, log its structure
      if (typeof data.video === 'object' && data.video !== null) {
        console.log('Structure of data.video:', JSON.stringify(data.video, null, 2));
      }
      // Parse the JSON string into an array
      const videoEvents = JSON.parse(data.video);
      return videoEvents;
    } catch (error) {
      console.error('Error fetching browserbase video:', error);
      throw error;
    }
  }

  async function fetchLastStatus(testId, authToken) {
    const url = `${API_BASE_URL}/get_last_status?test_id=${testId}`;
  
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        }
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      const status = data.status;
      const date = data.last_executed;
      return { status, date };
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  function groupTestsByLink(testsArray) {
    return testsArray.reduce((groups, test) => {
      const group = groups[test.link] || [];
      group.push(test);
      groups[test.link] = group;
      return groups;
    }, {});
  }

  const toggleMetadata = async (entry) => {
    if (viewingMetadataFor === entry.id) {
      setViewingMetadataFor(null);
      setMetadataContent(null);
    } else {
      try {
        const response = await fetch(entry.metadata);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const metadata = await response.text();
        setMetadataContent(metadata);
        setViewingMetadataFor(entry.id);
      } catch (error) {
        console.error("Error fetching metadata:", error);
      }
    }
  };

  const toggleHtmlReport = (history) => {
    if (!showHtmlReport) {
      console.log("Metadata URL:", history.metadata);
      setLoading(true);
      setCurrentHistory(history);
      fetch(history.metadata)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(metadata => {
          if (metadata.html_report_url && metadata.html_report_url['url']) {
            console.log('HTML report URL:', metadata.html_report_url['url']);
            fetch(metadata.html_report_url['url'])
              .then(response => response.text())
              .then(htmlContent => {
                console.log("Setting HTML report");
                setHtmlReport(htmlContent);
                setShowHtmlReport(true);
                setLoading(false);
              });
          } else if (metadata.html_report) {
            console.log('Fetching HTML report content');
            setHtmlReport(metadata.html_report);
            setShowHtmlReport(true);
            setLoading(false);
          }
        })
        .catch(error => {
          console.error("Error fetching HTML report:", error);
          setLoading(false);
        });
    } else {
      setShowHtmlReport(false);
      setHtmlReport(null);
      setCurrentHistory(null);
    }
  };

  useEffect(() => {
    if (showHtmlReport && currentHistory) {
      console.log("HTML Report is now being shown for history:", currentHistory);
      console.log("Current HTML Report:", htmlReport);
    }
  }, [showHtmlReport, currentHistory]);

  const filteredTests = tests ? tests.filter(test =>
    test.testname.toLowerCase().includes(searchTerm.toLowerCase())
  ) : [];
  const groupedTests = tests ? groupTestsByLink(filteredTests) : {};

  useEffect(() => {
    if (tests && tests.length > 0 && !isLinkManuallySelected) {
      const firstLink = Object.keys(groupedTests)[0];
      setSelectedLink(firstLink);
    }
  }, [tests, groupedTests, isLinkManuallySelected]);

  const handleLinkClick = (link) => {
    setSelectedLink(link);
    setIsLinkManuallySelected(true);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  if (!tests) {
    return <div>Loading...</div>;
  }

  const totalTests = tests ? tests.length : 0;
  const totalPages = Math.ceil(totalTests / testsPerPage);
  const indexOfLastTest = currentPage * testsPerPage;
  const indexOfFirstTest = indexOfLastTest - testsPerPage;
  const currentTests = tests ? tests.slice(indexOfFirstTest, indexOfLastTest) : [];
  const currentGroupedTests = groupTestsByLink(currentTests);

  const Pagination = ({ testsPerPage, totalTests, paginate, currentPage }) => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalTests / testsPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <div className="flex justify-center border-slate-400 bg-slate-100 border-x border-b py-4">
        <div className="join">
          {pageNumbers.map(number => (
            <button
              key={number}
              onClick={(e) => {
                e.preventDefault();
                paginate(number);
              }}
              className={` btn ${currentPage === number ? 'btn-active' : ''}`}
            >
              {number}
            </button>
          ))}
        </div>
      </div>
    );
  };


  const handlePlayTest = (test) => {
    localStorage.setItem("currentTestUid", test.id);
    router.history.push("/execute");
  }
  const handleExecuteTest = async (testId) => {
    try {
      const taskData = {
        test_id: testId,
        // Add other necessary fields if required
      };

      const response = await authorizedFetch("/execute_test_id", "POST", taskData);

      if (response) {
        console.log('Test execution result:', response);
        // You might want to update the UI here to show the execution result
        alert('Test executed successfully!');
      } else {
        throw new Error('No response from server');
      }
    } catch (error) {
      console.error('Error executing test:', error);
      alert('Error executing test. Please try again.');
    }
  };

  const handleCreateTest = () => {
    localStorage.setItem("currentTestUid", "");
    router.history.push("/execute");
  }

  const filteredGroupedTests = selectedLink
    ? { [selectedLink]: groupedTests[selectedLink] }
    : groupedTests;

  return (
    <>
      {testsError && (
        <div className='mb-4'>
          <FormAlert type='error' message={testsError.message} />
        </div>
      )}
      <div>
        <div className="p-4 border border-slate-400 rounded-t-md bg-slate-100">
          <div className='flex justify-between items-center '>
            <span className='text-3xl text-black font-bold m-3 pl-3 pb-4'>Tests</span>
            <button onClick={() => handleCreateTest()} class="btn btn-sm btn-success  py-2 px-4">New Test</button>
          </div>
        </div>

        {testsStatus === 'loading' ? (
          <div className='p-8'>Loading...</div>
        ) : testsAreEmpty ? (
          <div className='p-8'>Nothing yet. Click the button to add your first test.</div>
        ) : (
          Object.entries(filteredGroupedTests).map(([link, tests], linkIndex) => (
            <div className=" border-x border-slate-400 bg-slate-100 " key={linkIndex}>
              <div className="flex justify-between">
                <div className="flex items-center">
                  <span className='text-black ml-4 font-bold '>Showing tests for </span>
                  <div className="dropdown dropdown-hover">
                    <div tabIndex={0} role="button" className="btn btn-outline btn-info btn-sm m-3 w-auto">{selectedLink || link}</div>
                    <ul tabIndex={0} className="dropdown-content menu bg-white text-black rounded-box z-[1] p-2 ">
                      {Object.entries(groupedTests).map(([link, tests], linkIndex) => (
                        <div key={linkIndex}>
                          <li>
                            <a href="#" onClick={() => handleLinkClick(link)}>{link}</a>
                          </li>
                        </div>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="flex mr-3 items-center">
                  <input
                    type="text"
                    placeholder="Search tests..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    className="search-input m-2 p-2 text-sm rounded-md"
                    style={{ marginLeft: '10px' }}
                  />
                </div>
              </div>
              <div className="border-t px-4 py-2 border-slate-400 text-black">
                <div className='flex items-center space-x-1 w-full'>
                  <div className='flex-shrink-0' style={{ width: '180px' }}>
                    <span className='break-words'>Name</span>
                  </div>
                  <div className='flex-shrink-0 w-[120px] lg:w-[260px] xl:w-[403px]'>
                    <span>ID</span>
                  </div>
                  <div className='flex-shrink-0 w-[100px] lg:w-[120px] xl:w-[150px]'>
                    <span>Schedule</span>
                  </div>
                  <div className='flex-shrink-0 w-[80px] lg:w-[83px] xl:w-[100px]'>
                    <span >Status</span>
                  </div> 
                  <div className='flex-shrink-0 break-words w-[10px] lg:w-[240px] flex lg:justify-start justify-center items-center'>
                    <span className='text-center'>Latest run</span>
                  </div>
                </div>
              </div>
              <div className='min-h-[480px] bg-white border-b border-slate-400'>
                {tests && tests.length > 0 ? (
                  tests.map((test, testIndex) => (
                    <div id={`accordion-collapse-${testIndex}`} data-accordion="collapse" key={testIndex}>
                      <h2 id={`accordion-collapse-heading-${testIndex}`}>
                        <div
                          className={`flex px-4 py-2 border-slate-400 text-black bg-gray-100 border-t ${
                            testIndex === tests.length - 1 ? 'border-b' : ''
                          } ${test.featured ? 'bg-green-200' : ''}`}
                        >
                          <div
                            className="flex items-center min-h-[40px] space-x-1 w-full cursor-pointer"
                            onClick={() => {
                              const accordionBody = document.getElementById(`accordion-collapse-body-${testIndex}`);
                              accordionBody.classList.toggle('hidden');
                              setExpandedIndex(testIndex);
                            }}
                          >
                            <div className="flex-shrink-0" style={{ width: '180px' }}>
                              <span className="break-words">{test.testname}</span>
                            </div>
                            <div className="flex-shrink-0 w-[100px] lg:w-[240px] xl:w-[360px] flex items-center">
                              <span className="text-gray-500 break-words mr-2">ID:</span>
                          <div className="relative flex items-center">
                            <span className="text-gray-500 break-words mr-2">{test.id}</span>
                                <button
                                  onClick={() => handleCopyId(test.id)}
                                  className="text-gray-500 hover:text-gray-700 transition-colors duration-200 ml-1"
                                >
                                  {copiedId === test.id ? (
                                    <ClipboardDocumentCheckIcon className="h-5 w-5 text-green-500 animate-pulse" />
                                  ) : (
                                    <ClipboardDocumentIcon className="h-5 w-5" />
                                  )}
                                </button>
                                {copiedMessage && copiedId === test.id && (
                              <span className="absolute left-full ml-2 top-1/2 -translate-y-1/2 text-xs text-white bg-green-500 px-2 py-1 rounded shadow whitespace-nowrap">
                                {copiedMessage}
                              </span>
                            )}
                          </div>
                        </div>
                            <div className="flex-shrink-0 w-[100px] lg:w-[120px] xl:w-[150px] flex flex-col pl-10">
                              <span className="text-gray-700">
                                {test.schedule || 'N/A'}
                              </span>
                            </div>
                            <div className="flex-shrink-0 w-[85px] lg:w-[90px] xl:w-[130px] flex justify-center">
                              <span className={`badge ${
                                statuses[test.id]?.status === 'success' ? 'bg-green-100 text-green-800' :
                                statuses[test.id]?.status === 'not found' ? 'bg-gray-100 text-gray-800' :
                                statuses[test.id]?.status === 'loading' ? 'bg-yellow-100 text-yellow-800' :
                                'bg-red-100 text-red-800'
                              } font-semibold px-3 py-1 rounded-full`}>
                                {statuses[test.id]?.status === 'success' ? 'Success' :
                                statuses[test.id]?.status === 'not found' ? 'N/A' :
                                statuses[test.id]?.status === 'loading' ? 'Loading...' :
                                'Failed'}
                              </span>
                            </div>
                            <div className="flex-shrink-0 w-[60px] lg:w-[100px] xl:w-[100px] flex justify-center">
                              <span>{statuses[test.id]?.status === 'not found' ? 'N/A' : statuses[test.id]?.date ? new Date(statuses[test.id].date).toISOString().split('T')[0] : 'Loading...'}</span>
                            </div>
                          </div>
                          <div className="flex items-center ml-auto space-x-3">
                            <button
                              onClick={() => handlePlayTest(test)}
                              className="btn btn-sm btn-success py-2 px-4"
                            >
                              Edit
                            </button>
                            <button
                              onClick={() => handleExecuteTest(test.id)}
                              className="hover:opacity-80 transition-opacity duration-200"
                            >
                              <PlayCircleIcon className="h-7 w-7" />
                            </button>
                            <button
                              className="w-5 h-5 text-slate-600"
                              onClick={() => handleDuplicateTest(test)}
                            >
                              <DocumentDuplicateIcon />
                            </button>
                            <button
                              className="w-5 h-5 text-slate-600"
                              onClick={() => deleteTest(test.id)}
                            >
                              <TrashIcon />
                            </button>
                          </div>
                        </div>
                      </h2>
                      <div
                        id={`accordion-collapse-body-${testIndex}`}
                        className={`${expandedIndex === testIndex || testIndex === 0 ? '' : 'hidden'}`}
                        aria-labelledby={`accordion-collapse-heading-${testIndex}`}
                      >
                        <div className={`p-5 ${testIndex === tests.length - 1 ? 'border-b' : 'border-t'} border-gray-400 dark:border-gray-400 bg-white`}>
                          <div className="mt-3">
                            {histories[test.id]?.history === 'loading' ? (
                              <p>Loading history...</p>
                            ) : histories[test.id] ? (
                              <div>
                                {viewingMetadataFor && viewingMetadataFor.startsWith(test.id) && (
                                  <div className="w-full mt-2 bg-white p-4 shadow-lg rounded-lg overflow-auto" style={{ maxHeight: '200px' }}>
                                    <pre className="text-xs overflow-auto" style={{ whiteSpace: 'nowrap', wordWrap: 'normal' }}>
                                      {metadataContent}
                                    </pre>
                                  </div>
                                )}
                                <h3>Test History:</h3>
                                <ul>
                                  {histories[test.id]
                                    .slice((historyPage[test.id] || 1) - 1, ((historyPage[test.id] || 1) - 1) + historyPerPage)
                                    .map((entry, index) => (
                                      <div key={index}>
                                        {viewingMetadataFor === entry.id && (
                                          <div className="max-w-full mt-2 bg-white p-4 shadow-lg rounded-lg overflow-x-auto" style={{ maxWidth: '100%' }}>
                                            <pre className="text-xs overflow-auto" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                                              <a href={entry.metadata} target="_blank" rel="noopener noreferrer">
                                                {entry.metadata}
                                              </a>
                                            </pre>
                                          </div>  
                                        )}
                                        <li className="list-group-item flex items-center justify-between p-4 border-b">
                                          <span className={`badge ${entry.status === 'success' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'} font-semibold px-3 py-1 rounded-full`}>
                                            {entry.status === 'success' ? 'Success' : 'Failure'}
                                          </span>
                                          <span className="ms-2 text-sm w text-gray-500">
                                            {entry.execution_time ? new Date(entry.execution_time).toLocaleString('en-US', { 
                                              year: 'numeric', 
                                              month: '2-digit', 
                                              day: '2-digit', 
                                              hour: '2-digit', 
                                              minute: '2-digit', 
                                              second: '2-digit', 
                                              hour12: false 
                                            }) : 'N/A'}
                                          </span>
                                          <button 
                                            onClick={() => toggleHtmlReport(entry)}
                                            className="text-blue-500 hover:text-blue-700 text-sm font-semibold"
                                          >
                                            Show HTML Report
                                          </button>
                                          <button
                                            onClick={() => toggleVideo(entry)}
                                            className="text-blue-500 hover:text-blue-700 text-sm font-semibold"
                                          >
                                            View Video
                                          </button>
                                          <VideoPlayerModal
                                            isOpen={isVideoModalOpen}
                                            onClose={() => setIsVideoModalOpen(false)}
                                            videoEvents={videoEvents}
                                          />
                                          <button 
                                            onClick={() => downloadMetadata(entry)}
                                            className="text-blue-500 hover:text-blue-700 text-sm font-semibold"
                                          >
                                           Download Metadata
                                          </button>
                                          <button
                                            onClick={() => generateShareableReportLink(entry)}
                                            className="text-blue-500 hover:text-blue-700 text-sm font-semibold"
                                          >
                                            Shareable Report Link
                                          </button>

                                           <TestHtmlReportModal
                                              showHtmlReport={showHtmlReport}
                                              toggleHtmlReport={() => toggleHtmlReport(entry)}
                                              htmlReport={htmlReport}
                                              loading={loading}
                                            />
                                        </li>
                                      </div>
                                    ))}
                                </ul>
                                {histories[test.id].length > 0 && (
                                    <TestHistoryPagination
                                    historyPerPage={historyPerPage}
                                    totalHistory={histories[test.id].length}
                                    paginate={paginateHistory}
                                    currentPage={historyPage[test.id] || 1}
                                    testId={test.id}
                                    hasMoreHistory={hasMoreHistory[test.id]}
                                    loadMoreHistory={loadMoreHistory}
                                  />
                                )}
                              </div>
                            ) : (
                              <p>No history available.</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className='p-8'>No tests available for this link.</div>
                )}
              </div>
            </div>
          ))
        )}
      </div>
      <Pagination
        testsPerPage={testsPerPage}
        totalTests={totalTests}
        paginate={setCurrentPage}
        currentPage={currentPage}
      />
      {creatingTest && (
        <EditTestModal
          onDone={() => setCreatingTest(false)}
        />
      )}
      {updatingTestId && (
        <EditTestModal
          testId={updatingTestId}
          onDone={() => setUpdatingTestId(null)}
        />
      )}
    </>
  );
}

export default TestItems;