import React, { useRef, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { getApiKey } from "./../util/db"; // Ensure this import is correct
import { useHistory } from "react-router-dom";
import { useAuth } from "./../util/auth"; // Import the useAuth hook

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // Use localhost:8000 as the base URL

const FailingTests = () => {
  const history = useHistory();
  const tableContainerRef = useRef(null);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const auth = useAuth(); // Get auth context
  const [apiKey, setApiKey] = useState(null);

  useEffect(() => {
    const fetchApiKey = async () => {
      try {
        const key = await getApiKey(auth.user.uid);
        setApiKey(key);
      } catch (error) {
        console.error("Error fetching API key:", error);
      }
    };

    fetchApiKey();
  }, [auth.user.uid]);

  useEffect(() => {
    const fetchData = async () => {
      if (auth.user) {
        try {
          const response = await fetch(`${API_BASE_URL}/get_failing_tests_with_latest_run?user_id=${auth.user.uid}`, {
            headers: {
              'Authorization': `Bearer ${apiKey}`, // Use the token from auth context
              'Content-Type': 'application/json'
            }
          });
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          const formattedRows = data.map((test) => ({
            id: test.test_id,
            name: test.test_name, // Use test_name from the payload
            date: new Date(test.latest_run_date).toLocaleDateString(), // Formatting date
            status: 'Failed'
          }));

          const storedData = JSON.parse(localStorage.getItem('failingTestsData'));
          if (JSON.stringify(storedData) !== JSON.stringify(formattedRows)) {
            localStorage.setItem('failingTestsData', JSON.stringify(formattedRows));
            setRows(formattedRows);
          } else {
            setRows(storedData);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    const storedData = JSON.parse(localStorage.getItem('failingTestsData'));
    if (storedData) {
      setRows(storedData);
      setLoading(false);
    } else {
      fetchData();
    }
  }, [auth, apiKey]);

  useEffect(() => {
    if (!auth.user) {
      localStorage.clear(); // Clear all local storage data on logout
    }
  }, [auth.user]);

  const styles = {
    tableContainer: {
      backgroundColor: '#ffffff', // Changed to white
      width: '100%',
      padding: '20px',
      maxHeight: '350px', // Adjusted to show only 7 rows before scrolling
      overflowY: 'scroll',
      cursor: 'pointer',
      outline: '1px solid #000000',
    },
    tableCellHeader: {
      fontWeight: 'bold',
    },
    tableCell: {
      borderBottom: 'none',
    },
    moreLink: {
      display: 'block',
      textAlign: 'center',
      marginTop: '10px',
      color: '#000000',
      textDecoration: 'none',
      fontWeight: 'bold',
    }
  };

  const handleRowClick = (rowId) => {
    localStorage.setItem("currentTestUid", rowId);
    history.push('/execute');
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (tableContainerRef.current) {
        if (event.key === 'ArrowDown') {
          tableContainerRef.current.scrollBy({ top: 30, behavior: 'smooth' });
        } else if (event.key === 'ArrowUp') {
          tableContainerRef.current.scrollBy({ top: -30, behavior: 'smooth' });
        }
      }
    };

    const tableContainer = tableContainerRef.current;
    if (tableContainer) {
      tableContainer.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (tableContainer) {
        tableContainer.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <TableContainer
      component={Paper}
      style={styles.tableContainer}
      tabIndex={0}
      ref={tableContainerRef}
    >
      <style>
        {`
          .MuiTableContainer-root::-webkit-scrollbar {
            width: 12px;
          }
          .MuiTableContainer-root::-webkit-scrollbar-track {
            background: #ffffff; // Changed to white
          }
          .MuiTableContainer-root::-webkit-scrollbar-thumb {
            background: #888;
          }
          .MuiTableContainer-root::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
        `}
      </style>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={styles.tableCellHeader}>Name</TableCell>
            <TableCell style={styles.tableCellHeader}>Last Run</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.slice(0, 20).map((row) => ( // Limit to 20 items
            <TableRow
              key={row.id}
              onClick={() => handleRowClick(row.id)} // Pass row.id to handleRowClick
              className="hover:border hover:border-gray-400 hover:border-l hover:border-r"
              style={{ cursor: 'pointer' }}
            >
              <TableCell style={styles.tableCell}>{row.name}</TableCell>
              <TableCell style={styles.tableCell}>{row.date}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {rows.length > 20 && (
        <a href="/tests" style={styles.moreLink}>See More</a>
      )}
    </TableContainer>
  );
};

export default FailingTests;