import { registerMetadata as registerMetadata_1 } from "@million/lint/runtime";
import { $$ as $$_1 } from "@million/lint/runtime";
import clsx from 'clsx';
import { Link } from './link';
export function Text(props_1) {
  let _$_1 = $$_1(512, "27.28", null, 29, null, null, null, null, null);
  let {
    className,
    ...props
  } = $$_1(2, "27.28", props_1, 30, null, null, 0, null, _$_1);
  return <p data-slot="text" {...props} className={clsx(className, 'text-base/6 text-zinc-500 sm:text-sm/6 dark:text-zinc-400')} />;
}
export function TextLink(props_2) {
  let _$_2 = $$_1(512, "27.32", null, 33, null, null, null, null, null);
  let {
    className,
    ...props
  } = $$_1(2, "27.32", props_2, 34, null, null, 0, null, _$_2);
  return <Link {...props} className={clsx(className, 'text-zinc-950 underline decoration-zinc-950/50 data-[hover]:decoration-zinc-950 dark:text-white dark:decoration-white/50 dark:data-[hover]:decoration-white')} />;
}
export function Strong(props_3) {
  let _$_3 = $$_1(512, "27.36", null, 37, null, null, null, null, null);
  let {
    className,
    ...props
  } = $$_1(2, "27.36", props_3, 38, null, null, 0, null, _$_3);
  return <strong {...props} className={clsx(className, 'font-medium text-zinc-950 dark:text-white')} />;
}
export function Code(props_4) {
  let _$_4 = $$_1(512, "27.40", null, 41, null, null, null, null, null);
  let {
    className,
    ...props
  } = $$_1(2, "27.40", props_4, 42, null, null, 0, null, _$_4);
  return <code {...props} className={clsx(className, 'rounded border border-zinc-950/10 bg-zinc-950/[2.5%] px-0.5 text-sm font-medium text-zinc-950 sm:text-[0.8125rem] dark:border-white/20 dark:bg-white/5 dark:text-white')} />;
}
registerMetadata_1("27.28", 1, Text);
registerMetadata_1("27.32", 1, TextLink);
registerMetadata_1("27.36", 1, Strong);
registerMetadata_1("27.40", 1, Code);