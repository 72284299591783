import React from "react";
import { useLocation } from "react-router-dom";
import "./../styles/global.css";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import AboutPage from "./about";
import FaqPage from "./faq";
import ContactPage from "./contact";
import PricingPage from "./pricing";
import ExecutePage from "./execute";
import TestHistoryPage from "./testhistory";
import TestPage from "./tests";
import AuthPage from "./auth";
import SettingsPage from "./settings";
import LegalPage from "./legal";
import { Switch, Route } from "./../util/router";
import PurchasePage from "./purchase";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
import Chat from "./../components/Chat";
import { AuthProvider } from "./../util/auth";
import { QueryClientProvider } from "./../util/db";
import MainComponent from "../components/Maincomponent";
import Sidebar from  "../components/Sidebar";
import dashboard from "./dashboard";
import WorkflowPage from "./workflow";
import Report from "./report";
function App(props) {
  const location = useLocation(); // This is now safe to use
  const flexSettings = {
    paths: ["/execute", "/executetest", "/settings/general", "/settings/password", "/testhistory", "/tests", "/pricing","/dashboard", "/workflow"],
    flexDirection: "flex-col"
  };
  console.log("App props:", props);
  
  return (
    <QueryClientProvider>
      <AuthProvider>
        <Chat />
        <>
          
        
        <div className={`flex ${flexSettings.paths.includes(location.pathname) ? "flex-row" : flexSettings.flexDirection}`}>
        <MainComponent/>
          <div className="relative md:flex-grow  "> 
          <Switch>
            <Route exact path="/" component={(props) => <AuthPage {...props} type="signin" />} />
            <Route exact path="/about" component={AboutPage} />
            <Route exact path="/faq" component={FaqPage} />
            <Route exact path="/contact" component={ContactPage} />
            <Route exact path="/pricing" component={PricingPage} />
            <Route exact path="/execute" component={ExecutePage} />
            <Route exact path="/dashboard" component={dashboard} />
            <Route exact path="/tests" component={TestPage} />
            <Route exact path="/testhistory" component={TestHistoryPage} />
            <Route exact path="/auth/:type" component={AuthPage} />
            <Route exact path="/settings/:section" component={SettingsPage} />
            <Route exact path="/legal/:section" component={LegalPage} />
            <Route exact path="/purchase/:plan" component={PurchasePage} />
            <Route exact path="/workflow" component={WorkflowPage} />
            <Route exact path="/report" component={Report} />

            <Route component={NotFoundPage} />
          </Switch>
          </div>
          </div>
          
        </>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
