import React from "react";
import Section from "./Section";
import { useAuth } from "../util/auth";
import WorkflowItems from "./WorkflowItems";

function WorkflowSection(props) {
    const auth = useAuth();

    return (
        <Section
            size={props.size}
            bgColor={props.bgColor}
            bgImage={props.bgImage}
            bgImageOpacity={props.bgImageOpacity}
            textColor={props.textColor}
            className="min-h-screen flex flex-col"
        >
            <div className="container">
                <div className="flex flex-wrap">
                    <div className="p-4 w-full">
                        <div className="rounded border border-gray-200">
                            <WorkflowItems />
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    );
}

export default WorkflowSection;