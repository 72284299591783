import React, { useState } from "react";
import {
  ChevronDownIcon,
  CodeBracketIcon,
  SwatchIcon,
  PuzzlePieceIcon,
  BeakerIcon,
  DocumentIcon,
  LifebuoyIcon,
  ChatBubbleLeftEllipsisIcon,
  Squares2X2Icon,
  CogIcon,
  LockClosedIcon,
  Bars3Icon,
  PlayIcon
} from "@heroicons/react/24/solid";
import { Menu, Transition } from "@headlessui/react";
import Section from "./Section";
import { Link } from "./../util/router";
import FeatureIcon from "./FeatureIcon";
import Button from "./newbutton";
import { useAuth } from "./../util/auth";
import { useLocation } from "react-router-dom";

const Navbar = (props) => {
  const auth = useAuth();
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const location = useLocation(); 
  const classes = {
    navLink:
      "font-semibold inline-flex items-center space-x-1 h-8 px-4 group-hover:text-blue-500 py-6",
    navLinkIcon:
      "opacity-50 transform transition duration-200 ease-out group-hover:rotate-180 inline-block w-4 h-4",
    dropdown: {
      base: "absolute top-19 pt-1 z-10 invisible group-hover:visible transform transition duration-800 ease-in opacity-0 group-hover:opacity-100",
      left: "right-0",
      center: "left-1/2 -translate-x-1/2",
      right: "left-0",
      normal: "w-48",
      large: "w-96",
      inner:
        "bg-white shadow-xl ring-1 ring-black ring-opacity-5 rounded-lg overflow-hidden",
      title:
        "text-xs uppercase font-semibold tracking-wider text-blue-800 mb-5",
      link: "text-gray-600 hover:text-blue-600 font-medium text-sm flex items-center space-x-2",
      icon: "opacity-25 inline-block w-5 h-5",
      feature:
        "p-3 rounded-xl flex items-center space-x-4 text-gray-600 font-medium text-sm",
      featureName: "font-semibold mb-1",
      featureDescription: "opacity-75",
    },
    accountDropdown: {
      base: "absolute right-0 origin-top-right mt-2 w-48 z-10",
      inner:
        "bg-white ring-1 ring-black ring-opacity-5 rounded divide-y divide-gray-100 shadow-xl rounded",
      link: "flex items-center space-x-2 rounded py-2 px-3 text-sm font-medium text-gray-600 hover:bg-gray-100 hover:text-gray-700",
      linkActive: "text-gray-700 bg-gray-100",
      linkInactive:
        "text-gray-600 hover:bg-gray-100 hover:text-gray-700 focus:outline-none focus:bg-gray-100 focus:text-gray-700",
      icon: "opacity-50 inline-block w-5 h-5",
    },
  };

  return (
    <Section bgColor="bg-[#181414]">
      <div className="container py-3" style={{ opacity: 0.95 }}>
        <div className="flex justify-between">
          <a
            href="https://www.revyl.ai"
            className="inline-flex items-center space-x-2 font-bold text-lg tracking-wide text-white"
          >
            <svg
              className="opacity-75 hi-outline hi-cube-transparent inline-block w-8 h-8 text-white"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"
              />
            </svg>
            <span className="text-white">Revyl</span>
          </a>
          <div className="flex items-center lg:space-x-3">
            <ul className="hidden lg:flex items-center justify-between w-full">
              {!auth.user && (
                <li className="relative group mx-1.5">
                  <a href="https://docs.revyl.ai/introduction" target="_blank" rel="noopener noreferrer" className="h-8 w-full sm:w-max">
                    <Button
                      className="relative w-full sm:w-max flex items-center justify-center transition-all hover:shadow-[0_0_0_3px_rgba(255,255,255,0.3)_inset]"
                      backgroundColor="linear-gradient(145deg, #02c983, #009460)"
                      style={{
                        padding: '0.5rem 2rem',
                        margin: '0 0.25rem',
                      }}
                    >
                      <span className="relative whitespace-pre text-center text-sm font-semibold leading-none tracking-tight text-white z-10">
                        Docs
                      </span>
                    </Button>
                  </a>
                </li>
              )}

              {!auth.user && (
                <li className="relative group mx-1.5">
                  <a
                    href="https://calendly.com/landseerenga/cognisim-demo-1"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="h-8 w-full sm:w-max"
                  >
                    <Button
                      className="relative w-full sm:w-max flex items-center justify-center transition-all hover:shadow-[0_0_0_3px_rgba(255,255,255,0.3)_inset]"
                      backgroundColor="bg-black"
                      outlineColor="white"
                      style={{
                        borderRadius: '20px',
                        color: 'white',
                        padding: '0.5rem 2rem',
                        margin: '0 0.25rem',
                      }}
                    >
                      <span className="relative whitespace-pre text-center text-sm font-semibold leading-none tracking-tight text-white z-10">
                        Book a Demo
                      </span>
                    </Button>
                  </a>
                </li>
              )}

              {!auth.user && (
                <li className="relative group mx-1.5">
                  <Link href="/auth/signin" legacyBehavior>
                    <a className="h-8 w-full sm:w-max">
                      <Button
                        className="relative w-full sm:w-max flex items-center justify-center transition-all hover:shadow-[0_0_0_3px_rgba(255,255,255,0.3)_inset]"
                        backgroundColor="linear-gradient(145deg, #02c983, #009460)"
                        style={{
                          padding: '0.5rem 2rem',
                          margin: '0 0.25rem',
                        }}
                      >
                        <span className="relative whitespace-pre text-center text-sm font-semibold leading-none tracking-tight text-white z-10">
                          Log In
                        </span>
                      </Button>
                    </a>
                  </Link>
                </li>
              )}
            </ul>

            {auth.user && (
              <Menu as="div" className="relative inline-block">
                <Button
                  component={Menu.Button}
                  variant="simple"
                  size="sm"
                  endIcon={
                    <ChevronDownIcon className="opacity-50 inline-block w-5 h-5" />
                  }
                >
                  Account
                </Button>
                <Transition
                  as={React.Fragment}
                  enter="transition ease-out duration-150"
                  enterFrom="transform opacity-0 scale-75"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-100"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-75"
                >
                  <Menu.Items className={classes.accountDropdown.base}>
                    <div className={`${classes.accountDropdown.inner}`}>
                      <div className="p-2 space-y-1">
                        <Menu.Item>
                          <Link
                            to="/execute"
                            className={`${classes.accountDropdown.link}`}
                          >
                            <PlayIcon
                              className={classes.accountDropdown.icon}
                            />
                            <span>Execute</span>
                          </Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link
                            to="/tests"
                            className={`${classes.accountDropdown.link}`}
                          >
                            <BeakerIcon
                              className={classes.accountDropdown.icon}
                            />
                            <span>Tests</span>
                          </Link>
                        </Menu.Item>
                        <Menu.Item>
                          <Link
                            to="/testhistory"
                            className={`${classes.accountDropdown.link}`}
                          >
                            <DocumentIcon
                              className={classes.accountDropdown.icon}
                            />
                            <span>Test History</span>
                          </Link>
                        </Menu.Item>

                        <Menu.Item>
                          <Link
                            to="/settings/general"
                            className={`${classes.accountDropdown.link}`}
                          >
                            <CogIcon className={classes.accountDropdown.icon} />
                            <span>Settings</span>
                          </Link>
                        </Menu.Item>
                      </div>
                      <div className="p-2 border-t border-gray-200 dark:border-gray-700">
                        <Menu.Item>
                          <button
                            onClick={() => auth.signout()}
                            className={`${classes.accountDropdown.link} w-full text-left`}
                          >
                            <span>Sign out</span>
                          </button>
                        </Menu.Item>
                      </div>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            )}

            <div className="flex lg:hidden items-center justify-center">
              {!auth.user && (
                <Button
                  variant="simple"
                  size="sm"
                  onClick={() => setMobileNavOpen(!mobileNavOpen)}
                  endIcon={<Bars3Icon className="inline-block w-5 h-5" />}
                />
              )}
            </div>

            {!auth.user && (
              <div className={"lg:hidden" + (!mobileNavOpen ? " hidden" : "")}>
                <div className="grid grid-cols-1 sm:grid-cols-3 rounded-xl my-5 bg-purple-300">
                  <div className="p-6 space-y-6">
                    <h4 className={`${classes.dropdown.title}`}>Learn</h4>
                    <nav className="flex flex-col space-y-3">
                      <Link to="/about" className={`${classes.dropdown.link}`}>
                        Why CogniSim?
                      </Link>
                      <Link to="/pricing" className={`${classes.dropdown.link}`}>
                        Pricing
                      </Link>
                      <a href="https://docs.cognisim.io/introduction" target="_blank" rel="noopener noreferrer" className={`${classes.dropdown.link}`}>
                        Docs
                      </a>
                    </nav>
                    <h4 className={`${classes.dropdown.title}`}>More</h4>
                    <nav className="flex flex-col space-y-3">
                      <Link to="/contact" className={`${classes.dropdown.link}`}>
                        Contact
                      </Link>
                      {!auth.user && (
                        <Link to="/auth/signin" className={`${classes.dropdown.link}`}>
                          Sign in
                        </Link>
                      )}
                    </nav>
                  </div>
                </div>
              </div>
            )}

            {!auth.user && (
              <div className={"lg:hidden" + (!mobileNavOpen ? " hidden" : "")}>
                <div className="grid grid-cols-1 sm:grid-cols-3 rounded-xl my-4 bg-white p-4 shadow-lg">
                  <a href="https://docs.revyl.ai/introduction" target="_blank" rel="noopener noreferrer" className="h-8 w-full sm:w-max mb-2 sm:mb-0">
                    <Button
                      className="relative w-full sm:w-max flex items-center justify-center transition-all hover:shadow-[0_0_0_3px_rgba(255,255,255,0.3)_inset]"
                      backgroundColor="linear-gradient(145deg, #02c983, #009460)"
                      style={{
                        padding: '0.5rem 2rem',
                        margin: '0 0.25rem',
                      }}
                    >
                      <span className="relative whitespace-pre text-center text-sm font-semibold leading-none tracking-tight text-white z-10">
                        Docs
                      </span>
                    </Button>
                  </a>
                  <a
                    href="https://calendly.com/landseerenga/cognisim-demo-1"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="h-8 w-full sm:w-max mb-2 sm:mb-0"
                  >
                    <Button
                      className="relative w-full sm:w-max flex items-center justify-center transition-all hover:shadow-[0_0_0_3px_rgba(255,255,255,0.3)_inset]"
                      backgroundColor="bg-black"
                      outlineColor="white"
                      style={{
                        borderRadius: '20px',
                        color: 'white',
                        padding: '0.5rem 2rem',
                        margin: '0 0.25rem',
                      }}
                    >
                      <span className="relative whitespace-pre text-center text-sm font-semibold leading-none tracking-tight text-white z-10">
                        Book a Demo
                      </span>
                    </Button>
                  </a>
                  <Link href="/auth/signin" legacyBehavior>
                    <a className="h-8 w-full sm:w-max">
                      <Button
                        className="relative w-full sm:w-max flex items-center justify-center transition-all hover:shadow-[0_0_0_3px_rgba(255,255,255,0.3)_inset]"
                        backgroundColor="linear-gradient(145deg, #02c983, #009460)"
                        style={{
                          padding: '0.5rem 2rem',
                          margin: '0 0.25rem',
                        }}
                      >
                        <span className="relative whitespace-pre text-center text-sm font-semibold leading-none tracking-tight text-white z-10">
                          Log In
                        </span>
                      </Button>
                    </a>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Section>
  );
}

export default Navbar;

