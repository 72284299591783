import React from 'react';

const TestResults = ({ testResults }) => {
    console.log(testResults)
  if (!testResults) {
    return <div>No test results available.</div>;
  }

  return (
    <div className="p-4">
      <h2 className="text-xl font-semibold mb-4">Test Results</h2>
      <div className="space-y-4">
        {testResults.map((result, index) => (
          <div key={index} className="border-b pb-4">
            <h3 className="font-medium">{result.testName || `Test ${index + 1}`}</h3>
            <p className="mt-2">
              <span className="font-semibold">Status:</span>{' '}
              <span className={result.success ? 'text-green-600' : 'text-red-600'}>
                {result.success ? 'Passed' : 'Failed'}
              </span>
            </p>
            {result.timeSpent && (
              <p className="mt-2">
                <span className="font-semibold">Time Spent:</span> {result.timeSpent.toFixed(2)} seconds
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TestResults;
