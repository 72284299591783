import React from "react";
import Meta from "./../components/Meta";
import DashboardSection from "./../components/DashboardSection";
import TestSection from "./../components/TestSection";
import { requireAuth } from "./../util/auth";
import TestHistorySection from "../components/TestHistorySection";

function TestHistoryPage(props) {
  return (
    <>
      <Meta title="Tests History" />
        <TestHistorySection
            title="Tests History"
            subtitle=""
            strapline=""
            size="md"
            bgColor="bg-white"
            />
        </>
  
  );
}

export default requireAuth(TestHistoryPage);
