import React from 'react';

function TestHtmlReportModal({ showHtmlReport, toggleHtmlReport, htmlReport, loading }) {
  if (!showHtmlReport && !loading) return null;

  return (
    <div 
      className="fixed inset-0 flex items-center justify-center p-4 z-50"
      onClick={toggleHtmlReport}
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)' }}
    >
      <div 
        className="bg-white w-full max-w-4xl overflow-auto p-4 shadow-2xl rounded-lg relative mx-auto"
        style={{ height: '90vh', top: '50%', transform: 'translateY(-50%)' }}
        onClick={(e) => e.stopPropagation()}
      >
        <button 
          onClick={toggleHtmlReport}
          className="absolute top-0 right-0 m-2 text-red-500 hover:text-red-700 text-sm font-semibold"
        >
          Close Report
        </button>
        {loading ? (
          <div className="flex flex-col items-center justify-center h-full">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
            <p className="mt-4 text-lg font-semibold text-gray-700">Loading report...</p>
          </div>
        ) : (
          <iframe
            srcDoc={htmlReport}
            className="w-full h-full border-none"
            title="HTML Report"
            sandbox="allow-same-origin allow-scripts allow-forms"
            onLoad={() => console.log('Iframe loaded successfully')}
            onError={() => console.error('Error loading iframe content')}
          />
        )}
      </div>
    </div>
  );
}

export default TestHtmlReportModal;