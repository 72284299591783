import React from 'react';
import ControlPanel from './ControlPanel'; // Adjust the import path as necessary
import ShowStream from './ShowStream'; // Adjust the import path as necessary
import TestResults from './TestResults'; // Adjust the import path as necessary
import { useState } from 'react';

const RightSide = ({
  toggleRecording,
  isLocalMode,
  toggleLocalMode,
  deviceStatus,
  startDevice,
  killDevice,
  saveTest,
  isDeviceLoading,
  sessionUrl,
  flowDescription,
  platform,
  appPackage,
  userUid,
  auth,
  tasks,
  testUid,
  setTasks,
  setViewportSize,
  setTestUid,
  testResult,
  apiKey,
  breakpointIndex,
  setBreakpoint,
  schedule,
  setSchedule
}) => {

  const [isRecording, setIsRecording] = useState(false);
  
  return (
    <div className="flex flex-col h-full">
      <ControlPanel
        isRecording={isRecording}
        setIsRecording={setIsRecording}
        toggleRecording={toggleRecording}
        isLocalMode={isLocalMode}
        toggleLocalMode={toggleLocalMode}
        deviceStatus={deviceStatus}
        startDevice={startDevice}
        killDevice={killDevice}
        flowdescription={flowDescription}
        platform={platform}
        appPackage={appPackage}
        userUid={userUid}
        auth={auth}
        tasks={tasks}
        testUid={testUid}
        setTestUid={setTestUid}
        setTasks={setTasks}
        setViewportSize={setViewportSize}
        breakpointIndex={breakpointIndex}
        setBreakpoint={setBreakpoint}
        schedule={schedule}
        setSchedule={setSchedule}
      />
      <div className="flex-grow overflow-auto pt-4 flex flex-col">
        {isDeviceLoading && (
          <div className="flex flex-col items-center justify-center h-full bg-gray-100">
            <div className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
            <p className="mt-4 text-lg font-semibold text-gray-700">Initializing {platform} Session...</p>
            <p className="mt-2 text-sm text-gray-500">This may take a few moments</p>
          </div>
        )}
        {sessionUrl && (
   
              <ShowStream
                sessionUrl={sessionUrl}
                localMode={isLocalMode}
                testId={testUid}
                userId={userUid}
                apiKey={apiKey}
                platform={platform}
                appPackage={appPackage}
                isRecording={isRecording}
              />
    
   
        )}
        {testResult  && (
          <div className="h-64 overflow-y-auto mt-4 border border-gray-300 rounded-lg shadow-sm">
            <TestResults testResult={testResult} />
          </div>
        )}
      </div>
    </div>
  );
};

export default RightSide;