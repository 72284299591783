import React, { useRef, useEffect, useState, useCallback } from 'react';

function ShowStream({
  sessionUrl: initialSessionUrl,
  localMode,
  testId,
  userId,
  apiKey,
  platform,
  appPackage,
  isRecording
}) {
  const iframeRef = useRef(null);
  const BASE_STEP_URL = localMode ? "http://localhost:8001" : process.env.REACT_APP_STEP_API;
  const [isConnected, setIsConnected] = useState(true);
  const [sessionUrl, setSessionUrl] = useState(initialSessionUrl);
  const authorizedFetch = async (endpoint, method, body = {}) => {
    console.log(BASE_STEP_URL)
     const options = {
         method: method,
         headers: {
             'Content-Type': 'application/json',
             'Authorization': `Bearer ${apiKey}`
         },
     }
 
     // Only include body if method isn't GET
     if (method !== 'GET') {
         options.body = JSON.stringify(body);
     }
 
     const response = await fetch(`${BASE_STEP_URL}${endpoint}`, options);
     if (response.ok) {
         const result = await response.json();
         return result;
     }
 }
  const getIframeUrl = async () => {
    const result = await authorizedFetch('/get_iframe_url', 'POST', {
      user_id: userId,
      test_id: testId,
      platform: platform,
      email: '',
      local: localMode,
      app_package: appPackage
    });
    return result;
  }
  const handleMessage = useCallback((event) => {
    if (event.data === 'browserbase-disconnected') {
      setIsConnected(false);
      console.log('disconnected');
      console.log(event.data);
      
      // Re-fetch the iframe URL
      getIframeUrl().then(result => {
        if (result && result.result) {
          console.log(result.result)
          setSessionUrl(result.result);
          setIsConnected(true);
        }
      }).catch(error => {
        console.error('Failed to get new iframe URL:', error);
      });
    }
  }, [getIframeUrl]);

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      const style = document.createElement('style');
      style.textContent = `
        body::before {
          content: '';
          display: block;
          height: 40px;
          background-color: #f0f0f0;
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          z-index: 9999;
        }
        body {
          padding-top: 40px;
        }
      `;
      iframe.contentWindow.document.head.appendChild(style);
    }
  }, [sessionUrl]);

  return (
    <div className={`h-full flex flex-col ${isRecording ? 'border-4 border-red-500 rounded-lg shadow-lg' : ''}`}>
      <div className="browser-header">
        <div className="browser-buttons">
          <span className="browser-button red"></span>
          <span className="browser-button yellow"></span>
          <span className="browser-button green"></span>
        </div>
      </div>
      <div className="flex-grow relative">
        {platform === 'Android' ? (
          <p className="text-center py-4">Showing Android in scrcpy</p>
        ) : sessionUrl ? (
          <>
            {!isConnected && <p className="text-center py-2">Reconnecting...</p>}
            <iframe
              src={sessionUrl}
              sandbox="allow-same-origin allow-scripts"
              allow="clipboard-read; clipboard-write"
              className="absolute inset-0 w-full h-full"
              title="Debug Connection"
            ></iframe>
          </>
        ) : sessionUrl ? (
          <>
            {!isConnected && <p className="text-center py-2">Reconnecting...</p>}
            <iframe
              src={sessionUrl}
              sandbox="allow-same-origin allow-scripts"
              allow="clipboard-read; clipboard-write"
              className="absolute inset-0 w-full h-full"
              title="Debug Connection"
            ></iframe>
          </>
        ) : (
          <p>Loading DevTools...</p>
        )}
      </div>
    </div>
  );
}

export default ShowStream;