import React from "react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Button from "./Button";

function FaqSection(props) {
  const faqItems = [
    {
      question: "What does end to end testing mean?",
      answer:
        "End-to end testing is a methodology that is used to test whether the flow of an application is performing as designed from start to finish. The purpose of carrying out end-to end tests is to identify system dependencies and to ensure that the right information is passed between various system components. Essentially testing if the app works with respect to how a human would use it.",
    },
    {
      question: "How does CogniSim compare to other end to end testing frameworks?",
      answer:
        "CogniSim is designed with a focus on ease of test creation and minimizing test maintenance. It's estimated that engineers spend 30% of their time maintaining tests. CogniSim reduces this time by creating a semantic understanding of the application and its tests.",
    },
    {
      question: "What kind of software can I test with CogniSim?",
      answer:
          "Our platform works with web, IOS, and Android.",
    },
    {
      question: "Where Can I view examples of tests written for CogniSim?",
      answer:
        "Head over to our github to view examples of tests written to work with CogniSim",
    },
  ];

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="space-y-12 container">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          strapline={props.strapline}
          className="text-center"
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12">
          {faqItems.map((item, index) => (
            <div className="prose prose-indigo" key={index}>
              <h4>{item.question}</h4>
              <p>{item.answer}</p>
            </div>
          ))}
        </div>

        {/*{props.showSupportButton && (*/}
        {/*  <div className="text-center">*/}
        {/*    <Button*/}
        {/*      href={props.supportUrl}*/}
        {/*      target="_blank"*/}
        {/*      size="lg"*/}
        {/*      variant="simple"*/}
        {/*      startIcon={*/}
        {/*        <ArrowTopRightOnSquareIcon className="opacity-50 inline-block w-5 h-5" />*/}
        {/*      }*/}
        {/*    >*/}
        {/*      Go to support center*/}
        {/*    </Button>*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
    </Section>
  );
}

export default FaqSection;
