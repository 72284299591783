import React from "react";
import { FireIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Button from "./Button";
import { Link } from "./../util/router";

function CtaSection(props)  {
  return (
   
      <div className="mx-auto max-w-7xl py-16 sm:px-6 sm:pt-40 sm:pb-20 lg:px-8 bg-white">
        <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 text-center  sm:rounded-3xl sm:px-16">
          <h2 className="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-white sm:text-4xl">
          Begin testing today.
          </h2>
          <p className="mx-auto mt- max-w-xl text-lg leading-8 text-gray-300">
          Book a demo today and start automating all of your end to end tests.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
          <a
                href="https://calendly.com/landseerenga/cognisim-demo-1"
                target="_blank"
                rel="noopener noreferrer"
            >
              <button className="btn btn-active bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-full shadow-lg transform transition duration-300 hover:scale-110">Book a Demo</button>
              
            </a>
          </div>
          <svg
            viewBox="0 0 1024 1024"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
            aria-hidden="true"
          >
            <circle cx={512} cy={512} r={512} fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)" fillOpacity="0.7" />
            <defs>
              <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#E935C1" />
              </radialGradient>
            </defs>
          </svg>
        </div>
      </div>
    
  )
}

export default CtaSection;
