import React, { useState } from "react";
// SettingsGeneral.js
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import TextField from "./TextField";
import Button from "./Button";
import LoadingIcon from "./LoadingIcon";
import { useAuth } from "./../util/auth";
import { getTestExecutions, createApiKey, getActiveApiKey, getAllActiveApiKeys, deleteApiKey } from "./../util/db";
import { Link } from "./../util/router";
function SettingsGeneral(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const [testExecutions, setTestExecutions] = useState(null);
  const [apiKey, setApiKey] = useState(null);
  const [apiKeys, setApiKeys] = useState([]); //Store multiple api keys
  const [copySuccess, setCopySuccess] = useState('')
  //Handle copy ketys
   // Function to copy API keys to clipboard in Python list format
   const copyApiKeysToClipboard = async () => {
    const pythonListString = `api_keys = [\n    '${apiKeys.join("',\n    '")}',\n]`;
    try {
      await navigator.clipboard.writeText(pythonListString);
      setCopySuccess('API keys copied to clipboard!');
    } catch (err) {
      setCopySuccess('Failed to copy API keys.');
    }
  };
  useEffect(() => {
    if (auth.user) {
      getTestExecutions(auth.user.uid).then((testExecutions) => {
        setTestExecutions(testExecutions);
      getAllActiveApiKeys(auth.user.uid).then(setApiKeys);
      });
    }
  }, [auth.user]);
  const { register, handleSubmit, errors } = useForm();
  const handleCreateApiKey = async () => {
    setPending(true);
    await createApiKey(auth.user.uid);
    const allApiKeys = await getAllActiveApiKeys(auth.user.uid);
    console.log(allApiKeys)
    setApiKeys(allApiKeys);
    setPending(false);
  };

  //Delete api key funciton
  const handleDeleteApiKey = async (apiKeyValue) => {
    setPending(true);
    const success = await deleteApiKey(apiKeyValue)
    if (success) {
      // refresh the list of API keys after deletion
      const updatedApiKeys = await getAllActiveApiKeys(auth.user.uid);
      setApiKeys(updatedApiKeys);
    
    } else {
      console.error("Error deleting API key:");
    }
    setPending(false)
  }

  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true);

    return auth
      .updateProfile(data)
      .then(() => {
        // Set success status
        props.onStatus({
          type: "success",
          message: "Your profile has been updated",
        });
      })
      .catch((error) => {
        if (error.code === "auth/requires-recent-login") {
          props.onStatus({
            type: "requires-recent-login",
            // Resubmit after reauth flow
            callback: () => onSubmit(data),
          });
        } else {
          // Set error status
          props.onStatus({
            type: "error",
            message: error.message,
          });
        }
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };

  return (
    <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
      
      
      <TextField
        type="text"
        id="name"
        name="name"
        placeholder="Name"
        label="Name"
        defaultValue={auth.user.name}
        error={errors.name}
        inputRef={register({
          required: "Please enter your name",
        })}
      />
      <TextField
        type="email"
        id="email"
        name="email"
        placeholder="Email"
        label="Email"
        defaultValue={auth.user.email}
        error={errors.email}
        inputRef={register({
          required: "Please enter your email",
        })}
      />
      <Button
        type="submit"
        size="md"
        variant="primary"
        disabled={pending}
        isBlock={true}
      >
      
        {!pending && <>Save</>}

        {pending && <LoadingIcon className="w-6" />}
      </Button>
      
      <TextField
        type="text"
        id="text_executions"
        name="text_executions"
        placeholder="Text Executions"
        label="Text Executions Remaining"
        defaultValue={testExecutions}
        disabled={true}
        />
     <div>
     
        <h3 className="text-lg font-semibold mb-4">Your API Keys:</h3>
        {apiKeys.length > 0 ? (
          apiKeys.map((key, index) => (
            <div key={index} className="flex items-center justify-start py-2">
            <div className="flex-grow">
              <TextField
                type="text"
                id={`api_key_${index}`}
                name={`api_key_${index}`}
                placeholder="API Key"
                label={`API Key ${index + 1}`}
                defaultValue={key}
                disabled={true}
                className="w-full bg-gray-100" // Set the width to full and background to gray
              />
            </div>
            <button
              type="button"
              onClick={() => handleDeleteApiKey(key)}
              className="ml-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              disabled={pending}
            >
              Delete
            </button>
          </div>
          ))
        ) : (
          <p>No API keys found.</p>
        )}
      </div>
      
      {copySuccess && <div className="text-sm text-green-500">{copySuccess}</div>}
      <div className="flex flex-col sm:flex-row justify-center sm:justify-start space-y-2 sm:space-y-0 sm:space-x-2 mb-4">
        {/* Button to copy all API keys */}
        <Button
  type="button"
  size="md"
  variant="primary" // Change the variant or className to apply different styling
  onClick={copyApiKeysToClipboard}
  disabled={apiKeys.length === 0}
  className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded"
  
>
  Copy All API Keys
</Button>

        {/* Button to create a new API key */}
        <Button
          type="button"
          size="md"
          variant="primary"
          onClick={handleCreateApiKey}
          disabled={pending}
          className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded"
        >
          {!pending && <>Create New API Key</>}
          {pending && <LoadingIcon className="w-6 h-6" />}
        </Button>
      </div>
      <Button
        size="md"
        variant="primary"
        className="w-full"
        onClick={(e) => {
          e.preventDefault();
          auth.signout();
        }}
      >
        Sign out
      </Button>
    </form>
  );
}

export default SettingsGeneral;
