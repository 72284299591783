import React, { useState, useEffect, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useHistory } from "react-router-dom";
import { useAuth } from "./../util/auth";
import { getApiKey } from "./../util/db";
import { Link } from "react-router-dom";
import { formatDistanceToNow, parseISO } from 'date-fns';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // Adjust this to your actual API base URL

const List = () => {
  const history = useHistory();
  const auth = useAuth();
  const [rows, setRows] = useState([]);
  const tableContainerRef = useRef(null);
  const [apiKey, setApiKey] = useState(null);
  const dataRef = useRef(null);
  const [sortConfig, setSortConfig] = useState({ key: 'last_execution_time', direction: 'desc' });
  const [selectedLink, setSelectedLink] = useState(null);

  useEffect(() => {
    const fetchApiKey = async () => {
      try {
        const key = await getApiKey(auth.user.uid);
        setApiKey(key);
        console.log("API key fetched:", key);
      } catch (error) {
        console.error("Error fetching API key:", error);
      }
    };

    fetchApiKey();
  }, [auth.user.uid]);

  useEffect(() => {
    const fetchTestsByUser = async () => {
      if (auth.user && apiKey) {
        const url = `${API_BASE_URL}/get_user_tests_with_details?user_id=${auth.user.uid}`;
        console.log(`Fetching tests with URL: ${url}`);
        // Debugging log

        try {
          const response = await fetch(url, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${apiKey}`, // Use auth.token for authorization
              'Content-Type': 'application/json'
            }
          });

          console.log('Response status:', response.status);
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          const data = await response.json();
          console.log('Data received:', data);
          setRows(data); // Update state with new data
        } catch (error) {
          console.error('Failed to fetch tests', error);
        }
      } else {
        console.log('auth.user or auth.token is not available'); // Debugging log
      }
    };

    fetchTestsByUser();
  }, [auth.user, apiKey]);

  const handleRowClick = (testId) => {
    history.push(`/tests/${testId}`); // Navigate to the test details page on row click
  };

  const handleLinkFilterChange = (event) => {
    setSelectedLink(event.target.value);
  };

  const uniqueLinksSet = new Set(rows.map(row => row.link));

  const styles = {
    tableContainer: {
      backgroundColor: '#fffffff',
      width: '100%',
      maxHeight: '400px',
      overflowY: 'auto',
      cursor: 'pointer',
      outline: '1px solid #000000',
    },
    tableCellHeader: {
      fontWeight: 'bold',
      backgroundColor: '#fffffff',
      borderBottom: '1px solid #000000',
      cursor: 'pointer',
    },
    tableCell: {
      borderBottom: '1px solid #fffffff',
    },
    status: {
      padding: '5px 10px',
      borderRadius: '20px',
      display: 'inline-block',
      textAlign: 'center',
      fontWeight: 'bold',
      width: '80px',
    },
    passing: {
      backgroundColor: '#DCFCE7',
      color: '#064E3B',
    },
    failing: {
      backgroundColor: '#FEE2E2',
      color: '#991B1B',
    },
    unknown: {
      backgroundColor: '#D1D5DB',
      color: '#4B5563',
    },
    moreLink: {
      display: 'block',
      textAlign: 'center',
      marginTop: '5px',
      marginBottom: '5px',
      color: '#000000',
      textDecoration: 'none',
      fontWeight: 'bold',
    },
    linkFilter: {
      fontSize: '12px',
      padding: '5px',
      margin: '3px 0',
    }
  };

  const formatLastExecutionTime = (lastExecutionTime) => {
    if (!lastExecutionTime) return 'Not yet run';
    const now = new Date();
    const executionTime = parseISO(lastExecutionTime);
    const diffInSeconds = (now - executionTime) / 1000;

    if (diffInSeconds < 60) {
      return `${Math.floor(diffInSeconds)} seconds ago`;
    } else if (diffInSeconds < 3600) {
      return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    } else if (diffInSeconds < 86400) {
      return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    } else {
      return executionTime.toLocaleDateString();
    }
  };

  const sortedRows = rows
    .filter(row => !selectedLink || row.link === selectedLink)
    .sort((a, b) => {
      if (a.last_execution_time === null) return -1;
      if (b.last_execution_time === null) return 1;
      const aValue = a.last_execution_time ? parseISO(a.last_execution_time) : new Date(0);
      const bValue = b.last_execution_time ? parseISO(b.last_execution_time) : new Date(0);
      return bValue - aValue; // Descending order
    });

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  console.log('Rows:', rows);
  console.log('Unique Links:', uniqueLinksSet);

  return (
    <TableContainer component={Paper} style={styles.tableContainer} tabIndex={0} ref={tableContainerRef}>
      <div style={{ padding: '10px' }}>
        <label htmlFor="linkFilter">Filter by Link: </label>
        <select
          id="linkFilter"
          value={selectedLink || ''}
          onChange={(e) => setSelectedLink(e.target.value || null)}
          style={{ width: '280px' }}
        >
          <option value="">All</option>
          {[...uniqueLinksSet].map((link, index) => (
            <option key={index} value={link}>{link}</option>
          ))}
        </select>
      </div>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={styles.tableCellHeader}>Name</TableCell>
            <TableCell style={{ ...styles.tableCellHeader, paddingLeft: '10px', textAlign: 'center' }}>Latest Run</TableCell>
            <TableCell style={styles.tableCellHeader}>Test ID</TableCell>
            <TableCell style={styles.tableCellHeader}>Last Execution Time</TableCell>
            <TableCell style={{ ...styles.tableCellHeader, paddingLeft: '33px' }}>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRows.map((row) => (
            <TableRow key={row.id} style={{ cursor: 'pointer' }} onClick={() => handleRowClick(row.id)}>
              <TableCell style={styles.tableCell}>{row.testname}</TableCell>
              <TableCell style={{ ...styles.tableCell, textAlign: 'center' }}>
                {formatLastExecutionTime(row.last_execution_time)}
              </TableCell>
              <TableCell style={styles.tableCell}>{row.id}</TableCell>
              <TableCell style={styles.tableCell}>
                {row.last_execution_time ? new Date(row.last_execution_time).toLocaleString() : 'Not yet run'}
              </TableCell>
              <TableCell style={styles.tableCell}>
                {row.last_status !== null ? (
                  <span style={{ ...styles.status, ...(row.last_status ? styles.passing : styles.failing) }}>
                    {row.last_status ? 'Passing' : 'Failing'}
                  </span>
                ) : (
                  <span style={{ ...styles.status, ...styles.unknown }}>N/A</span>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <a href="/tests" style={styles.moreLink}>See More</a>
    </TableContainer>
  );
};

export default List;