import React, { useMemo, useCallback, useState } from 'react';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { Fragment } from 'react';

// Add this CSS class to your global styles or component-specific styles
const scrollbarStyles = `
  .always-visible-scrollbar::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  .always-visible-scrollbar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
  .always-visible-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }
`;

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const StepTypeSelector = ({
    stepType,
    index,
    handleInputChange,
    stepTypes,
    setTasks
}) => {
  const [selected, setSelected] = useState(() => {
    const foundType = stepTypes.find(type => type.id === stepType);
    return foundType || stepTypes[0];
  });
  
  const handleChange = useCallback((selectedOption) => {
    if (selectedOption.id === stepType) return;

    const newValue = selectedOption.id;
    const metadata = newValue === 'validation' ? { validation_type: 'visibility' } : {};
    
    setSelected(selectedOption);
    
    setTasks(prevTasks => {
      const updatedTask = { 
        ...prevTasks[index], 
        step_type: newValue,
        metadata: {
          ...prevTasks[index].metadata,
          ...metadata
        }
      };
      
      const newTasks = [...prevTasks];
      newTasks[index] = updatedTask;
      return newTasks;
    });
  }, [index, setTasks, stepType]);

  const groupedStepTypes = useMemo(() => 
    stepTypes.reduce((acc, step) => {
      (acc[step.group] = acc[step.group] || []).push(step);
      return acc;
    }, {}),
    [stepTypes]
  );

  return (
    <>
      <style>{scrollbarStyles}</style>
      <Listbox value={selected} onChange={handleChange}>
        {({ open }) => (
          <>
            <div className="relative mt-2">
              <ListboxButton className="relative w-32 cursor-default rounded-md bg-white py-1.5 pl-3 pr-3 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                <span className="flex items-center">
                  <span className="flex-shrink-0">{selected.icon}</span>
                  <span className="ml-2 block truncate">{selected.name}</span>
                </span>
              </ListboxButton>
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 z-[9999] overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <ListboxOptions className="relative z-[10000] mt-1 max-h-60 w-full rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm always-visible-scrollbar" style={{ overflowY: 'scroll' }}>
                      {Object.entries(groupedStepTypes).map(([group, types]) => (
                        <div key={group}>
                          <div className="bg-gray-100 px-4 py-2 text-sm font-semibold text-gray-900">{group}</div>
                          {types.map((type) => (
                            <ListboxOption
                              key={type.id}
                              className={({ active }) =>
                                classNames(
                                  active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                  'relative cursor-default select-none py-2 pl-3 pr-9'
                                )
                              }
                              value={type}
                            >
                              {({ selected, active }) => (
                                <>
                                  <div className="flex items-center">
                                    {type.icon}
                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3')}>
                                      {type.name}
                                    </span>
                                  </div>
                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active ? 'text-white' : 'text-indigo-600',
                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                      )}
                                    >
                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </ListboxOption>
                          ))}
                        </div>
                      ))}
                    </ListboxOptions>
                  </div>
                </div>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </>
  );
};

export default React.memo(StepTypeSelector);