import React from "react"
import Section from "./Section"
import SectionHeader from "./SectionHeader"
import { useAuth } from "./../util/auth";
import TestHistoryItems from "./TestHistoryItems"
import {Link} from "./../util/router"

function TestHistorySection(props) {
    const auth = useAuth();
    return (
        <Section
            size={props.size}
            bgColor={props.bgColor}
            bgImage={props.bgImage}
            bgImageOpacity={props.bgImageOpacity}
            textColor={props.textColor}
        >
            <div className="container">
                <SectionHeader
                    title={props.title}
                    subtitle={props.subtitle}
                    strapline={props.strapline}
                    className="text-center"
                />
                <div className="flex flex-wrap">
                    <div className="p-4 w-full">
                        <div className="rounded border border-gray-200">
                            <TestHistoryItems />
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    );
    
}

export default TestHistorySection;