import React from "react";
import { FireIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Button from "./Button";
import { Link } from "./../util/router";

function CtaSection(props) {
  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="space-y-10 container">
        <div className="text-center">
          <SectionHeader
              title={props.title}
              subtitle={props.subtitle}
              strapline={props.strapline}
          />
          <div className="lex flex-col justify-center sm:items-center sm:space-x-2">
            <a
                href="https://calendly.com/landseerenga/cognisim-demo-1"
                target="_blank"
                rel="noopener noreferrer"
            >
              <Button size="xl" variant="primary">
                Book a demo
              </Button>
            </a>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default CtaSection;
