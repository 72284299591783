import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

const TestHistoryPagination = ({ historyPerPage, totalHistory, paginate, currentPage, testId, hasMoreHistory, loadMoreHistory }) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalHistory / historyPerPage); i++) {
    pageNumbers.push(i);
  }

  const handlePaginate = (newPage) => {
    paginate(testId, newPage);
  };

  const handleLoadMore = () => {
    loadMoreHistory(testId);
  };

  return (
    <div className="flex flex-col items-center mt-4">
      <div className="flex justify-center items-center">
        <button
          onClick={() => handlePaginate(Math.max(1, currentPage - 1))}
          disabled={currentPage === 1}
          className="px-2 py-1 rounded-md bg-gray-200 text-gray-600 disabled:opacity-50"
        >
          <ChevronLeftIcon className="h-5 w-5" />
        </button>
        <span className="mx-4">
          Page {currentPage} of {Math.ceil(totalHistory / historyPerPage)}
        </span>
        <button
          onClick={() => handlePaginate(Math.min(Math.ceil(totalHistory / historyPerPage), currentPage + 1))}
          disabled={currentPage === Math.ceil(totalHistory / historyPerPage) && !hasMoreHistory}
          className="px-2 py-1 rounded-md bg-gray-200 text-gray-600 disabled:opacity-50"
        >
          <ChevronRightIcon className="h-5 w-5" />
        </button>
      </div>
      {hasMoreHistory && (
        <button 
          onClick={handleLoadMore}
          className="mt-4 text-blue-500 hover:text-blue-700 text-sm font-semibold"
        >
          Load More
        </button>
      )}
    </div>
  );
};

export default TestHistoryPagination;